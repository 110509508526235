
.cms-page-section.has-tabs {
    .cms-page-section__body {
        column-count: inherit;
        display: flex;
        flex-direction: column;    
    }
    
    [role="tablist"] {
        @extend .abs-reset-list;
        display: flex;
        justify-content: center;
        @include lib-css(margin-top, $indent__base);
        @include lib-css(margin-bottom, $indent__l);
    }

    [role="tab"] {
        @include lib-css(color, $color-sna-primario3);
        min-width: 54px;
        min-height: 54px;
        
        &.active {
            @include lib-css(color, $color-sna-primario2);
        }
        
        &:not(:last-child) {
            @include lib-css(margin-right, $indent__l);

            @include min-screen($screen__m) {
                @include lib-css(margin-right, $indent__xxxxl);
            }
        }
        
        .tab__icon {
            width: 2.2em;
            height: 2.2em;
        }

        .tab__text {
            @include lib-font-size($font-size__base);
        }

        &.icon-above,
        &.icon-above a {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            
            > svg {
                margin-bottom: .5em;
            }
        }

        a {
            color: currentColor;
        }
    }
}

.cms-page-section.has-tabs--bottom {
    @include min-screen($screen__m) {
        [role="tablist"] {
            order: 2;
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}