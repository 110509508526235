.block-product-link,
.block-category-link {
    &.widget {
        display: block;
        margin-bottom: $indent__base;
    }
}

.block-product-link-inline {
    &.widget {
        margin: 0;
    }
}

.block-products-list {
    .block-title {
        @include lib-typography-light-heading();
        margin-top: 0;
        text-align: left;

        strong {
            display: inline;
            font-size: inherit;
            font-weight: inherit;
            line-height: inherit;
            text-transform: inherit;
        }
    }
    /**
     * 1. Disable display:grid when using slick
     */
    .product-slider {
        display: block; /* [1] */
    }
}

.widget-product-carousel {
    display: block;
}

.block.widget {
    .product-item-info {
        width: auto;
    }

    .pager {
        padding: 0;

        .toolbar-amount {
            float: none;
            @include lib-font-size(12);
        }

        .pages-item-previous {
            padding-left: 0;
        }

        .pages-item-next {
            position: relative;
        }

        .items {
            white-space: nowrap;
        }
    }
}


//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .sidebar .block.widget .pager {
        .item:not(.pages-item-next):not(.pages-item-previous) {
            @extend .abs-no-display-desktop;
        }

        .pages-item-next {
            padding: 0;

            .action {
                margin: 0;
            }
        }
    }
}
