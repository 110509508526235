.blog {
    @include max-screen($screen__m) {
        .sidebar-main {
            order: 0;
        }
    }
    &__sidebar-category-tree,
    &__sidebar-recent,
    &__sidebar-tag-cloud {
        margin: 0 0 $indent__s;
        .filter {
            background-color: $color-sna-primario4;
            @include lib-font-size($font-size__s);
            color: $color-sna-black;

            .arrow-up {
                display: none;
            }
            .arrow-down {
                display: inline-block;
            }
            .block-title {
                padding: $indent__s $indent__xl $indent__s $indent__s;
                position: relative;
                @include lib-css(font-weight, $font-weight__heavier);
                cursor: pointer;

                > span {
                    display: inline-block;
                    word-break: break-word;
                }

                .arrow {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: $indent__base;
                }
            }
            .block-content {
                padding: $indent__s $indent__base $indent__m;
                .items .item {
                    &:not(:last-child) {
                        margin-bottom: $indent__s;
                    }

                    a {
                        padding: $indent__xs 0;
                        @include lib-link-secondary(
                            $_link-color: $color-sna-black,
                            $_link-color-visited: $color-sna-black,
                            $_link-color-hover: $color-sna-black,
                            $_link-text-decoration-hover: underline,
                            $_link-color-active: $color-sna-black
                        );
                    }
                }
                @include max-screen($screen__m) {
                    display: none;
                }
            }
        }

        &.active .filter {
            .arrow-up {
                display: inline-block;
            }
            .arrow-down {
                display: none;
            }
        }
    }

    &__sidebar-search {
        margin: 0 0 $indent__s;
        .control {
            position: relative;
            .actions {
                position: absolute;
                right: 16px;
                top: 50%;
                transform: translateY(-50%);

                .input-btn {
                    display: inline-block;
                    @include lib-button-reset();
                    padding: $indent__xs 0;

                    z-index: 1;
                    min-width: unset;

                    span {
                        @include lib-visually-hidden();
                    }

                    svg {
                        margin: 0;
                    }

                    &:focus {
                        &:before {
                            @include lib-css(color, $color-gray20);
                        }
                    }
                }
            }
            input {
                max-width: 100%;
            }
        }
    }
}