.block.newsletter {
    background-color: $color-sna-primario1;

    .action.primary,
    .mktoButton {
        &:hover {
            color: $color-sna-primario4;
            background-color: transparent;
            border-color: $color-sna-primario4;
        }
    }
}
