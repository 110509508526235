.responsive-table {
    @include max-screen($screen__m) {
        display: block;
        
        thead {
            @include lib-visually-hidden();
        }
        
        tbody, tr, td {
            display: block;
        }

        td::before {
            content: attr(data-heading);
            display: block;
            margin-bottom: 1em;
            font-weight: bold;
            text-transform: uppercase;
        }
    }
}