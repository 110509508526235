//
//  Column-order-first--mobile
//  _____________________________________________

@mixin lib-column-order-first--mobile() {
    @include max-screen($screen__m) {
        &.pagebuilder-column {
            order: -1;
        }
    }
}
