.panel.country.selector {
    background-color: $color-sna-primario2;
}
.geoip-language-suggestion {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.hidden {
        display: none;
    }

    &__text {
        @include lib-font-size($font-size__s);
        color: $color-sna-white;
        margin: $indent__xs 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    &__link {
        @include lib-link-secondary(
            $_link-font-weight   : $font-weight__bold,
            $_link-color         : $color-sna-primario1,
            $_link-color-visited : $color-sna-primario1,
            $_link-color-hover   : $color-sna-secundario1,
            $_link-color-active  : $color-sna-secundario1
        );
        padding: $indent__s $indent__xs;
    }

    &__popup-btn {
        @include lib-button-as-link(
            $_padding            : $indent__s $indent__xs
        );
        @include lib-link-secondary(
            $_link-font-weight   : $font-weight__bold,
            $_link-color         : $color-sna-white,
            $_link-color-visited : $color-sna-white,
            $_link-color-hover   : $color-sna-secundario1,
            $_link-color-active  : $color-sna-white
        );
    }

    &__close {
        @include lib-button-as-link();
        @include lib-link-secondary(
            $_link-font-weight             : $font-weight__bold,
            $_link-color                   : $color-sna-white,
            $_link-color-visited           : $color-sna-white,
            $_link-color-hover             : $color-sna-secundario1,
            $_link-color-active            : $color-sna-white
        );
        svg {
            margin: 0.8rem;
        }
    }
}