//
//  Variables
//  _____________________________________________

$form-field__vertical-indent__desktop: 29px !default;
$form-calendar-icon__color: $primary__color !default;

.fieldset {
    @include lib-form-fieldset();
    &:last-child {
        margin-bottom: 0;
    }

    > .field,
    > .fields > .field {
        @include lib-form-field();

        &.no-label {
            > .label {
                @extend .abs-visually-hidden;
            }
        }

        &.choice {
            .label {
                display: inline;
                font-weight: normal;
            }
        }

        .label {
            .column:not(.main) & {
                font-weight: normal;
            }
        }

        .field.choice {
            display: flex;
            align-items: center;
            margin-bottom: $indent__s;

            &:last-child {
                margin-bottom: 0;
            }
        }

        input[type=file] {
            margin: $indent__xs 0;
            max-width: 100%;

            @include max-screen($screen__xxxs) {
                max-width: 250px;
            }
        }
    }
}

.legend + .fieldset,
.legend + div {
    clear: both;
}

.legend {
    strong {
        margin-left: $indent__xs;
    }
}

fieldset.field {
    border: 0;
    padding: 0;
}

.field {
    &.date {
        @extend .abs-field-date;

        .time-picker {
            display: inline-block;
            margin-top: $indent__s;
            white-space: nowrap;
        }
    }

    .message {
        &.warning {
            margin-top: $indent__s;
        }
    }
}

.field.single-checkbox {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: $indent__s;
}

.field-error,
div.mage-error[generated] {
    margin-top: 7px;
    flex: 0 1 100%;
}

.field-error {
    @include lib-form-validation-note();
}

.field .tooltip {
    @include lib-tooltip(right);
    .tooltip-content {
        min-width: 200px;
        white-space: normal;
    }
}

input,
select {
    &:focus ~ .tooltip .tooltip-content {
        display: block;
    }
}

._has-datepicker {
    ~ .ui-datepicker-trigger {
        @include lib-button-reset();
        @include lib-icon-font(
            $_icon-font-content     : $icon-calendar,
            $_icon-font-color       : $primary__color,
            $_icon-font-size        : $icon-calendar__font-size,
            $_icon-font-line-height : $icon-calendar__font-size,
            $_icon-font-display     : block,
            $_icon-font-text-hide   : true
        );
        display: inline-block;
        vertical-align: middle;

        &:focus {
            box-shadow: none;
            outline: 0;
        }
    }
}

.ui-datepicker {
    & &-prev,
    & &-next {
        &.ui-state-disabled {
            opacity: 0;
        }
    }

    & &-prev {
        left: -4px;
    }

    & &-next {
        right: -4px;
    }

    & &-title {
        margin: 0 $indent__m;
    }

    & &-title &-month,
    & &-title &-year {
        padding: $indent__s $indent__base $indent__s $indent__s;
    }

    & &-title &-month {
        width: 48%;
        margin-right: 3%;
    }

    & &-title &-year {
        width: 49%;
    }
}

.uploadcare-widget.uploadcare-widget {
    display: block;
}
//
//  Sidebar forms
//  -----------------------------------------

.sidebar {
    .fieldset {
        margin: 0;

        & > .field:not(.choice),
        .fields > .field {
            &:not(:last-child) {
                margin: 0 0 $form-field__vertical-indent;
            }

            .label {
                margin: 0 0 4px;
                padding: 0 0 $indent__xs;
                text-align: left;
                width: 100%;
            }

            .control {
                width: 100%;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .fieldset {
        .legend {
            @extend .abs-margin-for-forms-desktop;
        }

        > .field {
            @include lib-form-field-type-revert();
            margin: 0 0 $form-field__vertical-indent__desktop;
        }
    }
}
