//
//  Column-no-flex-wrap--mobile
//  _____________________________________________

@mixin lib-column-no-flex-wrap--mobile() {
    @include max-screen($screen__m) {
        .pagebuilder-column-group {
            flex-wrap: nowrap;
        }
    }
}
