// Overlay
// ----------------------------------------------------------------------------
.menu-overlay {
    display: none;

    &.is-enabled {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100vh;
        z-index: 0;
    }
}