div[data-content-type='tabs'] {
    .tabs-navigation {
        overflow-y: auto;
        white-space: nowrap;

        li.tab-header {
            background: $color-sna-primario2;

            a.tab-title {
                color: $color-sna-white;
            }

            &.ui-state-active {
                background: $color-sna-white;
                z-index: 19;

                a.tab-title {
                    color: $color-sna-primario1;
                }
            }
        }
    }
}
