$media__img__retina-width : 120px;

.media-object-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.media-object {
    display: flex;
    align-items: flex-start;
    padding: 0;
    margin-bottom: 2em;
}

.media-object__img {
    width: $media__img__retina-width;
    margin-right: 1.2em;
}

.media-object__heading {
    display: block;
    font-weight: bold;
}