.topmenu {
    display: block;
}

.topmenu-main {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    padding: 0;
}

.topmenu-main__item,
.topmenu-main__item-link {
    margin: 0;
    &--last {
        margin: auto 0 0 auto;
        @include on4menu-mobile {
            display: none;
        }
    }
}
@include on4menu-mobile {
    .topmenu-main {
        margin-top: $indent__s;
    }
    .topmenu-main__item,
    .topmenu-main__item-link {
        margin: 0 0 $indent__s;
    }
}
@include on4menu-desktop {
    .topmenu-main {
        flex-direction: row;
        align-items: center;
        margin-left: -12px;
    }

    .topmenu-main__item,
    .topmenu-main__item-link {
        margin-left: $indent__s;
        margin-right: $indent__s;
    }

    .topmenu-main__item-clickable,
    .topmenu-main__item-link-clickable {
        @include lib-button-as-link();
        @include lib-link-secondary();
        @include lib-font-size(14);
        text-transform: uppercase;
        font-weight: $font-weight__bold;
        letter-spacing: 1.2px;
        width: auto;
        padding: 15px 0 14px;
        .arrow {
            margin: 0 0 0 $indent__xs;
        }
    }
    .topmenu-main__item-link-clickable {
        display: inline-block;
    }
}
@include on4menu-desktop-normal {
    .topmenu-main {
        margin-left: -$indent__base;
    }

    .topmenu-main__item,
    .topmenu-main__item-link {
        margin-left: 12px;
        margin-right: 12px;
    }
}

@include on4menu-desktop-big {
    .topmenu-main__item,
    .topmenu-main__item-link {
        margin-left: 20px;
        margin-right: 20px;
    }
}
