.catalog-product-view {
    .buy-now-wrapper .btn-special {
        //It might be displayed again on phase 2
        display: none !important;
    }
}

.product-info-fixed {
    @include lib-css(background-color, $color-sna-secundario2);
}

.product-item-sku {
    display: none;
}

.product-info-main {
    .product {
        &.attribute {
            &.sku {
                @include lib-css(color, $color__primary--light);

                visibility: hidden;
            }
        }
    }
}
