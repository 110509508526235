.product-features {
    text-align: center;

    &__name,
    &__sku {
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 1em;
    }

    &__sku {
        color: $color-sna-primario1;
    }

    &__img {
        width: 320px;
        margin: 0 auto 1em;
    }

    &__list {
        @extend .abs-reset-list;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        @include lib-css(margin-bottom, $indent__xl);
        margin-top: -1em;
        margin-bottom: $indent__xl;

        li {
            @include lib-css(margin-right, $indent__base);
        }
    }

    &__feature {
        max-width: 100px;
        margin: 1em auto 0;

        img {
            @include lib-css(margin-bottom, $indent__s);
            width: 75px;
            margin: 0 auto;
        }

        figcaption {
            @include lib-font-size($font-size__s);
        }
    }

    &__actions {
        text-align: center;
        margin: 0;
    }

    .slick-prev {
        left: 1em;
    }

    .slick-next {
        right: 1em;
    }

}

.slick-slide .product-features {
    &,
    li {
        max-width: none;
    }

    &:hover,
    li:hover {
        box-shadow: none;
    }

    .action.primary {
        display: $button__display !important;
    }
}
