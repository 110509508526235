.product.info.detailed .product.data.items {
    @include lib-data-accordion();
    margin-bottom: $indent__base;

    .data.item {
        color: $color-sna-black;

        &.documents {
            .manual {
                display: block;
                margin-bottom: $indent__s;

                .manual-title {
                    display: block;
                    margin-right: $indent__base;

                    &:before {
                        content: '';
                        width: 6px;
                        height: 12px;
                        display: inline-block;
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 6 12' style='enable-background:new 0 0 6 12;' xml:space='preserve'%3E%3Cg id='icon-arrow-right-bahco' transform='translate(3.000000, 6.000000) rotate(90.000000) translate(-3.000000, -6.000000) translate(-3.000000, 3.000000)'%3E%3Cpath id='Path' fill='%23292827' class='st0' d='M11.78,5.84c-0.16,0.15-0.43,0.15-0.59,0L5.96,0.97L0.72,5.84c-0.16,0.15-0.43,0.15-0.59,0 s-0.16-0.4,0-0.55l5.53-5.13c0.08-0.08,0.18-0.11,0.3-0.11c0.1,0,0.22,0.04,0.3,0.11l5.53,5.13C11.95,5.44,11.95,5.69,11.78,5.84z' /%3E%3C/g%3E%3C/svg%3E");
                        margin-right: $indent__s;
                    }
                }

                .manual-title,
                .select-input-container {
                    width: 200px;
                    margin-right: $indent__s;
                }

                .manual-title,
                .select-input-container,
                .action.primary.small {
                    margin-bottom: $indent__s;
                }

                .action.primary.small {
                    width: 200px;
                    padding-top: 11px;
                    padding-bottom: 11px;
                }
            }
        }

        &.media {
            background-color: $color-sna-primario2;

            .title {
                color: $color-sna-white;
            }

            .content {
                max-width: 1008px;
                margin: 0 auto;
            }

            .product.attribute.product-media {
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                justify-content: space-evenly;

                .media-image-container {
                    display: inline-block;
                    flex: 0 1 50%;

                    .media-image {
                        width: 100%;
                        height: auto;
                        display: block;
                        margin: auto;
                    }
                }
            }
        }

        &.video {
            background-color: $color-sna-black;
            padding-top: 0;
            padding-bottom: 0;

            .title {
                display: none;
            }
            .content {
                max-width: 1008px;
                margin: 0 auto;
            }

            .product.attribute.video {
                position: relative;
                overflow: hidden;
                padding-top: 56.25%;

                iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border: 0;
                }
            }
        }
        &.details,
        &.additional,
        &.awards,
        &.documents,
        &.product-addto,
        &.accessories {
            background-color: $color-sna-primario4;
        }

        &.details {
            .product-details-list {
                padding-left: 1.2em;
                .detail-item {
                    margin-bottom: 0;
                }
            }
            .product-awards-list {
                @include lib-list-reset-styles();
            }
            .awards {
                @extend .abs-reset-list;
                @include lib-font-size($font-size__s);
                margin: 0;
                padding: 0;

                li {
                    display: grid;
                    grid-template-areas:
                            "logo award"
                            "logo year";
                    grid-template-columns: auto 1fr;
                    grid-template-rows: 1fr 1fr;
                    @include lib-css(grid-column-gap, $indent__s);
                    @include lib-css(margin-bottom, $indent__base);

                    &:last-child {
                        margin-bottom: 0;
                    }

                    img {
                        grid-area: logo;
                        height: 52px;
                    }

                    strong {
                        display: block;
                        grid-area: award;
                        align-self: end;
                        @include lib-css(font-weight, $font-weight__heavier);
                    }

                    small {
                        display: block;
                        grid-area: year;
                        @include lib-css(color, $color-sna-primario3);
                    }

                    &.without-label {
                        display: inline-block;
                        margin-right: $indent__s;

                        &:last-child {
                            margin-bottom: $indent__base;
                            margin-right: 0;
                        }

                        img {
                            height: 60px;
                        }
                    }
                }

            }
        }

        &.product-addto {
            .add-this_toolbox,
            .print-page {
                display: inline-block;

                & > a {
                    @include lib-link-secondary(
                        $_link-font-weight             : $font-weight__bold,
                        $_link-color                   : $color-sna-primario2,
                        $_link-color-visited           : $color-sna-primario2,
                        $_link-color-hover             : $color-sna-primario1,
                        $_link-color-active            : $color-sna-primario2
                    );
                    @include lib-font-size($font-size__s);
                    cursor: pointer;
                    margin-right: $indent__base;
                    display: inline-flex;
                    align-items: center;
                    vertical-align: middle;

                    > span {
                        vertical-align: middle;
                    }
                    > img {
                        margin: $indent__s;
                    }
                }
            }
            .share-product {
                display: inline-flex;
                align-items: center;
                @include lib-font-size($font-size__s);
                vertical-align: middle;

                strong {
                    color: $color-sna-primario2;
                }

                a {
                    @include lib-link-secondary(
                        $_link-font-weight: $font-weight__bold,
                        $_link-color: $color-sna-primario2,
                        $_link-color-visited: $color-sna-primario2,
                        $_link-color-hover: $color-sna-primario1,
                        $_link-color-active: $color-sna-primario2
                    );
                    padding: $indent__s;
                    display: inline-block;
                }
                .whatsapp-link {
                    display: none;
                }
            }
            .print-page {
                margin-left: $indent__l;
            }
            .action.towishlist {
                padding: 11px 28px;
            }
            .send-request,
            .action.towishlist {
                margin-bottom: $indent__s;
            }
        }
    }

    .data.item.set-content + .data.item.spare-parts,
    .data.item.set-content + .data.item.related,
    .data.item.set-content + .data.item.dependent,
    .data.item.spare-parts + .data.item.related,
    .data.item.spare-parts + .data.item.dependent,
    .data.item.related + .data.item.dependent{
        padding-top: 0;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .product.info.detailed .product.data.items {
        @include lib-data-accordion();
        .data.item {
            display: block;
            padding-top: $indent__xxl;
            padding-bottom: $indent__xxl;
            @include lib-font-size($font-size__s);

            .title {
                margin-bottom: $indent__m;
            }

            &.documents,
            &.product-addto {
                padding-top: 0;
            }

            &.product-addto {
                .add-this_toolbox {
                    margin-top: $indent__s;
                    > a {
                        padding: $indent__s 0;
                    }
                }
            }

            &.media {
                padding-bottom: $indent__base;
                .product.attribute.product-media {
                    .media-image-container {
                        margin-bottom: $indent__m;

                        .media-image {
                            max-width: 333px;
                        }
                    }
                }
            }

            &.details {
                .awards {
                    padding: 0;
                    margin: 0;
                    align-items: baseline;
                }
            }

            &.video {
                padding: 0;
            }
            &.documents {
                .manual {
                    .manual-title,
                    .select-input-container,
                    .action.primary.small {
                        width: 25.6rem;
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

@include max-screen($screen__xs-2) {
    .product.info.detailed .product.data.items {
        .data.item {
            display: block;
            padding-top: $indent__xl;
            padding-bottom: $indent__xl;

            &.media {
                .product.attribute.product-media {
                    .media-image-container {
                        margin-bottom: $indent__base;
                        flex: 0 1 100%;

                        .media-image {
                            max-width: 100%;
                        }
                    }
                }
            }

            &.product-addto {
                .add-this_toolbox .whatsapp-link {
                    display: inline-block;
                }
            }

        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .product.info.detailed .product.data.items {
        display: block;
        .data.item {
            padding-top: 120px;
            padding-bottom: 144px;
            flex: 0 1 100%;
            max-width: 100%;
            @include lib-font-size($font-size__base);

            .title {
                margin-bottom: $indent__xl;
            }

            &.product-addto {
                text-align: right;
                .add-this_toolbox > * {
                    margin-left: $indent__s;
                }
            }

            &.details {
                padding-bottom: $indent__m;


                .content {
                    display: flex;

                    .details,
                    .awards {
                        flex: 1 1 50%;
                    }

                    .details + .awards {
                        padding-left: $indent__xxxxl;
                    }

                    .awards {
                        min-height: unset;
                        align-self: flex-start;
                    }
                }
            }

            &.documents {
                display: block;
                padding-top: $indent__xxl;
                padding-bottom: $indent__xxl;
                padding-right: $indent__xl;
                flex: 0 1 50%;

                .manual ~ .manual {
                    margin-top: $indent__m;
                }
            }

            &.media {
                padding-bottom: 112px;
                .product.attribute.product-media {
                    .media-image-container {
                        margin-bottom: $indent__l;

                        .media-image {
                            max-width: 495px;
                        }
                    }
                }
            }
        }
        .data.item.documents + .data.item.product-addto {
            flex: 0 1 50%;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
        }

        .data.item + .data.item.product-addto {
            padding-top: 0;
        }
    }
}

@include min-screen($screen__l) {
    .product.info.detailed .product.data.items {
        display: flex;
        flex-wrap: wrap;

        .data.item {
            &.documents {
                padding-top: $indent__l;
                flex: 0 1 50%;
            }
        }
    }
}


@include min-screen($screen__xl) {
    .product.info.detailed .product.data.items {
        .data.item.documents {
            .manual {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: wrap;
            }
        }
    }
}
