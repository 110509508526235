.nav-toggle {
    cursor: pointer;
    display: inline-block;
    font-size: 0;
    z-index: 14;

    @include on4menu-mobile {
        > .nav-toggle__icon-close {
            display: none;
        }
        &[aria-expanded="true"] {
            > .nav-toggle__icon-open {
                display: none;
            }
            > .nav-toggle__icon-close {
                display: block;
                width: 2rem;
                height: 2rem;
            }
        }
        > span {
            @include lib-font-size($font-size__s);
            @include lib-link-secondary(
                $_link-font-weight             : $font-weight__bold,
                $_link-text-transform          : uppercase
            );
            letter-spacing: 1.2px;
            margin-left: $indent__s;
        }
    }

    @include on4menu-desktop {
        display: none;
    }
}
@include max-screen($screen__xxxs) {
    .page-header.fixed .nav-toggle > span {
        display: none;
    }
}

