//
//  Buttons
//  _____________________________________________

//
//  Button with solid or pictire background
//  ---------------------------------------------

@mixin lib-button(
    $_button-font-family                 : $button__font-family,
    $_button-font-size                   : $button__font-size,
    $_button-font-weight                 : $button__font-weight,
    $_button-cursor                      : $button__cursor,
    $_button-display                     : $button__display,
    $_button-disabled-opacity            : $button__disabled__opacity,
    $_button-line-height                 : $button__line-height,
    $_button-width                       : $button__width,
    $_button-min-width                   : $button__min-width,
    $_button-margin                      : $button__margin,
    $_button-padding                     : $button__padding,
    $_button-color                       : $button__color,
    $_button-background                  : $button__background,
    $_button-border                      : $button__border,
    $_button-text-transform              : $button__text-transform,

    $_button-color-hover                 : $button__hover__color,
    $_button-background-hover            : $button__hover__background,
    $_button-border-hover                : $button__hover__border,
    $_button-color-active                : $button__active__color,
    $_button-background-active           : $button__active__background,
    $_button-border-active               : $button__active__border,

    $_button-gradient                    : $button__gradient,
    $_button-gradient-direction          : $button__gradient-direction,
    $_button-gradient-color-start        : $button__gradient-color-start,
    $_button-gradient-color-end          : $button__gradient-color-end,
    $_button-gradient-color-start-hover  : $button__hover__gradient-color-start,
    $_button-gradient-color-end-hover    : $button__hover__gradient-color-end,
    $_button-gradient-color-start-active : $button__active__gradient-color-start,
    $_button-gradient-color-end-active   : $button__active__gradient-color-end,

    $_button-icon-use                    : $button-icon__use,
    $_button-font-content                : $button-icon__content,
    $_button-icon-font                   : $button-icon__font,
    $_button-icon-font-size              : $button-icon__font-size,
    $_button-icon-font-line-height       : $button-icon__line-height,
    $_button-icon-font-color             : $button-icon__color,
    $_button-icon-font-color-hover       : $button-icon__hover__font-color,
    $_button-icon-font-color-active      : $button-icon__active__font-color,
    $_button-icon-font-margin            : $button-icon__margin,
    $_button-icon-font-vertical-align    : $button-icon__vertical-align,
    $_button-icon-font-position          : $button-icon__position,
    $_button-icon-font-text-hide         : $button-icon__text-hide
) {
    background-image: none; // Reset unusual Firefox-on-Android default style
    @include lib-css(background, $_button-background);
    @include lib-background-gradient(
            $_button-gradient-color-start,
            $_button-gradient-color-end,
            $_button-gradient-direction,
            $_button-gradient
    );
    @include lib-css(display, $_button-display);
    justify-content: center;
    align-items: center;
    text-align: center;
    vertical-align: middle;
    box-sizing: border-box;

    @include lib-css(border, $_button-border);
    @include lib-css(color, $_button-color);
    @include lib-css(cursor, $_button-cursor);
    @include lib-css(font-family, $_button-font-family);
    @include lib-font-size($_button-font-size);
    @include lib-css(font-weight, $_button-font-weight);
    @include lib-css(margin, $_button-margin);
    @include lib-css(padding, $_button-padding);
    @include lib-css(width, $_button-width);
    @include lib-css(min-width, $_button-min-width);
    @include lib-css(text-transform, $_button-text-transform);
    @include lib-line-height($_button-line-height);

    @include _lib-button-icon (
        $_button-icon-use          : $_button-icon-use,
        $_icon-font-content        : $_button-font-content,
        $_icon-font                : $_button-icon-font,
        $_icon-font-size           : $_button-icon-font-size,
        $_icon-font-line-height    : $_button-icon-font-line-height,
        $_icon-font-color          : $_button-icon-font-color,
        $_icon-font-color-hover    : $_button-icon-font-color-hover,
        $_icon-font-color-active   : $_button-icon-font-color-active,
        $_icon-font-margin         : $_button-icon-font-margin,
        $_icon-font-vertical-align : $_button-icon-font-vertical-align,
        $_icon-font-position       : $_button-icon-font-position,
        $_icon-font-text-hide      : $_button-icon-font-text-hide
    );

    &:focus,
    &:active {
        @include lib-css(background, $_button-background-active);
        @include lib-background-gradient(
                $_button-gradient-color-start-active,
                $_button-gradient-color-end-active,
                $_button-gradient-direction,
                $_button-gradient
        );
        @include lib-css(border, $_button-border-active);
        @include lib-css(color, $_button-color-active);
    }

    &:hover {
        @include lib-css(background, $_button-background-hover);
        @include lib-background-gradient(
                $_button-gradient-color-start-hover,
                $_button-gradient-color-end-hover,
                $_button-gradient-direction,
                $_button-gradient
        );
        @include lib-css(border, $_button-border-hover);
        @include lib-css(color, $_button-color-hover);
    }

    &.disabled,
    &.disabled-recaptcha,
    &[disabled],
    fieldset[disabled] & {
        @include lib-css(opacity, $_button-disabled-opacity);
        cursor: default;
        pointer-events: none; // Disabling of clicks
    }

    > svg {
        margin-right: $indent__s;
    }

    > span {
        vertical-align: middle;
    }
}

//
//  Button size
//  ---------------------------------------------

@mixin lib-button-size(
    $_button-line-height : $button__line-height,
    $_button-padding     : $button__padding
) {
    @include lib-line-height($_button-line-height);
    @include lib-css(padding, $_button-padding);
}

//
//  Large buttons
//  ---------------------------------------------

@mixin lib-button-l(
    $_button-l-font-size : $button__font-size__l,
    $_button-l-height    : $button__line-height__l,
    $_button-l-padding   : $button__padding__l
) {
    @include lib-button-size(
        $_button-line-height : $_button-l-height,
        $_button-padding     : $_button-l-padding
    );
    @include lib-font-size($_button-l-font-size);
}

//
//  Small buttons
//  ---------------------------------------------

@mixin lib-button-s(
    $_button-s-font-size : $button__font-size__s,
    $_button-s-height    : $button__line-height__s,
    $_button-s-padding   : $button__padding__s
) {
    @include lib-button-size(
        $_button-line-height : $_button-s-height,
        $_button-padding     : $_button-s-padding
    );
    @include lib-font-size($_button-s-font-size);
}

//
//  Link as button
//  ---------------------------------------------

@mixin lib-link-as-button() {
    @include lib-css(display, $button__display);
    @include lib-css(color, $button__color);
    @include lib-css(text-transform, $button__text-transform);
    text-decoration: none;

    &:hover,
    &:focus {
        text-decoration: none;
        @include lib-css(color, $button__hover__color);
    }

    &:active {
        @include lib-css(color, $button__active__color);
    }
}

//
//  Button as link
//  ---------------------------------------------

@mixin lib-button-as-link(
    $_link-color       : $link__color,
    $_link-color-hover : $link__hover__color,
    $_line-height      : $line-height__base,
    $_disabled_opacity : $button__disabled__opacity,
    $_margin           : 0,
    $_padding          : 0
) {
    @include lib-css(line-height, $_line-height);
    @include lib-css(margin, $_margin);
    @include lib-css(padding, $_padding);
    @include lib-link();
    background: none;
    border: 0;
    display: inline;

    &:hover {
        @include lib-css(color, $_link-color-hover);
    }

    &:hover,
    &:active,
    &:focus {
        background: none;
        border: 0;
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        @include lib-css(color, $_link-color);
        @include lib-css(opacity, $_disabled_opacity);
        cursor: default;
        pointer-events: none; // Disabling of clicks
        text-decoration: underline;
    }

    > span {
        vertical-align: middle;
    }
}

@mixin _lib-button-with-arrow__base() {
    display: inline-flex;
    align-items: center;
    @include lib-css(color, $color-sna-primario1);
    @include lib-font-size(13);
    @include lib-css(font-weight, $font-weight__bold);
    @include lib-css(text-transform, $button__text-transform);

    &:hover,
    &:active,
    &:visited,
    &:focus {
        text-decoration: none;
        @include lib-css(color, $color-sna-secundario1);
    }
}

@mixin _lib-button-with-arrow__left() {
    text-align: left;

    &:hover::before {
        @include lib-animations-bounce-left();
    }

    &::before {
        content: '';
        display: inline-block;
        height: 1.3rem;
        width: 2.6rem;
        margin-right: .7em;
        background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2026%2013%22%3E%3Cpath%20fill%3D%22%23B61811%22%20fill-rule%3D%22evenodd%22%20d%3D%22M.31%205.92C1.6%204.65%202.88%203.37%204.17%202.09%204.82%201.45%205.46.81%206.12.18a.566.566%200%200%201%20.82-.01c.22.23.23.59.01.82-.05.06-.1.11-.16.16L2.25%205.66c-.06.06-.12.13-.21.23h23.09c.14%200%20.27.01.4.03.27.05.46.28.46.55s-.19.5-.45.55c-.13.03-.27.04-.41.03H2.04c.1.11.16.18.22.24%201.5%201.49%202.99%202.98%204.49%204.46.09.09.18.18.25.28.18.24.13.58-.1.77-.26.22-.58.18-.88-.11C4.11%2010.8%202.21%208.91.31%207.02c-.41-.4-.41-.69%200-1.1z%22%20clip-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E');
        background-repeat: no-repeat;
        vertical-align: middle;
    }

    &:hover::before {
        @include lib-animations-bounce-left();
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 13'%3E%3Cpath fill='%239F001F' fill-rule='evenodd' d='M.31 5.92C1.6 4.65 2.88 3.37 4.17 2.09 4.82 1.45 5.46.81 6.12.18a.566.566 0 0 1 .82-.01c.22.23.23.59.01.82-.05.06-.1.11-.16.16L2.25 5.66c-.06.06-.12.13-.21.23h23.09c.14 0 .27.01.4.03.27.05.46.28.46.55s-.19.5-.45.55c-.13.03-.27.04-.41.03H2.04c.1.11.16.18.22.24 1.5 1.49 2.99 2.98 4.49 4.46.09.09.18.18.25.28.18.24.13.58-.1.77-.26.22-.58.18-.88-.11C4.11 10.8 2.21 8.91.31 7.02c-.41-.4-.41-.69 0-1.1z' clip-rule='evenodd'/%3E%3C/svg%3E");
    }
}

@mixin _lib-button-with-arrow__right() {
    text-align: right;

    &:hover::after {
        @include lib-animations-bounce-right();
    }

    &::after {
        content: '';
        display: inline-block;
        height: 1.3rem;
        width: 2.6rem;
        margin-left: .7em;
        background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2026%2013%22%3E%3Cpath%20fill%3D%22%23B61811%22%20d%3D%22M25.68%205.92l-3.86-3.83c-.65-.64-1.29-1.28-1.95-1.91a.553.553%200%200%200-.81-.01c-.23.22-.23.59-.01.82.05.06.1.11.16.16l4.54%204.51c.06.06.12.12.21.23H.87c-.14%200-.27.01-.4.03-.27.06-.47.29-.47.56%200%20.27.19.5.45.55.13.03.27.04.41.03h23.09c-.1.11-.16.18-.22.24-1.5%201.49-2.99%202.98-4.49%204.46-.09.09-.18.18-.25.28-.18.24-.13.58.1.77.26.22.58.18.88-.11%201.91-1.89%203.81-3.78%205.71-5.67.42-.41.42-.7%200-1.11z%22%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E');
        background-repeat: no-repeat;
        vertical-align: middle;
        background-repeat: no-repeat;
    }

    &:hover::after {
        @include lib-animations-bounce-right();
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 255.93 128.51'%3E%3Cpath fill='%239F001F' d='M241.4,7q-19-19-38-38c-6.37-6.36-12.71-12.74-19.16-19a5.63,5.63,0,0,0-8.08-.06,5.81,5.81,0,0,0-.1,8.12c.48.57,1,1.07,1.57,1.61l44.7,44.75c.59.59,1.14,1.24,2.09,2.28H-2.93A21.92,21.92,0,0,0-6.9,7a5.66,5.66,0,0,0-4.56,5.5A5.64,5.64,0,0,0-7,18a19.38,19.38,0,0,0,4,.34q111.85,0,223.73,0h3.69c-1,1.1-1.58,1.77-2.2,2.39Q200.13,42.88,178,65a24.33,24.33,0,0,0-2.5,2.78,5.53,5.53,0,0,0,1,7.61c2.58,2.16,5.74,1.77,8.64-1.13Q213.29,46.12,241.38,18C245.49,13.9,245.49,11.09,241.4,7Z' transform='translate(11.46 51.77)'/%3E%3C/svg%3E");
    }
}

@mixin lib-button-go() {
    @include _lib-button-with-arrow__base();
    @include _lib-button-with-arrow__right();
}

@mixin lib-button-go--white() {
    @include lib-button-go();
    @include lib-css(color, $color-white);

    &:hover,
    &:active,
    &:visited,
    &:focus {
        @include lib-css(color, $color__primary--light);
    }

    &::after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 255.93 128.51'%3E%3Cpath fill='%23ffffff' d='M241.4,7q-19-19-38-38c-6.37-6.36-12.71-12.74-19.16-19a5.63,5.63,0,0,0-8.08-.06,5.81,5.81,0,0,0-.1,8.12c.48.57,1,1.07,1.57,1.61l44.7,44.75c.59.59,1.14,1.24,2.09,2.28H-2.93A21.92,21.92,0,0,0-6.9,7a5.66,5.66,0,0,0-4.56,5.5A5.64,5.64,0,0,0-7,18a19.38,19.38,0,0,0,4,.34q111.85,0,223.73,0h3.69c-1,1.1-1.58,1.77-2.2,2.39Q200.13,42.88,178,65a24.33,24.33,0,0,0-2.5,2.78,5.53,5.53,0,0,0,1,7.61c2.58,2.16,5.74,1.77,8.64-1.13Q213.29,46.12,241.38,18C245.49,13.9,245.49,11.09,241.4,7Z' transform='translate(11.46 51.77)'/%3E%3C/svg%3E");
    }

    &:hover::after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 255.93 128.51'%3E%3Cpath fill='%23CC9E9C' d='M241.4,7q-19-19-38-38c-6.37-6.36-12.71-12.74-19.16-19a5.63,5.63,0,0,0-8.08-.06,5.81,5.81,0,0,0-.1,8.12c.48.57,1,1.07,1.57,1.61l44.7,44.75c.59.59,1.14,1.24,2.09,2.28H-2.93A21.92,21.92,0,0,0-6.9,7a5.66,5.66,0,0,0-4.56,5.5A5.64,5.64,0,0,0-7,18a19.38,19.38,0,0,0,4,.34q111.85,0,223.73,0h3.69c-1,1.1-1.58,1.77-2.2,2.39Q200.13,42.88,178,65a24.33,24.33,0,0,0-2.5,2.78,5.53,5.53,0,0,0,1,7.61c2.58,2.16,5.74,1.77,8.64-1.13Q213.29,46.12,241.38,18C245.49,13.9,245.49,11.09,241.4,7Z' transform='translate(11.46 51.77)'/%3E%3C/svg%3E");
    }
}

/**
 * 1. Min touchable area 48px for a11y, UX & SEO.
 *    Not applied to button-go in order to not alter current look and feel
 */
@mixin lib-button-go-back() {
    @include _lib-button-with-arrow__base();
    @include _lib-button-with-arrow__left();
    min-height: 48px; /* [1] */
}

//
//  Responsive
//  ---------------------------------------------

@mixin lib-button-responsive() {
    width: 100%;
}

//
//  Button reset
//  ---------------------------------------------

@mixin lib-button-reset() {
    background-image: none; // Reset unusual Firefox-on-Android default style
    background: none;
    -moz-box-sizing: content-box; // Hack: fix Firefox button line-height problem
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    @include lib-css(font-weight, $font-weight__regular);

    &:focus,
    &:active {
        background: none;
        border: none;
    }

    &:hover {
        background: none;
        border: none;
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        cursor: not-allowed;
        pointer-events: none; // Disabling of clicks
        @include lib-css(opacity, $button__disabled__opacity);
    }
}

@mixin lib-custom-button-reset() {
    background-image: none; // Reset unusual Firefox-on-Android default style
    background: none;
    -moz-box-sizing: content-box; // Hack: fix Firefox button line-height problem
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    @include lib-css(font-weight, $font-weight__regular);

    &:focus,
    &:active {
        background: none;
        border: none;
    }

    &:hover {
        background: none;
        border: none;
    }

}

//
//  Button primary
//  ---------------------------------------------

@mixin lib-button-primary(
    $_button-line-height                 : $button-primary__line-height,
    $_button-width                       : $button-primary__width,
    $_button-min-width                   : $button-primary__min-width,
    $_button-margin                      : $button-primary__margin,
    $_button-padding                     : $button-primary__padding,
    $_button-color                       : $button-primary__color,
    $_button-background                  : $button-primary__background,
    $_button-border                      : $button-primary__border,
    $_button-gradient-color-start        : $button-primary__gradient-color-start,
    $_button-gradient-color-end          : $button-primary__gradient-color-end,
    $_button-color-hover                 : $button-primary__hover__color,
    $_button-background-hover            : $button-primary__hover__background,
    $_button-border-hover                : $button-primary__hover__border,
    $_button-gradient-color-start-hover  : $button-primary__hover__gradient-color-start,
    $_button-gradient-color-end-hover    : $button-primary__hover__gradient-color-end,
    $_button-color-active                : $button-primary__active__color,
    $_button-background-active           : $button-primary__active__background,
    $_button-border-active               : $button-primary__active__border,
    $_button-gradient-color-start-active : $button-primary__active__gradient-color-start,
    $_button-gradient-color-end-active   : $button-primary__active__gradient-color-end,
    $_button-gradient                    : $button-primary__gradient,
    $_button-gradient-direction          : $button-primary__gradient-direction
) {
    @include lib-button(
        $_button-line-height                 : $_button-line-height,
        $_button-width                       : $_button-width,
        $_button-min-width                   : $_button-min-width,
        $_button-margin                      : $_button-margin,
        $_button-padding                     : $_button-padding,
        $_button-color                       : $_button-color,
        $_button-background                  : $_button-background,
        $_button-border                      : $_button-border,
        $_button-color-hover                 : $_button-color-hover,
        $_button-background-hover            : $_button-background-hover,
        $_button-border-hover                : $_button-border-hover,
        $_button-color-active                : $_button-color-active,
        $_button-background-active           : $_button-background-active,
        $_button-border-active               : $_button-border-active,
        $_button-gradient                    : $_button-gradient,
        $_button-gradient-direction          : $_button-gradient-direction,
        $_button-gradient-color-start        : $_button-gradient-color-start,
        $_button-gradient-color-end          : $_button-gradient-color-end,
        $_button-gradient-color-start-hover  : $_button-gradient-color-start-hover,
        $_button-gradient-color-end-hover    : $_button-gradient-color-end-hover,
        $_button-gradient-color-start-active : $_button-gradient-color-start-active,
        $_button-gradient-color-end-active   : $_button-gradient-color-end-active
    );
}
//
//  Button primary small
//  ---------------------------------------------

@mixin lib-button-primary-small(
    $_button-line-height                 : $button-primary__line-height,
    $_button-width                       : $button-primary__width,
    $_button-min-width                   : $button-primary-small__min-width,
    $_button-margin                      : $button-primary__margin,
    $_button-padding                     : $button-primary-small__padding,
    $_button-color                       : $button-primary__color,
    $_button-background                  : $button-primary__background,
    $_button-border                      : $button-primary__border,
    $_button-gradient-color-start        : $button-primary__gradient-color-start,
    $_button-gradient-color-end          : $button-primary__gradient-color-end,
    $_button-color-hover                 : $button-primary__hover__color,
    $_button-background-hover            : $button-primary__hover__background,
    $_button-border-hover                : $button-primary__hover__border,
    $_button-gradient-color-start-hover  : $button-primary__hover__gradient-color-start,
    $_button-gradient-color-end-hover    : $button-primary__hover__gradient-color-end,
    $_button-color-active                : $button-primary__active__color,
    $_button-background-active           : $button-primary__active__background,
    $_button-border-active               : $button-primary__active__border,
    $_button-gradient-color-start-active : $button-primary__active__gradient-color-start,
    $_button-gradient-color-end-active   : $button-primary__active__gradient-color-end,
    $_button-gradient                    : $button-primary__gradient,
    $_button-gradient-direction          : $button-primary__gradient-direction
) {
    @include lib-button(
        $_button-line-height                 : $_button-line-height,
        $_button-width                       : $_button-width,
        $_button-min-width                   : $_button-min-width,
        $_button-margin                      : $_button-margin,
        $_button-padding                     : $_button-padding,
        $_button-color                       : $_button-color,
        $_button-background                  : $_button-background,
        $_button-border                      : $_button-border,
        $_button-color-hover                 : $_button-color-hover,
        $_button-background-hover            : $_button-background-hover,
        $_button-border-hover                : $_button-border-hover,
        $_button-color-active                : $_button-color-active,
        $_button-background-active           : $_button-background-active,
        $_button-border-active               : $_button-border-active,
        $_button-gradient                    : $_button-gradient,
        $_button-gradient-direction          : $_button-gradient-direction,
        $_button-gradient-color-start        : $_button-gradient-color-start,
        $_button-gradient-color-end          : $_button-gradient-color-end,
        $_button-gradient-color-start-hover  : $_button-gradient-color-start-hover,
        $_button-gradient-color-end-hover    : $_button-gradient-color-end-hover,
        $_button-gradient-color-start-active : $_button-gradient-color-start-active,
        $_button-gradient-color-end-active   : $_button-gradient-color-end-active
    );
}
//
//  Button primary
//  ---------------------------------------------

@mixin lib-button-primary-over-dark-bg(
    $_button-background                  : $button-primary-over-dark-bg__background,
    $_button-border                      : $button-primary-over-dark-bg__border,
    $_button-color                       : $button-primary-over-dark-bg__color,
    $_button-background-hover            : $button-primary-over-dark-bg__hover__background,
    $_button-border-hover                : $button-primary-over-dark-bg__hover__border,
    $_button-color-hover                 : $button-primary-over-dark-bg__hover__color,
    $_button-background-active           : $button-primary-over-dark-bg__active__background,
    $_button-border-active               : $button-primary-over-dark-bg__active__border,
    $_button-color-active                : $button-primary-over-dark-bg__active__color
) {
    @include lib-button-primary(
        $_button-background                  : $_button-background,
        $_button-border                      : $_button-border,
        $_button-color                       : $_button-color,
        $_button-background-hover            : $_button-background-hover,
        $_button-border-hover                : $_button-border-hover,
        $_button-color-hover                 : $_button-color-hover,
        $_button-background-active           : $_button-background-active,
        $_button-border-active               : $_button-border-active,
        $_button-color-active                : $_button-color-active
    );
}

//
//  Button secondary
//  ---------------------------------------------

@mixin lib-button-secondary(
    $_button-line-height                 : $button-secondary__line-height,
    $_button-width                       : $button-secondary__width,
    $_button-min-width                   : $button-secondary__min-width,
    $_button-margin                      : $button-secondary__margin,
    $_button-padding                     : $button-secondary__padding,
    $_button-color                       : $button-secondary__color,
    $_button-background                  : $button-secondary__background,
    $_button-border                      : $button-secondary__border,
    $_button-gradient-color-start        : $button-secondary__gradient-color-start,
    $_button-gradient-color-end          : $button-secondary__gradient-color-end,
    $_button-color-hover                 : $button-secondary__hover__color,
    $_button-background-hover            : $button-secondary__hover__background,
    $_button-border-hover                : $button-secondary__hover__border,
    $_button-gradient-color-start-hover  : $button-secondary__hover__gradient-color-start,
    $_button-gradient-color-end-hover    : $button-secondary__hover__gradient-color-end,
    $_button-color-active                : $button-secondary__active__color,
    $_button-background-active           : $button-secondary__active__background,
    $_button-border-active               : $button-secondary__active__border,
    $_button-gradient-color-start-active : $button-secondary__active__gradient-color-start,
    $_button-gradient-color-end-active   : $button-secondary__active__gradient-color-end,
    $_button-gradient                    : $button-secondary__gradient,
    $_button-gradient-direction          : $button-secondary__gradient-direction
) {
    @include lib-button(
        $_button-line-height                 : $_button-line-height,
        $_button-width                       : $_button-width,
        $_button-min-width                   : $_button-min-width,
        $_button-margin                      : $_button-margin,
        $_button-padding                     : $_button-padding,
        $_button-color                       : $_button-color,
        $_button-background                  : $_button-background,
        $_button-border                      : $_button-border,
        $_button-color-hover                 : $_button-color-hover,
        $_button-background-hover            : $_button-background-hover,
        $_button-border-hover                : $_button-border-hover,
        $_button-color-active                : $_button-color-active,
        $_button-background-active           : $_button-background-active,
        $_button-border-active               : $_button-border-active,
        $_button-gradient                    : $_button-gradient,
        $_button-gradient-direction          : $_button-gradient-direction,
        $_button-gradient-color-start        : $_button-gradient-color-start,
        $_button-gradient-color-end          : $_button-gradient-color-end,
        $_button-gradient-color-start-hover  : $_button-gradient-color-start-hover,
        $_button-gradient-color-end-hover    : $_button-gradient-color-end-hover,
        $_button-gradient-color-start-active : $_button-gradient-color-start-active,
        $_button-gradient-color-end-active   : $_button-gradient-color-end-active
    );
}

//
//  Button special
//  ---------------------------------------------

@mixin lib-button-special(
    $_button-line-height                 : $button-special__line-height,
    $_button-width                       : $button-special__width,
    $_button-min-width                   : $button-special__min-width,
    $_button-max-width                   : $button-special__max-width,
    $_button-margin                      : $button-special__margin,
    $_button-padding                     : $button-special__padding,
    $_button-color                       : $button-special__color,
    $_button-background                  : $button-special__background,
    $_button-border                      : $button-special__border,
    $_button-gradient-color-start        : $button-special__gradient-color-start,
    $_button-gradient-color-end          : $button-special__gradient-color-end,
    $_button-color-hover                 : $button-special__hover__color,
    $_button-background-hover            : $button-special__hover__background,
    $_button-border-hover                : $button-special__hover__border,
    $_button-gradient-color-start-hover  : $button-special__hover__gradient-color-start,
    $_button-gradient-color-end-hover    : $button-special__hover__gradient-color-end,
    $_button-color-active                : $button-special__active__color,
    $_button-background-active           : $button-special__active__background,
    $_button-border-active               : $button-special__active__border,
    $_button-gradient-color-start-active : $button-special__active__gradient-color-start,
    $_button-gradient-color-end-active   : $button-special__active__gradient-color-end,
    $_button-gradient                    : $button-special__gradient,
    $_button-gradient-direction          : $button-special__gradient-direction
) {
    @include lib-css(max-width, $_button-max-width);
    @include lib-button(
        $_button-line-height                 : $_button-line-height,
        $_button-width                       : $_button-width,
        $_button-min-width                   : $_button-min-width,
        $_button-margin                      : $_button-margin,
        $_button-padding                     : $_button-padding,
        $_button-color                       : $_button-color,
        $_button-background                  : $_button-background,
        $_button-border                      : $_button-border,
        $_button-color-hover                 : $_button-color-hover,
        $_button-background-hover            : $_button-background-hover,
        $_button-border-hover                : $_button-border-hover,
        $_button-color-active                : $_button-color-active,
        $_button-background-active           : $_button-background-active,
        $_button-border-active               : $_button-border-active,
        $_button-gradient                    : $_button-gradient,
        $_button-gradient-direction          : $_button-gradient-direction,
        $_button-gradient-color-start        : $_button-gradient-color-start,
        $_button-gradient-color-end          : $_button-gradient-color-end,
        $_button-gradient-color-start-hover  : $_button-gradient-color-start-hover,
        $_button-gradient-color-end-hover    : $_button-gradient-color-end-hover,
        $_button-gradient-color-start-active : $_button-gradient-color-start-active,
        $_button-gradient-color-end-active   : $_button-gradient-color-end-active
    );

    &:visited,
    &:focus,
    &:hover {
        color: $_button-color;
        text-decoration: none;
    }

    > svg {
        fill: currentColor;
        margin-right: $indent__s;
        flex-shrink: 0;
    }
    > span {
        vertical-align: middle;
    }
}

//
//  Button revert secondary color
//  ---------------------------------------------

@mixin lib-button-revert-secondary-color(
    $_button-color             : $button__color,
    $_button-background        : $button__background,
    $_button-border            : $button__border,

    $_button-color-hover       : $button__hover__color,
    $_button-background-hover  : $button__hover__background,
    $_button-border-hover      : $button__hover__border,

    $_button-color-active      : $button__active__color,
    $_button-background-active : $button__active__background,
    $_button-border-active     : $button__active__border
) {
    @include lib-css(background, $_button-background);
    @include lib-css(border, $_button-border);
    @include lib-css(color, $_button-color);

    &:focus,
    &:active {
        @include lib-css(background, $_button-background-active);
        @include lib-css(border, $_button-border-active);
        @include lib-css(color, $_button-color-active);
    }

    &:hover {
        @include lib-css(background, $_button-background-hover);
        @include lib-css(border, $_button-border-hover);
        @include lib-css(color, $_button-color-hover);
    }
}

//
//  Button revert secondary size
//  ---------------------------------------------

@mixin lib-button-revert-secondary-size(
    $_button-font-size   : $button__font-size,
    $_button-line-height : $button__line-height,
    $_button-padding     : $button__padding
) {
    @include lib-font-size($_button-font-size);
    @include lib-button-size(
        $_button-line-height : $_button-line-height,
        $_button-padding     : $_button-padding
    )
}

//
//  Button expand
//  ---------------------------------------------
@mixin lib-button-expandable(
    $_button-expandable-display             : $button-expandable__display,
    $_button-expandable-width               : $button-expandable__width,
    $_button-expandable-margin              : $button-expandable__margin,
    $_button-expandable-padding             : $button-expandable__padding,

    $_button-expandable-background          : $button-expandable__background,
    $_button-expandable-border              : $button-expandable__border,
    $_button-expandable-border-radius       : $button-expandable__border-radius,
    $_button-expandable-color               : $button-expandable__color,
    $_button-expandable-cursor              : $button-expandable__cursor,

    $_button-expandable-font-family         : $button-expandable__font-family,
    $_button-expandable-font-size           : $button-expandable__font-size,
    $_button-expandable-font-weight         : $button-expandable__font-weight,
    $_button-expandable-line-height         : $button-expandable__line-height,
    $_button-expandable-text-transform      : $button-expandable__text-transform
) {
    @include lib-button-reset();

    @include lib-css(display, $_button-expandable-display);
    @include lib-css(width, $_button-expandable-width);
    @include lib-css(margin, $_button-expandable-margin);
    @include lib-css(padding, $_button-expandable-padding);

    @include lib-css(background, $_button-expandable-background);
    @include lib-css(border, $_button-expandable-border);
    @include lib-css(border-radius, $_button-expandable-border-radius);
    @include lib-css(color, $_button-expandable-color);
    @include lib-css(cursor, $_button-expandable-cursor);

    @include lib-css(font-family, $_button-expandable-font-family);
    @include lib-font-size($_button-expandable-font-size);
    @include lib-css(font-weight, $_button-expandable-font-weight);
    @include lib-line-height($_button-expandable-line-height);
    @include lib-css(text-transform, $_button-expandable-text-transform);

    > svg {
        fill: currentColor;
        margin-left: $indent__s;
    }
}

//
//  Inner buttons mixins
//  ---------------------------------------------

@mixin _lib-button-icon (
    $_button-icon-use          : $button-icon__use,
    $_icon-font-content        : $button-icon__content,
    $_icon-font                : $button-icon__font,
    $_icon-font-size           : $button-icon__font-size,
    $_icon-font-line-height    : $button-icon__line-height,
    $_icon-font-color          : $button-icon__color,
    $_icon-font-color-hover    : $button-icon__hover__font-color,
    $_icon-font-color-active   : $button-icon__active__font-color,
    $_icon-font-margin         : $button-icon__margin,
    $_icon-font-vertical-align : $button-icon__vertical-align,
    $_icon-font-position       : $button-icon__position,
    $_icon-font-text-hide      : $button-icon__text-hide
) {
    @if $_button-icon-use {
        @include lib-button-icon (
            $_icon-font-content       : $_icon-font-content,
            $_icon-font               : $_icon-font,
            $_icon-font-size          : $_icon-font-size,
            $_icon-font-line-height   : $_icon-font-line-height,
            $_icon-font-color         : $_icon-font-color,
            $_icon-font-color-hover   : $_icon-font-color-hover,
            $_icon-font-color-active  : $_icon-font-color-active,
            $_icon-font-margin        : $_icon-font-margin,
            $_icon-font-vertical-align: $_icon-font-vertical-align,
            $_icon-font-position      : $_icon-font-position,
            $_icon-font-text-hide     : $_icon-font-text-hide
        );
    }
}

@mixin lib-button-icon(
    $_icon-font-content,
    $_icon-font: $button-icon__font,
    $_icon-font-size: $button-icon__font-size,
    $_icon-font-line-height: $button-icon__line-height,
    $_icon-font-color: $button-icon__color,
    $_icon-font-color-hover: $button-icon__hover__font-color,
    $_icon-font-color-active: $button-icon__active__font-color,
    $_icon-font-margin: $button-icon__margin,
    $_icon-font-vertical-align: $button-icon__vertical-align,
    $_icon-font-position: $button-icon__position,
    $_icon-font-text-hide: $button-icon__text-hide
) {
    @include lib-icon-font(
        $_icon-font-content: $_icon-font-content,
        $_icon-font: $_icon-font,
        $_icon-font-size: $_icon-font-size,
        $_icon-font-line-height: $_icon-font-line-height,
        $_icon-font-color: $_icon-font-color,
        $_icon-font-color-hover: $_icon-font-color-hover,
        $_icon-font-color-active: $_icon-font-color-active,
        $_icon-font-margin: $_icon-font-margin,
        $_icon-font-vertical-align: $_icon-font-vertical-align,
        $_icon-font-position: $_icon-font-position,
        $_icon-font-text-hide: $_icon-font-text-hide
    );
}

@mixin lib-button-has-svg-icon() {
    padding: $button-with-svg__padding;
}
