.cms-page-section.is-category-list,
.landing-section.is-category-list {

    .cms-page-section__title,
    .landing-section__title {
        text-align: left;
        @include lib-css(font-weight, $font-weight__hairline);
    }

    .cms-page-section__body,
    .landing-section__body {
        column-count: inherit;
        @include lib-category-product-list();
    }

}
