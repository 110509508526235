//
//  Variables
//  _____________________________________________

$autocomplete__background-color  : $color-white !default;
$autocomplete__border            : 1px solid $form-element-input__border-color !default;
$autocomplete-item__border       : 1px solid $color-gray90 !default;
$autocomplete-item__hover__color : $color-gray91 !default;
$autocomplete-item-amount__color : $color-gray60 !default;

.block-search {
    margin-bottom: 0;

    .block {
        &-title {
            display: none;
        }
    }

    .block-content {
        margin-bottom: 0;

        .label .close {
            display: none;
        }
    }

    input {
        &:hover,
        &:focus {
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
        }
    }

    .nested {
        display: none;
    }
}

.search-autocomplete {
    @extend .abs-add-box-sizing;
    display: none;
    margin-top: -15px;
    overflow: hidden;
    position: absolute;
    z-index: 3;

    ul {
        @include lib-list-reset-styles();

        li {
            @include lib-css(border-top, $autocomplete-item__border);
            cursor: pointer;
            margin: 0;
            padding: $indent__xs $indent__xl $indent__xs $indent__s;
            position: relative;
            text-align: left;
            white-space: normal;

            &:not(:empty) {
                border-top: 0;
                @include lib-css(border, $autocomplete__border);
                @include lib-css(background, $autocomplete__background-color);
            }

            &:first-child {
                border-top: none;
            }

            &:hover,
            &.selected {
                @include lib-css(background, $autocomplete-item__hover__color);
            }

            .amount {
                @include lib-css(color, $autocomplete-item-amount__color);
                position: absolute;
                right: 7px;
                top: $indent__xs;
            }
        }
    }
}

.form.search.advanced {
    .fields.range {
        .field {
            &:first-child {
                position: relative;

                .control {
                    padding-right: 25px;

                    &:after {
                        content: ' \2013 ';
                        display: inline-block;
                        position: absolute;
                        right: 0;
                        text-align: center;
                        top: 6px;
                        width: 25px;
                    }
                }
            }

            &:last-child {
                position: relative;

                div.mage-error[generated] {
                    left: 0;
                    position: absolute;
                    top: 32px;
                }
            }

            &.with-addon {
                .control {
                    padding-right: 45px;
                }
            }
        }
    }

    .group.price {
        .addon {
            .addafter {
                background: none;
                border: 0;
                padding-top: 6px;
                position: absolute;
                right: 0;
                top: 0;
            }
        }
    }
}

.search.summary {
    margin-bottom: $indent__s;
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .page-header:not(.fixed) {
        .block-search {
            display: inline-block;
            margin-left: auto;
            position: relative;
            width: 100%;
            max-width: 350px;

            .block-content {
                margin-bottom: 0;
            }
            .label {
                display: none;
            }
            .control {
                border-top: 0;
                margin: 0;
                padding: 0;
            }
            input {
                @include lib-input-placeholder($form-element-input-placeholder__color);
                margin: 0;
                padding-right: $indent__xxl;
                position: static;
            }
            .action.search {
                display: inline-block;
                @include lib-button-reset();
                padding: $indent__xs 0;
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
                z-index: 1;
                min-width: unset;

                span {
                    @include lib-visually-hidden();
                }

                &:focus {
                    &:before {
                        @include lib-css(color, $color-gray20);
                    }
                }
            }
        }
    }

    .page-header.fixed {
        .block-search {
            display: block;
            position: initial;
            .field.search {
                text-align: right;
            }
            .label {
                display: inline-block;
                cursor: pointer;

                span {
                    @include lib-visually-hidden();
                }

                &.active {
                    + .control {
                        padding: $indent__s $layout__width-xs-indent;
                        max-height: 64px;
                        transition: max-height 0.5s, overflow 0.5s 0.5s;
                        overflow: hidden;

                    }
                    .search {
                        display: none;
                    }
                    .close {
                        display: inline-block;
                    }
                }
            }
            .control {
                padding: 0 $layout__width-xs-indent;
                background-color: $color-sna-white;
                box-shadow: $box-shadow-sna1;
                position: absolute;
                right: 0;
                left: 0;
                top: 100%;
                max-height: 0;
                transition: max-height 0.5s, overflow 0s;
                overflow: hidden;
                z-index: 15;
            }
            .form.active .actions {
                overflow: hidden;
                max-height: 64px;
                transition: max-height 0.6s, overflow 0.2s 0.2s;
            }
            .actions {
                position: absolute;
                right: 0;
                top: 100%;
                z-index: 15;
                max-height: 0;
                transition: max-height 0.2s, overflow 0s;
                overflow: hidden;

                .action.search {
                    transform: none;
                    @include lib-custom-button-reset();
                    padding: $indent__s $layout__width-xs-indent;
                    margin: $indent__s;
                    position: relative;
                    height: $form-element-input__height;

                    > span {
                        display: none;
                    }
                }
            }
        }
    }
}


@include max-screen($screen__xs-2) {
    .page-header:not(.fixed) {
        .block-search {
            display: block;
            position: initial;
            .field.search {
                text-align: right;
            }
            .label {
                display: inline-block;
                cursor: pointer;

                span {
                    @include lib-visually-hidden();
                }

                &.active {
                    + .control {
                        padding: $indent__s $layout__width-xs-indent;
                        max-height: 64px;
                        transition: max-height 0.5s, overflow 0.5s 0.5s;
                        overflow: hidden;

                    }
                    .search {
                        display: none;
                    }
                    .close {
                        display: inline-block;
                    }
                }
            }
            .control {
                padding: 0 $layout__width-xs-indent;
                background-color: $color-sna-white;
                box-shadow: $box-shadow-sna1;
                position: absolute;
                right: 0;
                left: 0;
                top: 100%;
                max-height: 0;
                transition: max-height 0.5s, overflow 0s;
                overflow: hidden;
                z-index: 15;
            }
            .form.active .actions {
                overflow: hidden;
                max-height: 64px;
                transition: max-height 0.6s, overflow 0.2s 0.2s;
            }
            .actions {
                position: absolute;
                right: 0;
                top: 100%;
                z-index: 15;
                max-height: 0;
                transition: max-height 0.2s, overflow 0s;
                overflow: hidden;

                .action.search {
                    transform: none;
                    @include lib-custom-button-reset();
                    padding: $indent__s $layout__width-xs-indent;
                    margin: $indent__s;
                    position: relative;
                    height: $form-element-input__height;

                    > span {
                        display: none;
                    }
                }
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .block-search {
        display: inline-block;
        padding-left: 15px;
        position: relative;
        width: 100%;
        max-width: 300px;
        margin-right: 0;

        z-index: 4;

        .label {
            @extend .abs-visually-hidden-desktop;
        }

        .control {
            border-top: 0;
            margin: 0;
            padding: 0;
        }

        .nested {
            display: block;
            padding-top: 5px;
            position: absolute;
        }

        input {
            @include lib-input-placeholder($form-element-input-placeholder__color);
            margin: 0;
            padding-right: $indent__xxl;
            position: static;
        }

        .action.search {
            display: inline-block;
            @include lib-button-reset();
            padding: $indent__xs 0;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
            min-width: unset;

            span {
                @include lib-visually-hidden();
            }

            &:focus {
                &:before {
                    @include lib-css(color, $color-gray20);
                }
            }
            &:hover {
                color: $color-sna-primario1;
            }
        }
    }

    .search-autocomplete {
        margin-top: 0;
    }
}

@include min-screen($screen__m-2) {
    .block-search {
        max-width: 400px;
    }
}

@include min-screen($screen__l) {
    .block-search {
        max-width: 450px;
    }
}

@include min-screen($screen__l-3) {
    .block-search {
        max-width: 550px;
    }
}

@include min-screen($screen__xl) {
    .block-search {
        max-width: 650px;
    }
}