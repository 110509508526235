@import "sidebar/sidebar";

.blog {

    &__post-title {
        margin-top: 0;
        text-align: left;
        a {
            @include lib-link-secondary(
                $_link-color: $color-sna-primario2,
                $_link-color-visited: $color-sna-primario2,
                $_link-color-hover: $color-sna-primario2,
                $_link-color-active: $color-sna-primario2
            );
            @include lib-typography-light-heading();
        }
    }
    &__post-list {
        @extend .abs-reset-list;
    }
    &__post-item {
        border-top: 1px solid $color-sna-primario4;
        padding: $indent__base 0 $indent__l;

        .action.go {
            margin-top: $indent__l;
        }
    }
    &__post-meta-time {
        display: block;
        text-align: right;
    }
    &__post-featured-image {
        float: left;
        max-width: 40%;
        padding: 0 $indent__base $indent__s 0;
    }
    &__post-footer {
        clear: both;
    }
    &__post-content {
        p {
            margin-bottom: 1em;
        }
    }
    &__post-footer-tags {
        display: block;
        margin-top: $indent__base;
        .tag {
            display: inline-block;
            padding: $indent__s;
            background-color: $color-sna-primario4;
            @include lib-link-secondary(
                $_link-font-weight             : $font-weight__heavier,
                $_link-color                   : $color-sna-primario2,
                $_link-color-visited           : $color-sna-primario2,
                $_link-color-hover             : $color-sna-primario2,
                $_link-color-active            : $color-sna-primario2
            );
            @include lib-font-size($font-size__xs);

            &:hover {
                -webkit-box-shadow: $box-shadow-sna1;
                -moz-box-shadow: $box-shadow-sna1;
                box-shadow: $box-shadow-sna1;
            }
        }
    }
    &__post-view-related-posts,
    &__post-view-related-products {
        .block-title {
            @include lib-heading(h4);
        }
        ol {
            @extend .abs-reset-list;
            &.posts .post-name {
                padding: $indent__xs 0;
            }
        }
    }
    .toolbar {
        .limiter {
            float: left;
            clear: both;
            margin-top: $indent__base;
        }
        .pages {
            float: right;
        }
    }
}