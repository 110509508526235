//  Newsletter subscription
.block.newsletter {
    margin-bottom: $indent__xxxxl;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    padding: $indent__xl $indent__base $indent__xxxl;
    
    background-color: #222;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    text-align: center;

    > * {
        width: 100%;
    }

    .title {
        @include lib-css(font-weight, $font-weight__bold);
        font-size: 2.5rem;
        text-transform: uppercase;
        text-align: center;
        
        > strong {
            font-size: inherit;
        }
    }

    .newsletter__cto-text {
        font-size: $font-size__s;
        margin-bottom: $indent__base;
        text-align: center;
    }

    .label {
        @extend .abs-visually-hidden;
    }

    .mktoForm,
    .form {
        max-width: 52rem;
        margin: 0 auto;
    }

    .form {
        display: flex;
        flex-wrap: wrap;

        > * {
            flex-basis: 100%;
        }

        > .field {
            margin-bottom: $indent__base;
            .control input {
                @include lib-form-input-secondary();
            }
        }

        > .terms-conditions {
            display: inline-flex;
            justify-content: center;
            font-size: $font-size__xs;

            input[type="checkbox"] {
                @include lib-form-custom-checkbox-white();
            }

            a {
                color: currentColor;
                text-decoration: underline;
            }
        }
    }

    .action.primary,
    .mktoButton {
        background: transparent;
        color: $color-white;
        border-color: currentColor;

        &:hover {
            background-color: $color-sna-primario3;
            border-color: $color-sna-primario3;
        }
    }

    .content { 
        > *,
        .mktoHtmlText {
            font-size: $font-size__s;
        }

        > form {
            font-size: inherit;
        }

        > .mktoForm {
            margin-top: $indent__l;
        }
    }

}

@include min-screen($screen__l) {
    .block.newsletter {
        .mktoForm {
            max-width: 95rem;
        }
        
        .form {
            max-width: 79rem;
            > * {
                flex-basis: auto;
            }
            > .newsletter {
                flex-grow: 3;
                margin-right: $indent__base;
            }
            > .terms-conditions {
                order: 2;
                flex-basis: 100%;
            }
        }
    }
}
