.message.info {
    @include lib-message-icon-inner(info);
}

.message.error {
    @include lib-message-icon-inner(error);
}

.message.warning {
    @include lib-message-icon-inner(warning);
}

.message.notice {
    @include lib-message-icon-inner(notice);
}

.message.success {
    @include lib-message-icon-inner(success);
}

body.catalog-product-view {
    .page.messages {
        background-color: $color-sna-primario4;

        .message {
            margin: 0;
        }
    }
}
