.table.grouped {
    @include lib-table-bordered(
        $_table_type: horizontal
    );

    > thead > tr > th {
        border-bottom: 0;
    }

    .price-box {
        .price {
            font-size: $font-size__base;
        }
    }

    .control {
        .qty {
            @extend .abs-input-qty;
        }
    }

    .stock.unavailable {
        font-weight: normal;
        margin: 0;
        text-transform: none;
        white-space: nowrap;
    }

    .row-tier-price {
        td {
            border-top: 0;
        }

        .prices-tier {
            margin: 0 -10px 10px;
        }
    }
}
//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    #super-product-table {

        .table-product-item-name {
            display: inline-block;
            margin: 0;
        }

        .col.item.name {
            background-color: $color-sna-primario2;
            width: 100%;
            display: block;
            @include lib-css(font-weight, $font-weight__bold);
        }

        .col.item.name:before,
        .table-product-item-name {
            @include lib-font-size($font-size__s);
            color: $color-sna-white;
        }

        tbody > tr {
            background-color: $color-sna-primario4;
            border: 1px solid $color-sna-primario2;
            margin-bottom: $indent__s;
            td {
                padding: 8px 6px;
                &:last-child {
                    padding-bottom: 12px;
                }
            }

            .col {
                display: inline-block;
                width: 49%;
                padding: $indent__base;

                &.no-display {
                    @extend .no-display;
                }
            }
        }
    }

    .table-scroll .clone {
        display: none;
    }

    .table-wrapper.grouped .table:not(.cart):not(.totals):not(.table-comparison) > tbody > tr td:before,
    .table-wrapper.grouped .table:not(.cart):not(.totals):not(.table-comparison) > tbody > tr th:before {
        word-break: break-word;
        margin-bottom: $indent__s;
    }

}

//
//  Tablet + Desktop
//  _____________________________________________

@include min-screen($screen__s) {

    .table-wrapper.grouped {
        margin-bottom: 0;
    }

    .table-scroll {
        margin-bottom: 16px;
    }

    #super-product-table {
        @include lib-font-size($font-size__s);
        text-align: center;

        thead {
            background-color: $color-sna-primario3;
            color: $color-sna-primario2;

            th {
                border: none;
                text-align: center;
                padding: $indent__base $indent__s;
                vertical-align: middle;
                img {
                    //width: 32px;
                }
            }
        }

        tbody > tr > td {
            background-color: white;
            vertical-align: middle;
        }

        tbody > tr:nth-child(2n+1) {
            background-color: $color-sna-primario4;
            td {
                background-color: $color-sna-primario4;
            }
        }

        tbody td span {
            font-weight: 600;
        }
    }

    .table-scroll.initialized {
        position: relative;
        margin: auto;
        overflow: hidden;
        border: 1px solid $color-sna-primario2;


        .table-wrapper {
            width: 100%;
            overflow: auto;

            /* IE Scrollbar style */
            scrollbar-face-color: $color-sna-secundario3;
            scrollbar-arrow-color: $color-sna-secundario3;

            &::-webkit-scrollbar {
                height: 8px;
                background-color: transparent;
            }

            &::-webkit-scrollbar-thumb {
                background-color: $color-sna-secundario3;
                opacity: 0.5;
                border-radius: $indent__xs;
            }

            table {
                width: 100%;
                margin: auto;
                border-collapse: separate;
                border-spacing: 0;
                tbody td.item,
                thead th.item {
                    visibility: hidden;
                }
            }
        }

        .clone {
            position: absolute;
            top: 0;
            left: 0;
            pointer-events: none;

            th,
            td {
                visibility: hidden;
                border-color: transparent;
            }

            tbody td.item,
            thead th.item {
                visibility: visible;
                position: relative;
                pointer-events: all;
                &:after {
                    box-shadow: 18px 0 4px -16px inset rgba(0, 0, 0, 0.33);
                    content: " ";
                    height: 100%;
                    position: absolute;
                    top: 0;
                    right: -15px;
                    width: 15px;
                }
            }
        }
    }
}
