.img-logo-snaeurope {
    max-width: 14em;
}

.img-logo-snapon {
    max-width: 14em;
}

.img-logo-award {
    height: 56px;
}