.betms-config-steps {
    @include lib-css(font-weight, $font-weight__hairline);
    list-style-type: disc;
    text-transform: uppercase;
}

.betms-foam-sizes {
    @extend .abs-reset-list;

    > li {
        text-align: center;
        margin-top: $landing-section-padding-top;

        > * {
            display: inline-block;
            margin: 1em auto;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

    }

    @include min-screen($screen__l) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto;
        column-gap: $indent__base;
        margin-top: $landing-section-padding-top;

        > li { margin-top: 0; }
        > :nth-child(1) { grid-column: 1; }
        > :nth-child(2) { grid-column: 2; }
        > :nth-child(3) { grid-column: 3; }
    }
}

.hub-drawer-config {
    @extend .abs-reset-list;
    margin-top: -$indent__l;
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(300px, 1fr) );
    justify-items: center;
    align-items: baseline;

    > li {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        flex-grow: 1;
        flex-shrink: 1;
        margin-left: 2%;
        margin-right: 2%;
        margin-top: $indent__l;
    }

    &.has-2-cols {
        column-count: auto;

        > li {
            flex-basis: 46%;
        }
    }

    &.has-3-cols > li {
        flex-basis: 29%;
    }

    &.has-4-cols > li {
        flex-basis: 21%;
    }

    figure {
        > img {
            display: block;
            max-height: 170px;
            margin: 0 auto;

            @include min-screen($screen__l) {
                max-height: 225px;
            }
        }

        > figcaption {
            @include lib-css(margin-top, $indent__s);
            @include lib-css(font-weight, $font-weight__bold);
            text-transform: uppercase;
            text-align: center;

            @include max-screen($screen__xs) {
                @include lib-font-size($font-size__xs);
            }
        }
    }
}

.hub-colour-config {
    @extend .abs-reset-list;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: -$indent__base;
    margin-bottom: 2.1em;

    @include max-screen($screen__xs) {
        @include lib-font-size($font-size__xs);
    }

    > li {
        flex-basis: 20%;
        margin-top: $indent__base;
        padding: 1em;
    }

    @include min-screen($screen__l) {
        > li {
            flex-basis: 22%;
            padding: .7em;
        }
    }

    &__colour {
        @include shape-circle-with-centered-text();
        color: white;
        @include lib-css(font-weight, $font-weight__bold);
        text-transform: uppercase;
    }

    .colour-ral2009 {
        background-color: $color-sna-primario1;
    }
    .colour-ral3001 {
        background-color: #b71f25;
    }
    .colour-ral5002 {
        background-color: #1c388d;
    }
    .colour-ral9022 {
        background-color: $color-sna-primario3;
    }
    .colour-ral9005 {
        background-color: #000;
    }

}

.hub-classic-specials {
    margin-top: 4em;

    section {
        margin-bottom: 4em;
    }

    .heading {
        font-size: 1em;
        margin-bottom: 2em;
        text-align: center;
    }

    @include min-screen($screen__l) {
        display: flex;
        flex-wrap: wrap;

        > * {
            flex-basis: 50%;
        }
    }
}

.hub-available-boards {
    @extend .abs-reset-list;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    > li {
        flex-basis: 46%;
        margin-bottom: 2em;
    }

    @include min-screen($screen__l) {
        > li {
            flex-basis: 21%;
        }
    }

    figure img {
        margin-bottom: 1em;
    }

    /** icons */
    + div {
        text-align: center
    }
}
