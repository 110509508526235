//
//  Theme variables
//  _____________________________________________

//  Messages
$message-global-note__background: $color-yellow-light2 !default;
$message-global-note__border-color: $color-yellow-light3 !default;
$message-global-note__color: $text__color !default;

$message-global-note-link__color: $link__color !default;
$message-global-note-link__color-hover: $link__hover__color !default;
$message-global-note-link__color-active: $link__active__color !default;

$message-global-caution__background: $color-red9 !default;
$message-global-caution__border-color: none !default;
$message-global-caution__color: $color-white !default;

$message-global-caution-link__color: $link__color !default;
$message-global-caution-link__color-hover: $link__hover__color !default;
$message-global-caution-link__color-active: $link__active__color !default;

$button__shadow: '' !default;

//  Footer
$footer__background-color: false !default;

body {
    @include lib-css(background-color, $page__background-color);
}

.page-main {
    > .page-title-wrapper {
        .page-title + .action {
            margin-top: $indent__l;
        }
    }
}

//
//  In-page navigation - a11y
//  ---------------------------------------------

.action.skip {
    &:not(:focus) {
        @extend .abs-visually-hidden;
    }

    &:focus {
        @include lib-css(background, $color-gray94);
        @include lib-css(padding, $indent__s);
        box-sizing: border-box;
        left: 0;
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;
        z-index: 15;
    }
}

.action-skip-wrapper {
    height: 0;
    position: relative;
}

//
//  SVG Icons
//  ---------------------------------------------

svg {
    display: inline-block;
    width: 2.4rem;
    height: 2.4rem;
    vertical-align: middle;
    fill: currentColor;
}

svg.icons {
    display: none;
}

svg .st0,
symbol .st0,
svg path {
    fill: currentColor;
}

svg.arrow {
    height: 1.2rem;
    width: 1.2rem;
}

//
//  Global notice
//  ---------------------------------------------

.message.global {
    p {
        margin: 0;
    }

    &.noscript,
    &.cookie {
        @include lib-message($_message-type: global-note);
        margin: 0;
    }

    &.cookie {
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        z-index: 3;
        -webkit-box-shadow: $box-shadow-sna2;
        -moz-box-shadow: $box-shadow-sna2;
        box-shadow: $box-shadow-sna2;
        background-color: $color-sna-primario2;

        .content {
            margin: $indent__m auto $indent__base;
            color: $color-sna-white;
            text-align: center;
        }

        .actions {
            margin-top: $indent__m;
            .allow {
                @include lib-button-primary-over-dark-bg();
            }
        }
    }

    &.demo {
        @include lib-message($_message-type: global-caution);
        margin-bottom: 0;
        text-align: center;
    }
}

//
//  Back to top link
//  ---------------------------------------------

#top-to-back,
#back-to-top {
    border-radius: 0;
    width: $indent__xxl;
    height: $indent__xxl;
    color: $color-sna-black;
    background-color: transparent;
    box-shadow: none;
    z-index: 2;

    &::before {
        position: absolute;
        content: '';
        display: block;
        width: $indent__xl;
        height: $indent__xl;
        margin: 50% 0 0 50%;
        transform: translate(-50%, -50%);
        background-color: $color-sna-white;
        @include lib-css(box-shadow, $box-shadow-sna1);
    }

    > svg {
        width: 24px;
        height: 24px;
        margin: 50% 0 0 50%;
        transform: translate(-50%, -50%);
    }
}

//
//  Component: Social Networks
//  ---------------------------------------------

.social-networks {
    a {
        display: inline-block;
        img,
        svg {
            height: 21px;
        }
        &:not(:last-child) {
            margin-right: $indent__s;
        }

        &:hover {
            color: $color-sna-secundario1;
        }
    }
}

@include max-screen($screen__m) {
    .social-networks {
        margin-top: $indent__xl;
        a {
            img,
            svg {
                width: 4.2rem;
                height: 3.2rem;
            }
        }
    }
}

//
//  Footer
//  ---------------------------------------------

.page-footer {
    @include lib-css(background-color, $footer__background-color);
    margin-top: auto;
}

.footer {
    &.content {
        ul {
            @extend .abs-reset-list;
        }

        .links {
            > li {
                margin: 0 0 8px;
            }
        }
    }
}

.page-header,
.page-footer {
    .switcher {
        margin-right: 10px;

        .options {
            @include lib-dropdown(
                $_dropdown-actions-padding: 0,
                $_dropdown-list-item-padding: 0,
                $_dropdown-toggle-icon-content: $icon-down,
                $_dropdown-toggle-active-icon-content: $icon-up,
                $_icon-font-text-hide: true,
                $_icon-font-size: 22px,
                $_icon-font-line-height: 22px,
                $_dropdown-list-min-width: 160px
            );

            ul.dropdown {
                a {
                    display: block;
                    padding: 8px;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }

        li {
            font-size: $font-size__s;
            margin: 0;
        }

        .label {
            @extend .abs-visually-hidden;
        }

        strong {
            font-weight: $font-weight__regular;
        }
    }
}

//
//  Footer: Download catalogue and Contact
//  ---------------------------------------------

.footer-two-blocks-row {
    padding-left: $indent__footer-lateral;
    padding-right: $indent__footer-lateral;

    > * {
        flex-grow: 1;
    }
}

@include min-screen($screen__l) {
    .footer-two-blocks-row {
        display: flex;
        align-items: flex-start;
        flex-wrap: nowrap;
        padding-left: $indent__footer-lateral / 2;
        padding-right: $indent__footer-lateral / 2;

        > * {
            margin-left: $indent__footer-lateral / 2;
            margin-right: $indent__footer-lateral / 2;
            flex-grow: 1;
        }

    }
}

.footer {
    .contact,
    .download-catalogue {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        font-size: $font-size__s;

        &__title {
            @include lib-font-size($font-size__xxl);
            @include lib-css(font-weight, $font-weight__bold);
        }
    }

    .download-catalogue {
        align-items: center;
        margin-top: $indent__xxxxl;
        margin-bottom: $indent__xxxxl;

        &__title {
            text-align: left;
            text-transform: inherit;
        }

        &__info {
            flex-grow: 1;

            > :last-child {
                text-align: center;
                margin-top: $indent__l;
            }
        }

        &__img {
            order: -1;

            img {
                display: block;
            }
        }
    }

    .contact {
        flex-wrap: wrap;
        padding-bottom: $indent__xl;

        &__title {
            margin-bottom: $indent__xxl;
            text-align: center;
            text-transform: uppercase;
        }

        &__text {
            margin-bottom: $indent__xxl;

            > :last-child {
                margin-top: $indent__l;
            }
        }

        &__list {
            li {
                margin-bottom: $indent__m !important;
                white-space: nowrap;
            }

            li:last-child {
                margin-bottom: 0 !important;
            }

            svg {
                margin-right: $indent__base;
            }
        }
    }
}

@include min-screen($screen__s) {
    .footer {
        .contact,
        .download-catalogue {
            flex-direction: row;

            & > * {
                flex-basis: 35%;
            }
        }

        .download-catalogue {
            &__img {
                margin-right: $indent__xxl;
            }

            &__info > :last-child {
                text-align: left;
            }
        }

        .contact {
            &__title {
                flex-basis: 100%;
            }
        }
    }
}

@include min-screen($screen__l) {
    .footer .contact {
        margin-top: $indent__xxxxl;
    }
}

//
//  Footer: Fotter nav links
//  ---------------------------------------------
.footer-nav-links {
    padding-left: $indent__footer-lateral;
    padding-right: $indent__footer-lateral;
    margin-bottom: $indent__xxl;

    font-size: $font-size__s;
    text-align: center;

    a {
        color: currentColor;
    }

    .btn-expandable {
        margin-bottom: $indent__m;
    }

    li {
        margin-bottom: $indent__m !important;
    }

    .highlighted {
        font-weight: $font-weight__bold;
    }

    .action.secondary {
        margin-bottom: $indent__m;
    }
}

@include min-screen($screen__l) {
    .footer-nav-links {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        text-align: left;

        > :not(:last-child) {
            flex-grow: 1;
        }

        .btn-expandable {
            margin-bottom: $indent__s;

            & + ul {
                padding-left: $indent__base;
            }
        }

        li {
            margin-bottom: $indent__s !important;
        }
    }
}

//
//  Footer: Nav legal links
//  ---------------------------------------------

.footer-nav-legal-links {
    @include lib-font-size($font-size__s);
    margin-bottom: $indent__xl;
    text-align: center;

    .btn-expandable {
        text-transform: none;
        margin-bottom: $indent__m;
    }

    ul {
        display: flex;
        justify-content: center;
        flex-direction: column;
        a {
            color: currentColor;
            display: inline-block;
            margin-right: $indent__base;
            margin-bottom: $indent__m;
        }
    }
}

@include min-screen($screen__m) {
    .footer-nav-legal-links {
        ul {
            flex-direction: row;
            a {
                margin-bottom: 0;
            }
        }
    }
}

@include min-screen($screen__l) {
    .footer-nav-legal-links {
        margin-bottom: $indent__base;
    }
}

//
//  Footer: copyright
//  ---------------------------------------------

.footer .copyright-snaeurope {
    font-size: $font-size__s;
    text-align: center;

    > * {
        display: block;
    }

    .copyright {
        @include lib-css(color, $color-sna-primario3);
    }
}

@include min-screen($screen__m) {
    .footer .copyright-snaeurope {
        > * {
            display: inline-block;
        }

        .copyright::after {
            content: " | ";
            display: inline;
        }
    }
}

//
//  Widgets
//  ---------------------------------------------

.sidebar {
    .widget.block:not(:last-child),
    .widget:not(:last-child) {
        @extend .abs-margin-for-blocks-and-widgets;
    }
}

.widget {
    clear: both;

    .block-title {
        @extend .abs-block-title;
    }
}

.page-header,
.page-footer {
    .widget.block {
        @include lib-css(margin, $indent__base 0);
    }
}

.no-display {
    @extend .abs-no-display;
}

//
//  Calendar
//  ---------------------------------------------

.ui-datepicker {
    td {
        padding: 0;
    }
}


//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

    .page-wrapper {
        @include lib-vendor-prefix-display(flex);
        @include lib-vendor-prefix-flex-direction(column);

        > .breadcrumbs,
        > .top-container,
        > .widget {
            box-sizing: border-box;
            width: 100%;
        }
    }

    .header.panel {
        @extend .abs-add-clearfix-desktop;
        padding-bottom: $indent__s;
        padding-top: $indent__s;
    }

    .page-main {
        > .page-title-wrapper {
            .page-title {
                display: inline-block;
            }

            .page-title + .action {
                float: right;
                margin-top: $indent__base;
            }
        }
    }

    .footer {
        &.content {
            .links {
                display: inline-block;
                margin-bottom: 20px;
                padding: 0 50px 0 0;
                vertical-align: top;
            }

            .switcher.store {
                display: inline-block;
                padding-right: 50px;
                vertical-align: top;
            }
        }
    }
}

@import 'components/header';
@import 'components/top-header';

@import 'imgs/logos';

@import 'layouts/text-only';

@import 'pages/home';
@import 'pages/after-sales-services';
@import 'pages/awards';
@import 'pages/cordless';
@import 'pages/ergo';
@import 'pages/responsibility-sustainability';
@import 'pages/sna-europe';
@import 'pages/sponsorship';
@import 'pages/storage-hub';
@import 'pages/where-to-buy';
