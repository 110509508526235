$layout-column-main__sidebar-offset       : $indent__m !default;
$layout-column__additional-sidebar-offset : $layout-column-main__sidebar-offset !default;

.columns {
    @include lib-layout-columns();

    > .category-image,
    > .view-category-description,
    .page-title-wrapper,
    .column.main {
        @extend .abs-add-box-sizing;
        @include lib-vendor-prefix-flex-grow(0);
        @include lib-vendor-prefix-flex-shrink(1);
        @include lib-vendor-prefix-order(1);
        width: 100%;
    }

    .column.main {
        @include lib-css(padding-bottom, $indent__xl);
    }

    .sidebar-main {
        @extend .abs-add-box-sizing;
        @include lib-vendor-prefix-flex-grow(0);
        @include lib-vendor-prefix-flex-shrink(1);
        @include lib-vendor-prefix-order(0);
    }

    .sidebar-additional {
        @extend .abs-add-box-sizing;
        @include lib-vendor-prefix-flex-grow(0);
        @include lib-vendor-prefix-flex-shrink(1);
        @include lib-vendor-prefix-order(0);
    }
}

.page-footer {
    padding-bottom: $indent__xl;
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .menu,
    .breadcrumbs,
    .page-main,
    .page-wrapper > .widget,
    .page-wrapper > .page-bottom,
    .block.category.event,
    .top-container {
        padding-left: $layout-indent-main__s;
        padding-right: $layout-indent-main__s;
    }

    .columns .sidebar-main {
        @include lib-vendor-prefix-order(1);
        width: 100%;
    }
    .columns .column.main {
        @include lib-vendor-prefix-order(2);
    }

    .page-main {
        .account &,
        .cms-privacy-policy & {
            padding-top: 41px;
            position: relative;
        }
    }


    .page-header .header.panel,
    .panel.wrapper .country.selector.panel .geoip-language-suggestion,
    .header.content {
        padding-left: $layout-indent-header__s;
        padding-right: $layout-indent-header__s;
    }

    body.catalog-product-view {
        .page.messages,
        .column.main {
            margin-left: -$layout-indent-main__s;
            margin-right: -$layout-indent-main__s;
            width: calc(100% + 32px);

            & > *,
            .product.info.detailed .data.item {
                padding-left: $layout-indent-main__s;
                padding-right: $layout-indent-main__s;
            }
            .btn-special-container {
                margin-left: 0;
                margin-right: 0;
            }
            & > .product.info.detailed {
                padding-left: 0;
                padding-right: 0;
            }
            .product-ergo-image {
                right: $layout-indent-main__s;
            }
        }
    }
}

//
//  > Tablet
//  _____________________________________________

@include min-screen($screen__m) {
    .breadcrumbs,
    .menu,
    .menu .mn-submenu,
    .page-wrapper > .widget,
    .page-wrapper > .page-bottom,
    .block.category.event,
    .top-container,
    .message.global.cookie .content,
    .page-main {
        box-sizing: border-box;
        margin-left: auto;
        margin-right: auto;
        max-width: $layout__max-width;
        padding-left: $layout-indent-main__m;
        padding-right: $layout-indent-main__m;
        width: auto;
    }

    .panel.wrapper .header.panel,
    .panel.wrapper .country.selector.panel .geoip-language-suggestion,
    .header.content {
        box-sizing: border-box;
        margin-left: auto;
        margin-right: auto;
        max-width: $layout__max-width;
        padding-left: $layout-indent-header__m;
        padding-right: $layout-indent-header__m;
        width: auto;
    }

    .page-footer {
        box-sizing: border-box;
        margin-left: auto;
        margin-right: auto;
        max-width: $layout__max-width;
        width: 100%;
    }

    .page-main,
    .page-main-full-width {
        width: 100%;

        // These flex properties should be combined to `flex: 1 0 auto`
        // sass-lint:disable no-css-comments
        /* stylelint-disable declaration-block-no-redundant-longhand-properties */
        @include lib-vendor-prefix-flex-grow(1);
        @include lib-vendor-prefix-flex-shrink(0);
        @include lib-vendor-prefix-flex-basis(auto);
        /* stylelint-enable declaration-block-no-redundant-longhand-properties */
        // sass-lint:enable  no-css-comments

        .ie9 & {
            width: auto;
        }
    }

    .columns {
        display: block;
    }

    body[class*="columns"] {
        &.blog .page-title-wrapper,
        &.catalog-category-view .columns > .page-title-wrapper,
        .columns > .view-category-short-description,
        .columns > .category-image,
        .columns > .view-category-description,
        .column.main {
            max-width: calc(100% - 230px);
            float: right;
        }
    }

    .sidebar-main {
        @extend .abs-add-box-sizing-desktop;
        max-width: 230px;
        width: 100%;
        padding-right: $layout-column-main__sidebar-offset;
        padding-top: $indent__base;
    }

    .page-layout-2columns-right {
        .sidebar-main,
        .sidebar-additional {
            padding-left: $layout-column-main__sidebar-offset;
            padding-right: 0;
            order: 2;
        }
    }

    .sidebar-additional {
        @extend .abs-add-box-sizing-desktop;
        max-width: 230px;
        width: 100%;
        padding-left: $layout-column__additional-sidebar-offset;
    }

    .page-layout-2columns-left {
        .sidebar-additional {
            clear: left;
            float: left;
            padding-left: 0;
            padding-right: $layout-column__additional-sidebar-offset;
        }
    }

    .panel.header {
        padding: 10px 20px;
    }

    body.catalog-product-view {
        .page.messages,
        .column.main {
            margin-left: -$layout-indent-main__m;
            margin-right: -$layout-indent-main__m;
            width: calc(100% + 80px);

            & > *,
            .product.info.detailed .data.item  {
                padding-left: $layout-indent-main__m;
                padding-right: $layout-indent-main__m;
            }
            .btn-special-container {
                margin-left: $layout-indent-main__m;
                margin-right: $layout-indent-main__m;
            }
            & > .product.info.detailed {
                padding-left: 0;
                padding-right: 0;
            }
            .product-ergo-image {
                right: $layout-indent-main__m;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__l) {
    .page-header .header.panel,
    .header.content {
        padding-left: $layout-indent-header__l;
        padding-right: $layout-indent-header__l;
    }
}

@include min-screen($screen__xl) {
    .breadcrumbs,
    .page-wrapper > .widget,
    .page-wrapper > .page-bottom,
    .block.category.event,
    .top-container,
    .message.global.cookie .content,
    .page-main {
        padding-left: $layout-indent-main__xl;
        padding-right: $layout-indent-main__xl;
    }

    .page-header .header.panel,
    .header.content {
        padding-left: $layout-indent-header__xl;
        padding-right: $layout-indent-header__xl;
    }

    .sidebar-main,
    .sidebar-additional {
        flex: 0 1 260px;
    }

    body[class*="columns"] {
        &.blog .page-title-wrapper,
        &.catalog-category-view .columns > .page-title-wrapper,
        .columns > .view-category-short-description,
        .columns > .category-image,
        .columns > .view-category-description,
        .column.main {
            max-width: calc(100% - 260px);
        }
    }

    body.catalog-product-view {
        .page.messages,
        .column.main {
            margin-left: -$layout-indent-main__xl;
            margin-right: -$layout-indent-main__xl;
            width: calc(100% + 96px);

            & > *,
            .product.info.detailed .data.item  {
                padding-left: $layout-indent-main__xl;
                padding-right: $layout-indent-main__xl;
            }
            .btn-special-container {
                margin-left: $layout-indent-main__xl;
                margin-right: $layout-indent-main__xl;
            }
            & > .product.info.detailed {
                padding-left: 0;
                padding-right: 0;
            }
            .product-ergo-image {
                right: $layout-indent-main__xl;
            }
        }
    }
}

