.range-preview {
    margin-top: 7%;
    margin-bottom: 9%;

    &__main-img {
        margin-bottom: 2%;
    }

    &__thumb-list {
        @extend .abs-reset-list;
        @include lib-css(margin-bottom, $indent__xl);

        li {
            @include lib-css(margin-right, $indent__base);
            
            &:hover {
                box-shadow: none;
            }
        }
    }

    &__thumb {
        max-width: 259px;
        margin: 0 auto;

        img {
            @include lib-css(margin-bottom, $indent__s);
        }

        figcaption {
            @include lib-font-size($font-size__s);
            text-align: left;
        }
    }

    &__actions {
        text-align: center;
        margin: 0;
    }

    .slick-slide {
        text-align: center;
        padding: 12px;
    }

    .slick-prev {
        left: 1em;
    }

    .slick-next {
        right: 1em;
    }

}
