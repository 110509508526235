//
//  Layout
//  _____________________________________________

//  Page Width mixin
@mixin lib-layout-width(
    $_layout__min-width: $layout__min-width,
    $_layout__max-width: $layout__max-width,
    $_layout__indent   : $layout-indent__width
)  {
    @if $responsive != true {
        @include _lib-layout-width($_layout__min-width, $_layout__max-width);
        @include lib-css(padding-left, $layout-indent__width);
        @include lib-css(padding-right, $layout-indent__width);
        margin: 0 auto;
    }
}

@mixin _lib-layout-width(
    $_layout__min-width: $layout__min-width,
    $_layout__max-width: $layout__max-width
) {
    @if $_layout__min-width == $_layout__max-width {
        @include lib-css(width, $_layout__min-width);
    }
    @else {
        @include lib-css(max-width, $_layout__max-width);
        @include lib-css(min-width, $_layout__min-width);
        width: auto;
    }
}

@mixin lib-layout-columns() {
    @if $use-flex == true {
        @include lib-vendor-prefix-display(flex);
        @include lib-vendor-prefix-flex-wrap(wrap);
        box-sizing: border-box;
    }
    &:after {
        clear: both;
        content: ' ';
        display: block;
        height: 0;
        overflow: hidden;
        visibility: hidden;
    }
}

@mixin lib-layout-columns__main() {
    .#{$layout-class-1column} & {
        @include lib-layout-column(1, 2, 100%);
    }
    .#{$layout-class-3columns} & {
        @include lib-layout-column(3, 2, $layout-column-main__width-3);
    }
    .#{$layout-class-2columns__left} & {
        @include lib-layout-column(2, 2, $layout-column-main__width-2-left);
    }
    .#{$layout-class-2columns__right} & {
        @include lib-layout-column(2, 1, $layout-column-main__width-2-right);
    }
}

@mixin lib-layout-columns__left() {
    .#{$layout-class-3columns} & {
        @include lib-layout-column(3, 1, $layout-column__left-width);
    }
    .#{$layout-class-2columns__left} & {
        @include lib-layout-column(2, 1, $layout-column__left-width);
    }
    .#{$layout-class-2columns__right} & {
        @include lib-layout-column(2, 1, $layout-column__left-width);
    }
}

@mixin lib-layout-columns__right() {
    .#{$layout-class-3columns} & {
        @include lib-layout-column(3, 3, $layout-column__right-width);
    }
    .#{$layout-class-2columns__left} & {
        @include lib-layout-column(2, 2, $layout-column__right-width);
    }
    .#{$layout-class-2columns__right} & {
        @include lib-layout-column(2, 2, $layout-column__right-width);
    }
}

@mixin _lib-column-width($_width, $_check, $_index) {
    @if $_check == false and $_index == 2 {
        @include lib-column-width($_width);
    }
    @else if $_check == true or $_width == 'auto' {
        @include lib-css(width, $_width);
        $_index: 1;
    }
}

@mixin _lib-set-column-width($_index, $_width) {
    @if $_index > 0 {
        $_units    : 'px', '%', 'em', 'rem', 'pt';
        $_unit     : nth($_units, $_index);
        $_checkUnit: false;

        @if unit($_width) == $_unit {
            $_checkUnit: true;
        }
        @include _lib-column-width($_width, $_checkUnit, $_index);
        @include _lib-set-column-width($_index - 1, $_width);

    }
}

@mixin lib-layout-column($_total-columns, $_order, $_width) {
    $_units: 'px', '%', 'em', 'rem', 'pt';
    @include _lib-set-column-width(length($_units), $_width);
    @include lib-flex-degradation($_total-columns, $_order);
    @if $use-flex == true {
        @include lib-vendor-prefix-order($_order);
    }
}

@mixin lib-flex-degradation($_total-columns, $_order) {
    @if $_order == 1 {
        float: left;
    }
    @else if $_order == $_total-columns {
        float: right;
    }
    @else if $_total-columns == 3 and $_order == 2 {
        display: inline-block;
    }
}

@mixin lib-layout-indent(
    $_layout-indent__s__left        :  false,
    $_layout-indent__s__right       :  false,
    $_layout-indent__m__left        :  false,
    $_layout-indent__m__right       :  false,
    $_layout-indent__l__left        :  false,
    $_layout-indent__l__right       :  false,
    $_layout-indent__xl__left       :  false,
    $_layout-indent__xl__right      :  false,
    $_property                      : "padding"
) {
    @include lib-css(#{$_property}-left, $_layout-indent__s__left);
    @include lib-css(#{$_property}-right, $_layout-indent__s__right);
    
    @include min-screen($screen__m) {
        @include lib-css(#{$_property}-left, $_layout-indent__m__left);
        @include lib-css(#{$_property}-right, $_layout-indent__m__right);
    }
    @include min-screen($screen__l) {
        @include lib-css(#{$_property}-left, $_layout-indent__l__left);
        @include lib-css(#{$_property}-right, $_layout-indent__l__right);
    }
    @include min-screen($screen__xl) {
        @include lib-css(#{$_property}-left, $_layout-indent__xl__left);
        @include lib-css(#{$_property}-right, $_layout-indent__xl__right);
    }
}

@mixin _lib-layout-indent-equal(
    $_layout-indent__s              :  false,
    $_layout-indent__m              :  false,
    $_layout-indent__l              :  false,
    $_layout-indent__xl             :  false,
    $_property                      : "padding",
    $_apply-left                    :  true,
    $_apply-right                   :  true
) {
    $_layout-indent__s__left: $_layout-indent__s;
    $_layout-indent__s__right: $_layout-indent__s;
    $_layout-indent__m__left: $_layout-indent__m;
    $_layout-indent__m__right: $_layout-indent__m;
    $_layout-indent__l__left: $_layout-indent__l;
    $_layout-indent__l__right: $_layout-indent__l;
    $_layout-indent__xl__left: $_layout-indent__xl;
    $_layout-indent__xl__right: $_layout-indent__xl;

    @if $_apply-left == false {
        $_layout-indent__s__left: false;
        $_layout-indent__m__left: false;
        $_layout-indent__l__left: false;
        $_layout-indent__xl__left: false;
    }
    @if $_apply-right == false {
        $_layout-indent__s__right: false;
        $_layout-indent__m__right: false;
        $_layout-indent__l__right: false;
        $_layout-indent__xl__right: false;
    }
    @include lib-layout-indent(
        $_layout-indent__s__left,
        $_layout-indent__s__right,
        $_layout-indent__m__left ,
        $_layout-indent__m__right,
        $_layout-indent__l__left ,
        $_layout-indent__l__right,
        $_layout-indent__xl__left ,
        $_layout-indent__xl__right,
        $_property
    )
}

@mixin lib-layout-indent-main(
    $_apply-left                    :  true,
    $_apply-right                   :  true
) {
    @include _lib-layout-indent-equal(
        $_layout-indent__s              :  $layout-indent-main__s,
        $_layout-indent__m              :  $layout-indent-main__m,
        $_layout-indent__l              :  $layout-indent-main__l,
        $_layout-indent__xl             :  $layout-indent-main__xl,
        $_apply-left                    :  $_apply-left,
        $_apply-right                   :  $_apply-right
    );
}

@mixin lib-layout-indent-main-inverse(
    $_apply-left                    :  true,
    $_apply-right                   :  true
) {
    @include _lib-layout-indent-equal(
        $_layout-indent__s              :  -$layout-indent-main__s,
        $_layout-indent__m              :  -$layout-indent-main__m,
        $_layout-indent__l              :  -$layout-indent-main__l,
        $_layout-indent__xl             :  -$layout-indent-main__xl,
        $_property                      :  "margin",
        $_apply-left                    :  $_apply-left,
        $_apply-right                   :  $_apply-right
    );
}

@mixin lib-layout-indent-main-inverse__s(
    $_apply-left                    :  true,
    $_apply-right                   :  true
) {
    @include _lib-layout-indent-equal(
        $_layout-indent__s              :  -$layout-indent-main__s,
        $_property                      :  "margin",
        $_apply-left                    :  $_apply-left,
        $_apply-right                   :  $_apply-right
    );
}

@mixin lib-layout-indent-main-inverse-reset() {
    margin-left: 0;
    margin-right: 0;
}

@mixin lib-layout-two-columns-component() {
    @include lib-layout-columnify(2);
}

@mixin lib-layout-columnify($num_cols : 1) {
    @if $num_cols > 1 {
        @include min-screen($screen__l) {
            column-count: 2;
            /* column-fill: balance; */
            @include lib-css (column-gap, $indent__xxl); 
        }   
        @include min-screen($screen__xl) {
            @include lib-css (column-gap, $indent__xxl * 2); 
        }
    } @else {
        /* Disable columns */
        column-count: inherit;
    }
}