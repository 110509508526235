.form-wishlist-items {
    .action-toolbar {
        margin-top: $indent__base;
        .action.share {
            @include lib-button-as-link();
            @include lib-link-secondary(
                $_link-text-decoration         : underline,
                $_link-color-hover             : $color-sna-secundario1,
                $_link-text-decoration-hover   : underline
            );
        }
    }
}
.form-wishlist-items ~ .wishlist-toolbar {
    .toolbar-amount,
    .limiter {
        display: none;
    }
}
.wishlist-toolbar {
    .pages {
        display: none;
    }

    & ~ .wishlist-toolbar {
        .pages {
            display: block;
            position: static;
        }
    }
}

.product-info-main,
.product-options-bottom,
.block-bundle-summary {
    .action.towishlist {
        @extend .abs-action-addto-product;
    }
}

.product-items,
.products.list.items,
.table-comparison {
    .action {
        &.towishlist {
            @extend .abs-actions-addto-gridlist;
            @include lib-icon-font-symbol(
                $icon-wishlist-empty
            );
        }
    }
}

.account .table-wrapper .data.table.wishlist {
    @include lib-table-bordered(
        $_table_type: horizontal
    );

    thead > tr > th {
        border-bottom: 0;
    }

    tbody > tr:last-child > td {
        border-bottom: 1px solid $table__border-color;
    }

    .product.name {
        display: inline-block;
        margin-bottom: $indent__s;
    }

    .box-tocart {
        margin: $indent__s 0;

        .qty {
            @extend .abs-input-qty;
            vertical-align: middle;
        }
    }

    .col {
        &.item {
            width: 50%;
        }

        &.photo {
            max-width: 150px;
        }

        &.selector {
            max-width: 15px;
        }
    }

    textarea {
        margin: $indent__s 0;
    }

    .input-text.qty {
        margin-bottom: $indent__s;
    }

    .action.primary {
        vertical-align: top;
    }

    .price {
        font-weight: $font-weight__bold;
    }
}

.block-wishlist {
    .block-title {
        @extend .abs-block-title;
    }

    .counter {
        @extend .abs-block-items-counter;
    }

    .product-item-name {
        margin-right: $indent__m;
    }
}

.products-grid.wishlist {
    .product {
        &-item {
            .comment-box {
                .label {
                    @extend .abs-visually-hidden;
                }
            }

            &-actions {
                .action.delete {
                    @include lib-link-secondary(
                        $_link-color-hover              : $color-sna-primario2,
                        $_link-text-decoration-hover    : underline
                    );
                }
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________


@include max-screen($screen__m) {
    .form-wishlist-items {
        .action-toolbar {
            .action.share {
                margin-top: $indent__base;
                display: block;
            }

            .secondary {
                text-align: right;
            }
        }
    }
    .products-grid.wishlist {
        .product {
            &-item {
                &-link,
                &-sku,
                &-actions {
                    margin-left: $indent__base;
                    margin-right: $indent__base;
                }
            }
        }
    }
}

@include max-screen($screen__xs-2) {

    .products-grid.wishlist {
        .product {
            &-item {
                &-link,
                &-sku,
                &-actions {
                    margin-left: $indent__xs;
                    margin-right: $indent__xs;
                }
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .form-wishlist-items {
        .action-toolbar {
            display: flex;
            justify-content: space-between;
            .action.update {
                margin-right: $indent__s;
            }
        }
    }
    .products-grid.wishlist {
        .product {
            &-item {
                &-info {
                    padding-bottom: $indent__base;
                }

                &-link,
                &-sku,
                &-actions {
                    margin-left: $indent__base;
                    margin-right: $indent__base;
                }

                &-actions {
                    display: none;
                    justify-content: space-between;
                }

                &:hover {
                    .product-item-actions {
                        display: flex;
                        justify-content: space-between;
                        margin-top: $indent__l;
                    }
                }
            }
        }
    }
}

