.cms-page-view main {
    p {
        margin-bottom: 1em;
    }
}

//
//  Tablet
//  _____________________________________________

main {
    @include lib-font-size($main__base__font-size);
    @include lib-css(line-height, $main__base__line-height);

    h2 {
        text-align: center;
        text-transform: uppercase;
    }
}

@include min-screen($screen__m) {
    h1 {
        @include lib-font-size($h1__tablet__font-size);
        @include lib-css(margin-bottom, $h1__tablet__margin-bottom);
    }
    h2 {
        @include lib-font-size($h2__tablet__font-size);
        @include lib-css(margin-bottom, $h2__tablet__margin-bottom);
    }
    main {
        @include lib-font-size($main__tablet__font-size);
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__xl) {

    body.cms-home,
    body.cms-page-view,
    body.catalog-category-view-landing {
        h1 {
            @include lib-font-size($h1__desktop__font-size);
            @include lib-css(margin-bottom, $h1__desktop__margin-bottom);
        }
        h2 {
            @include lib-font-size($h2__desktop__font-size);
            @include lib-css(margin-bottom, $h2__desktop__margin-bottom);
        }
        main {
            @include lib-font-size($main__desktop__font-size);
        }
    }
}

.items {
    @include lib-list-reset-styles();
}

.display-1 {
    @include set-typography('display-1');
}

.display-2 {
    @include set-typography('display-2');
}

.heading-h1 {
    @include set-typography('heading-h1');
}

.heading-h2 {
    @include set-typography('heading-h2');
}

.heading-h3 {
    @include set-typography('heading-h3');
}

.label-1 {
    @include set-typography('label-1');
}

.label-2 {
    @include set-typography('label-2');
}

.label-3 {
    @include set-typography('label-3');
}

.body-1 {
    @include set-typography('body-1');
}

.body-2 {
    @include set-typography('body-2');
}

