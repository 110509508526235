//  Header
$header__background-color: false !default;
$header-icons-color: $color-gray56 !default;
$header-icons-color-hover: $color-gray20 !default;

$addto-color: $text__color__muted !default;
$addto-hover-color: $primary__color !default;

$minicart-icons-color: $header-icons-color !default;
$minicart-icons-color-hover: $header-icons-color-hover !default;

//
//  Header
//  ---------------------------------------------

.page-header {
    @include lib-css(background-color, $header__background-color);
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    box-shadow: $box-shadow-sna1;
    background-color: $color-sna-white;
    z-index: 20;
}

// If filters are active

body.filters-active {
    .page-header {
        z-index: 1;
    }
}

.header.content {
    @extend .abs-add-clearfix;
    padding-top: $indent__base;
    position: relative;

    .header-my-account-link {
        display: inline-block;
        margin-right: 0 !important; // TODO: Quitar cuando el enlace del carrito se muestre
        .my-account {
            @include lib-link-secondary(
                $_link-font-weight: $font-weight__bold,
                $_link-text-transform: uppercase
            );
            @include lib-font-size($font-size__s);
            letter-spacing: 1.2px;
            align-items: center;
        }
        svg {
            color: $color-sna-primario1;
            margin-right: $indent__xs;
        }
    }
}

.logo {
    display: block;
    position: relative;
    z-index: 5;

    .page-print & {
        float: none;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .header.panel {
        display: none;
    }

    .header.content {
        padding: 0;

        > * {
            padding: $indent__base;
        }

        .logo {
            > img {
                display: block;
            }
        }

        .minicart-wrapper {
            display: none;
        }

        .nav-toggle,
        .header-my-account-link,
        .header-my-account-link > a,
        .btn-special {
            display: flex;
            align-items: center;
            height: 48px;
            padding-top: 0;
            padding-bottom: 0;
            min-width: 0;
        }

        .header-my-account-link > a {
            flex-grow: 1;
            justify-content: center;
        }
    }

    .page-header:not(.fixed) {
        .header.content {
            display: grid;
            grid-template-rows: repeat(2, auto);
            grid-template-columns: repeat(3, 1fr);
            grid-template-areas:
                "logo search search"
                "menu account where";
            padding-right: 0;

            > * {
                align-self: center;
            }

            .logo {
                grid-area: logo;
            }

            .block-search {
                grid-area: search;
            }

            .nav-toggle {
                grid-area: menu;
            }

            .header-my-account-link {
                grid-area: account;
            }

            .btn-special {
                grid-area: where;
            }
        }
    }
    .page-header.fixed {
        .header.content {
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .logo {
                margin-left: auto;
                margin-right: auto;
                padding-top: 0;
                padding-bottom: 0;
                order: 2;
            }

            .block-search {
                order: 3;
                padding-top: 0;
                padding-bottom: 0;
            }

            .header-my-account-link {
                display: none;
            }

            .btn-special {
                order: 4;
                padding: $indent__base;
                span {
                    @include lib-visually-hidden();
                }
                svg {
                    margin-right: 0;
                }
            }
        }
    }
    .catalog-product-view .page-header:not(.fixed) .header.content {
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas:
                "logo search"
                "menu account";
        .btn-special {
            display: none;
        }
    }
}

@include max-screen($screen__xs-2) {
    .header.content {
        .header-my-account-link {
            display: none;
        }

        .block-search {
            max-width: unset;
            width: auto;
        }
    }

    .page-header:not(.fixed) {
        .header.content {
            grid-template-columns: repeat(2, 1fr);
            grid-template-areas:
                "logo search"
                "menu where";
        }
    }

    .catalog-product-view .page-header:not(.fixed) .header.content {
        grid-template-areas:
                "logo search"
                "menu menu";
    }
}

@include min-screen($screen__m) {
    .header.content {
        @extend .abs-add-clearfix-desktop;
        display: flex;
        align-items: center;
        position: static;
        .logo {
            order: 1;
            margin-right: auto;
        }

        .block-search {
            order: 2;
            margin-right: $indent__m;
        }

        .header-my-account-link {
            order: 3;
            margin-right: $indent__m;
        }

        .minicart-wrapper {
            order: 4;
        }
        > .btn-special {
            display: none;
        }
    }

    .page-header {
        border: 0;
        margin-bottom: 0;

        &:not(.fixed) {
            .header.content {
                padding-top: $indent__l;
                transition: padding 0.5s ease;
            }
            .menu {
                margin-top: $indent__m;
            }
        }

        &.fixed {
            .header.content {
                padding-top: $indent__base;
                transition: padding 0.5s ease;
            }
            .menu {
                margin-top: $indent__base;
            }
        }

        .switcher {
            display: inline-block;
        }
    }
}

@include min-screen($screen__m-2) {
    .header.content {
        .block-search {
            margin-right: $indent__xl;
        }

        .header-my-account-link {
            margin-right: $indent__xl;
        }
    }

    .page-header {
        &:not(.fixed) {
            .header.content {
                padding-top: $indent__l;
            }
        }

        &.fixed {
            .header.content {
                padding-top: $indent__base;
            }
        }
    }
}

@include min-screen($screen__l) {
    .header.content {
        .block-search {
            margin-right: $indent__xxxl;
        }

        .header-my-account-link {
            margin-right: $indent__xxxl;
        }
    }

    .page-header {
        &:not(.fixed) {
            .header.content {
                padding-top: $indent__l;
            }
        }

        &.fixed {
            .header.content {
                padding-top: $indent__base;
            }
        }
    }
}

@include min-screen($screen__xl) {
    .header.content {
        .block-search {
            margin-right: $indent__xxxxl;
        }

        .header-my-account-link {
            margin-right: $indent__xxxxl;
        }
    }

    .page-header {
        &:not(.fixed) {
            .header.content {
                padding-top: $indent__l;
            }
        }

        &.fixed {
            .header.content {
                padding-top: $indent__base;
            }
        }
    }
}
