//
//  Product Lists
//  _____________________________________________

.products {
    margin: $indent__l 0;
}

.product {
    &-item {
        &-info {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            position: relative;
        }

        &-details {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
        }

        &-name {
            flex-grow: 1;
            @include lib-css(font-weight, $font-weight__bold);
            @include lib-css(margin-bottom, $indent__s);

            @include lib-link-secondary(
                $_link-font-weight             : $font-weight__bold,
                $_link-color                   : $color-sna-primario2,
                $_link-color-visited           : $color-sna-primario2,
                $_link-color-hover             : $color-sna-primario2,
                $_link-color-active            : $color-sna-primario2
            );
            @include lib-font-size($font-size__base);
            word-break: break-word;

            > span {
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                line-clamp: 3;
                -webkit-box-orient: vertical;
            }
        }

        &-link {
            color: currentColor;
        }

        &-sku {
            display: block;
            @include lib-css(color, $color-sna-primario1);
            @include lib-css(font-weight, $font-weight__bold);
            @include lib-font-size($font-size__base);
            word-break: break-word;
        }

        .gallery {
            @extend .abs-reset-list;
        }

        &-photo,
        .gallery {
            position: relative;
            margin: 0 $indent__base auto;
            @include lib-css(padding, 0);
        }

        .action {
            margin-top: $indent__base;
            width: 100%;
            min-width: unset;
        }
    }
}


.price-container {
    .price {
        font-size: 14px;
    }

    .price-including-tax + .price-excluding-tax,
    .weee {
        margin-top: $indent__xs;
    }

    .price-including-tax + .price-excluding-tax,
    .weee,
    .price-including-tax + .price-excluding-tax .price,
    .weee .price,
    .weee + .price-excluding-tax:before,
    .weee + .price-excluding-tax .price {
        font-size: 11px;
    }

    .weee {
        &:before {
            content: '('attr(data-label) ': ';
        }

        &:after {
            content: ')';
        }

        + .price-excluding-tax {
            &:before {
                content: attr(data-label) ': ';
            }
        }
    }
}

.products-list {
    .product {
        &-item {
            display: table;
            width: 100%;

            &-info {
                display: table-row;
            }

            &-photo {
                display: table-cell;
                padding: 0 $indent__l $indent__l 0;
                vertical-align: top;
                width: 1%;
            }

            &-details {
                display: table-cell;
                vertical-align: top;
            }
        }
    }

    .product-image-wrapper {
        @extend .abs-reset-image-wrapper;
    }
}

//
//  Mobile
//  _____________________________________________


@include min-screen($screen__s) {
    .product {
        &-item {
            &-actions {
                display: block;

                .products-grid & {
                    margin: $indent__s 0;
                }

                .actions-primary + .actions-secondary {
                    //display: table-cell;
                    padding-left: 5px;
                    white-space: nowrap;
                    & > * {
                        white-space: normal;
                    }
                }

                .actions-primary {
                    display: table-cell;
                }
            }
        }
    }
}

@include max-screen($screen__xs-2) {
    .product {
        &-item {
            &-name,
            &-sku {
                @include lib-font-size($font-size__s);
            }
            &-photo {
                @include lib-css(margin, 0 $indent__xs auto);
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .product-item:not(.buy-now-item) {
        &:hover {
            .product-image-wrapper {
                padding-bottom: calc(110% - 66px);
            }
        }
    }
}
