.panel.wrapper {
    background-color: $color-sna-secundario2;

    .header.panel {
        .header-top-links,
        .country-selector {

            .country-selector__header,
            span {
                padding: 14px $indent__s;
            }
        }
        .header-top-links .nav-header-top,
        .country-selector .nav-country-selector {
            background-color: $color-sna-secundario2;
        }
    }
}
