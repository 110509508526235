//
//  Buttons variables
//  _____________________________________________

//
//  Buttons
//  ---------------------------------------------

//  Font style
$button__font-family                          : $font-family__base !default;
$button__font-size                            : $font-size__s !default;
$button__font-weight                          : $font-weight__bold !default;
$button__line-height                          : $font-size__base + 2 !default;
$button__margin                               : 0 !default;
$button__padding                              : 14px 28px !default; // is set up to false when buttons don't depend on side paddings or have fixed width
$button-with-svg__padding                     : 11px 28px !default;
$button__width                                : false !default; // is set up to false when buttons depend on side paddings or to fixed value when needed
$button__min-width                            : false !default;
$button__text-transform                       : uppercase !default;

//  Display settings
$button__display                              : inline-flex !default;
$button__cursor                               : pointer !default;
$button__border-radius                        : false !default;

$button__disabled__opacity                    : 0.5 !default;

//  Default                                   = secondary button
$button__color                                : $color-sna-primario2 !default;
$button__background                           : $color-white !default;
$button__border                               : 1px solid $color-sna-primario2 !default;
$button__gradient-color-start                 : false !default;
$button__gradient-color-end                   : false !default;

$button__hover__background                    : false !default;
$button__hover__border                        : 1px solid $color-sna-secundario2 !default;
$button__hover__color                         : $color-sna-secundario2 !default;
$button__hover__gradient-color-start          : false !default;
$button__hover__gradient-color-end            : false !default;

$button__active__background                   : $button__hover__background !default;
$button__active__border                       : $button__hover__border !default;
$button__active__color                        : $button__hover__color !default;
$button__active__gradient-color-start         : false !default;
$button__active__gradient-color-end           : false !default;

//  Primary button (over white background)
$button-primary__line-height                  : false !default;
$button-primary__width                        : false !default;
$button-primary__min-width                    : 25.6rem !default;
$button-primary-small__min-width              : unset !default;
$button-primary__margin                       : false !default;
$button-primary__padding                      : $button__padding !default;
$button-primary-small__padding                : 10px 28px !default;
$button-primary__gradient                     : false !default;
$button-primary__gradient-direction           : false !default;

$button-primary__background                   : $color-sna-primario1 !default;
$button-primary__border                       : 1px solid $color-sna-primario1 !default;
$button-primary__color                        : $color-white !default;
$button-primary__gradient-color-start         : false !default;
$button-primary__gradient-color-end           : false !default;

$button-primary__hover__background            : $color-sna-secundario1 !default;
$button-primary__hover__border                : 1px solid $color-sna-secundario1 !default;
$button-primary__hover__color                 : $button-primary__color !default;
$button-primary__hover__gradient-color-start  : false !default;
$button-primary__hover__gradient-color-end    : false !default;

$button-primary__active__background           : $button-primary__hover__background !default;
$button-primary__active__border               : $button-primary__hover__border !default;
$button-primary__active__color                : $button-primary__color !default;
$button-primary__active__gradient-color-start : false !default;
$button-primary__active__gradient-color-end   : false !default;

//  Secondary button (background transparent)
$button-secondary__line-height                  : false !default;
$button-secondary__width                        : false !default;
$button-secondary__min-width                    : 25.6rem !default;
$button-secondary-small__min-width              : unset !default;
$button-secondary__margin                       : false !default;
$button-secondary__padding                      : $button__padding !default;
$button-secondary-small__padding                : 10px 28px !default;
$button-secondary__gradient                     : false !default;
$button-secondary__gradient-direction           : false !default;

$button-secondary__background                   : transparent !default;
$button-secondary__border                       : 1px solid $color-sna-primario2 !default;
$button-secondary__color                        : $color-sna-primario2 !default;
$button-secondary__gradient-color-start         : false !default;
$button-secondary__gradient-color-end           : false !default;

$button-secondary__hover__background            : $color-sna-secundario2 !default;
$button-secondary__hover__border                : 1px solid $color-sna-secundario2 !default;
$button-secondary__hover__color                 : $color-sna-white !default;
$button-secondary__hover__gradient-color-start  : false !default;
$button-secondary__hover__gradient-color-end    : false !default;

$button-secondary__active__background           : $button-secondary__hover__background !default;
$button-secondary__active__border               : $button-secondary__hover__border !default;
$button-secondary__active__color                : $button-secondary__hover__color !default;
$button-secondary__active__gradient-color-start : false !default;
$button-secondary__active__gradient-color-end   : false !default;

//  Primary button (over dark background)
$button-primary-over-dark-bg__background                   : $color-white !default;
$button-primary-over-dark-bg__border                       : 1px solid $color-white !default;
$button-primary-over-dark-bg__color                        : $color-sna-primario1 !default;

$button-primary-over-dark-bg__hover__background            : $button-primary-over-dark-bg__background !default;
$button-primary-over-dark-bg__hover__border                : $button-primary-over-dark-bg__border !default;
$button-primary-over-dark-bg__hover__color                 : $color-sna-secundario1 !default;

$button-primary-over-dark-bg__active__background           : $button-primary-over-dark-bg__hover__background !default;
$button-primary-over-dark-bg__active__border               : $button-primary-over-dark-bg__hover__border !default;
$button-primary-over-dark-bg__active__color                : $button-primary-over-dark-bg__hover__color !default;

//  Expandable button
$button-expandable__font-family               : $font-family__base !default;
$button-expandable__font-size                 : $font-size__s !default;
$button-expandable__font-weight               : $font-weight__bold !default;
$button-expandable__line-height               : $button__line-height !default;
$button-expandable__margin                    : 0 !default;
$button-expandable__padding                   : 0 !default; // is set up to false when buttons don't depend on side paddings or have fixed width
$button-expandable__width                     : false !default; // is set up to false when buttons depend on side paddings or to fixed value when needed
$button-expandable__text-transform            : uppercase !default;

$button-expandable__display                   : inline-block !default;
$button-expandable__cursor                    : pointer !default;
$button-expandable__border-radius             : false !default;

$button-expandable__color                     : $color-sna-primario3 !default;
$button-expandable__background                : none !default;
$button-expandable__border                    : none !default;

//  Special button
$button-special__line-height                  : $button__line-height !default;
$button-special__width                        : auto !default;
$button-special__min-width                    : $button-primary__min-width !default;
$button-special__max-width                    : false !default;
$button-special__margin                       : false !default;
$button-special__padding                      : $button__padding !default;
$button-special__gradient                     : false !default;
$button-special__gradient-direction           : false !default;

$button-special__background                   : $color-sna-primario1;
$button-special__border                       : 1px solid $color-sna-primario1 !default;
$button-special__color                        : $color-white !default;
$button-special__gradient-color-start         : false !default;
$button-special__gradient-color-end           : false !default;

$button-special__hover__background            : $color-sna-secundario1 !default;
$button-special__hover__border                : 1px solid $color-sna-secundario1 !default;
$button-special__hover__color                 : false !default;
$button-special__hover__gradient-color-start  : false !default;
$button-special__hover__gradient-color-end    : false !default;

$button-special__active__background           : $button-special__hover__background !default;
$button-special__active__border               : $button-special__hover__border !default;
$button-special__active__color                : $button-special__color !default;
$button-special__active__gradient-color-start : false !default;
$button-special__active__gradient-color-end   : false !default;

//  Gradient button
$button__gradient                             : false !default; // [true|false] - button has a gradient background
$button__gradient-direction                   : false !default; // button gradient direction if button has a gradient background

//  Button with icon
$button-icon__use                             : false !default;
$button-icon__content                         : $icon-settings !default;
$button-icon__font                            : $icon-font !default;
$button-icon__font-size                       : 22px !default;
$button-icon__line-height                     : $button-icon__font-size !default;
$button-icon__color                           : inherit !default;
$button-icon__margin                          : 0 !default;
$button-icon__vertical-align                  : top !default;
$button-icon__position                        : $icon__position !default;
$button-icon__text-hide                       : false !default;

$button-icon__hover__font-color               : inherit !default;
$button-icon__active__font-color              : inherit !default;

//  Large button
$button__font-size__l                         : $font-size__l !default;
$button__line-height__l                       : $font-size__l + 4 !default;
$button__padding__l                           : 14px 17px !default;

//  Small button
$button__font-size__s                         : 11px !default;
$button__line-height__s                       : $button__font-size__s + 1 !default;
$button__padding__s                           : $indent__xs 8px !default;
