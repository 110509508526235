//
//  Responsive variables
//  _____________________________________________

$use-flex    : true !default; // Use flexbox [true|false]
$responsive  : true !default; // Theme is responsive [true|false]

$screen__375: 375px !default;
$screen__550: 550px !default;
$screen__1920: 1920px !default;

$screen__xxxxs: 320px !default;
$screen__xxxs : $screen__375;
$screen__xxs  : 420px !default;
$screen__xs   : 480px !default;
$screen__xs-2 : $screen__550;
$screen__s    : 640px !default;
$screen__m    : 768px !default;
$screen__m-2  : 950px !default;
$screen__l    : 1024px !default;
$screen__l-2  : 1100px !default;
$screen__l-3  : 1200px !default;
$screen__xl   : 1440px !default;
$screen__xxl  : 1800px !default;
$screen__xxl-2: 1900px !default;
$screen__xxxl : $screen__1920;

$breakpoint-names: (
    phone-375: $screen__375,
    tablet: $screen__550,
    desktop-1920: $screen__1920,
) !default;
