

.cms-page-picsection {
    display: flex;
    flex-direction: column;
    @include lib-layout-indent-main-inverse();
    margin-bottom: 0 !important;
    
    > * {
        flex: 1 1 50%;
    }
    
    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        @include lib-layout-indent(
            $_layout-indent__s__left          :  $layout-indent-main__s,
            $_layout-indent__s__right         :  $layout-indent-main__s,
            $_layout-indent__m__left          :  $layout-indent-main__m,
            $_layout-indent__m__right         :  $layout-indent-main__m,
            $_layout-indent__xl__left         :  $layout-indent-main__xl * 2,
            $_layout-indent__xl__right        :  $layout-indent-main__xl
        );
        @include lib-css(padding-top, $indent__xl);
        @include lib-css(padding-bottom, $indent__xl);

        @include _lib-cms-page-body-headings();
        
        > :first-child {
            margin-top: 0;
        }

        > :last-child {
            margin-bottom: 0;
        }
    }

    &__title {
        @include lib-typography__main__heading2();
        @include lib-typography-light-heading();
    }
    
    &__pic {
        position: relative;
        text-align: center;
        padding-left: 0;
        padding-right: 0;
        @include lib-layout-indent(
            $_layout-indent__s__left          :  $layout-indent-main__s,
            $_layout-indent__s__right         :  $layout-indent-main__s,
            $_layout-indent__m__left          :  $layout-indent-main__m,
            $_layout-indent__m__right         :  $layout-indent-main__m,
            $_layout-indent__l__left          :  0,
            $_layout-indent__l__right         :  0,
            $_property                        :  "margin"
        );

        img {
            display: block;
            margin: 0 auto;
        }

        figcaption {
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 7%;
            right: 50%;

            strong {
                display: block;
                @include lib-font-size(3.8em);
                @include lib-css(color, $color-sna-primario1);
            }
            
            cite {
                display: block;
                margin-top: .8em;
                @include lib-font-size($font-size__xs);
            }
        }
    }
    
    @include min-screen($screen__l) {
        flex-direction: row;

        &__content {
            padding-top: 0;
            padding-bottom: 0;
        }

        &__pic {
            margin-top: 0;
            figcaption {
                left: 12.8%;

                cite {
                    @include lib-font-size($font-size__s);
                }
            }
        }
    }

    &.is-reverse {
        .cms-page-picsection {
            &__content {
                order: 2;
                @include lib-layout-indent(
                    $_layout-indent__s__left          :  $layout-indent-main__s,
                    $_layout-indent__s__right         :  $layout-indent-main__s,
                    $_layout-indent__m__left          :  $layout-indent-main__m,
                    $_layout-indent__m__right         :  $layout-indent-main__m,
                    $_layout-indent__xl__left         :  $layout-indent-main__xl,
                    $_layout-indent__xl__right        :  $layout-indent-main__xl * 2
                );
            }
            
            &__pic {
                @include lib-layout-indent(
                    $_layout-indent__s__left          :  $layout-indent-main__s,
                    $_layout-indent__s__right         :  $layout-indent-main__s,
                    $_layout-indent__m__left          :  $layout-indent-main__m,
                    $_layout-indent__m__right         :  $layout-indent-main__m,
                    $_layout-indent__l__left          :  0,
                    $_layout-indent__l__right         :  0,
                    $_property                        : "margin"
                );
                margin-top: 0;
            }
    
            @include min-screen($screen__l) {
                &__pic {
                    margin-bottom: 0;
                }
            }
        }
    }

    &.fixed-height,
    &.height-fixed {
        .cms-page-picsection__pic {
            height: 320px;
            overflow: hidden;
            padding: 0;

            img {
                width: 100%;
                height: 100%;
                @include object-fit(cover);
            }
        }

        // @include min-screen(915px) {
        @include min-screen($screen__m) {
            .cms-page-picsection__pic {
                height: auto;

                img {
                    height: auto;
                    width: auto;
                    object-fit: inherit;
                }
            }
        }
    }

    &.height-content {
        .cms-page-picsection__content {
            @include lib-css(padding-top, $indent__xl);
            @include lib-css(padding-bottom, $indent__xl);
        }
    }

    &.theme-dark,
    &__pic.theme-dark {
        @include lib-landing-theme-dark()
    }

    &.theme-light,
    &__pic.theme-light {
        @include lib-landing-theme-light();
    }
    
    &__pic.to-bottom {
        display: flex;
        align-items: flex-end;
    }
    &__pic.cover {
        img {
            @include object-fit(cover);
            height: 100%;
            width: 100%;
        }

        &--left {
            img {
                object-position: left;
            }
        }
        &--right {
            img {
                object-position: right;
            }
        }
    }
}

.cms-page-section .cms-page-picsection {
    @include lib-layout-indent(
        $_layout-indent__s__left        :  -$layout-indent-main__s,
        $_layout-indent__s__right       :  -$layout-indent-main__s,
        $_layout-indent__m__left        :  -$layout-indent-main__m,
        $_layout-indent__m__right       :  -$layout-indent-main__m,
        $_layout-indent__xl__left       :  -$layout-indent-main__xl * 2,
        $_layout-indent__xl__right      :  -$layout-indent-main__xl * 2,
        $_property                      :  "margin"
    );
}