@mixin on4menu-desktop-column-container() {
    @include on4menu-desktop {
        margin-left: -$on4menu-column-gutter / 2;
        margin-right: -$on4menu-column-gutter / 2;
    }
}

@mixin on4menu-desktop-column() {
    @include on4menu-desktop {
        padding-left: $on4menu-column-gutter / 2;
        padding-right: $on4menu-column-gutter / 2;
    }
}