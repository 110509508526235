.menu {
    @include lib-css(background, $color-sna-white);
    font-size: $on4menu-font-size--base;
}

@include on4menu-mobile {
    .menu {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        padding: 0;
        transform-origin: top;
        transform: scaleY(0);
        transition: transform 0.2s 0.2s;
        overflow-x: hidden;
        height: 100vh;
        z-index: 10;

        > * {
            opacity: 0;
            transition: opacity 0.2s;
        }
    }

    .menu.is-expanded {
        @include lib-css(box-shadow, $box-shadow-sna3);
        transform: scaleY(1);
        transition: transform 0.2s;
        > * {
            opacity: 1;
            transition: opacity 0.2s 0.2s;
        }
    }

    .menu__sections {
        display: flex;
        flex-direction: column;
        padding: 0 $indent__l;
    }
}

@include on4menu-desktop {
    .menu {
        display: flex;
        margin-left: auto;
        height: auto !important; /* Don't use style="height: ..." setted via JS (it's only for mobile) */
        padding-left: $indent__m;
        padding-right: 0;

        &__sections {
            width: 100%;
        }
    }

    .menu__section--mobile-only {
        display: none;
    }
}

@include on4menu-desktop-big {
    .menu {
        padding-left: $layout-indent-main__m;
    }
}

@include min-screen($screen__xl) {
    .menu {
        padding-left: $layout-indent-main__xl;
    }
}

//
//  Account minicart section
//  ---------------------------------------------
.nav-account-minicart {
    background-color: $color-sna-primario4;
    margin-left: -$indent__l;
    margin-right: -$indent__l;
    padding: $indent__base $indent__l;
    display: none;
    justify-content: space-around;
    box-shadow: $box-shadow-sna3;

    .my-account {
        @include lib-link-secondary(
                $_link-font-weight             : $font-weight__bold,
                $_link-text-transform          : uppercase
        );
        @include lib-font-size($font-size__s);
        letter-spacing: 1.2px;
        align-items: center;
    }
    svg {
        color: $color-sna-primario1;
        margin-right: $indent__xs;
    }
}
@include on4menu-mobile {
    .page-header.fixed {
        .nav-account-minicart {
            display: flex;
        }
    }
}
@include max-screen($screen__xs-2) {
    .page-header {
        .nav-account-minicart {
            display: flex;
        }
    }
}

//
//  Menu nav links
//  ---------------------------------------------
.mobile-menu-nav-links {
    font-size: $font-size__s;
    padding: $indent__base 0 $indent__l;
    box-sizing: border-box;

    a {
        color: currentColor;
    }

    .btn-expandable {
        @include lib-button-expandable(
            $_button-expandable-margin: 0 0 $indent__m,
            $_button-expandable-color: $color-sna-black,
            $_button-expandable-font-weight: $font-weight__regular,
            $_button-expandable-text-transform: none
        );
    }

    li {
        margin-bottom: $indent__m;
    }
    ul {
        padding: 0;
        margin-bottom: $indent__base;
    }

    .action.secondary {
        width: 100%;
        max-width: 31.5rem;
        display: block;
        margin: 0 auto;
    }

    .social-networks {
        margin: 0 auto;
        text-align: center;
        max-width: 31.5rem;

        a {
            margin-top: $indent__m;
            svg {
                width: 4.2rem;
                height: 3.2rem;
            }
            &:not(:last-child) {
                margin-right: $indent__base;
            }
        }
    }
}

//
//  Menu mobile country selector
//  ---------------------------------------------
.mobile-country-selector {
    text-align: center;
    margin-top: $indent__s;
    margin-bottom: $indent__base;
    .country-selector {
        &__header {
            padding: 0 $indent__s $indent__s;
            @include lib-link-secondary(
                $_link-font-weight             : $font-weight__bold,
                $_link-color                   : $color-sna-primario2,
                $_link-color-visited           : $color-sna-primario2,
                $_link-color-hover             : $color-sna-primario1,
                $_link-color-active            : $color-sna-primario2
            );
            @include lib-font-size($font-size__s);
            display: inline-block;

            .menu {
                display: inline-block;
            }
            .arrow {
                display: none;
            }

            > span {
                vertical-align: middle;
                color: inherit;
                text-transform: uppercase;
            }
        }
        .nav-country-selector {
            @include lib-css(box-shadow, $box-shadow-sna3);
            background-color: $color-sna-white;
            text-align: left;
            padding: $indent__s $indent__l 0;
            position: absolute;
            top: 0 !important; /* Don't use style="top: ..." setted via JS (it's only for desktop) */
            right: 0;
            bottom: 0;
            left: 0;
            transform: translateX(100%);
            transition: transform 0.3s;
            overflow-x: hidden;
            z-index: 1;

            ul {
                padding: 0 $indent__xs;
                margin: 0;
                li {
                    list-style: none;
                    a, a > span {
                        @include lib-css(font-weight, $font-weight__heavier);
                        @include lib-link-secondary(
                            $_link-font-weight          : $font-weight__heavier,
                            $_link-color                : $color-sna-primario2,
                            $_link-color-visited        : $color-sna-primario2,
                            $_link-color-hover          : $color-sna-primario1,
                            $_link-color-active         : $color-sna-primario2
                        );
                    }
                }
                li:not(:last-child) {
                    margin-bottom: $indent__m;
                }
                li:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .nav-country-selector.is-expanded {
        transform: translateX(0);
        .country-selector {
            &__back-btn {
                display: block;
                padding: $indent__s 0;

                .arrow-left {
                    display: inline-block;
                    width: 2.6rem;
                    margin-right: $indent__s;
                }

                > span {
                    display: inline-block;
                    color: $color-sna-primario1;
                    font-weight: $font-weight__bold;
                    text-transform: uppercase;
                }
            }
        }
        ul {
            border-top: 1px solid $color-sna-primario4;
            padding: $indent__m 0 $indent__s;
        }
    }
}

//
//  In-page navigation
//  _____________________________________________
/**
 * 1. Move target the page header height to make content visible with sticky header
 */
.anchor-target {
    visibility: hidden;
    position: absolute;
    margin-top: -58px; /* [1] */

    @include min-screen($screen__m) {
        margin-top: -128px; /* [1] */
    }
}

.anchor-target--product-view {
    margin-top: -98px; /* [1] */
    @include min-screen($screen__m) {
        margin-top: -104px; /* [1] */
    }
}
