$cta-banner-height__s                   : 320px;
$cta-banner-height__m                   : 424px;
$cta-banner-height__l                   : 581px;

$cta-banner__block-class                : "cta-banner";
$cta-banner__row-breakpoint             : $screen__l;
$cta-banner__body-width                 : 50%;
$cta-banner__figure-width               : 50%;

$cta-banner__title__font-size           :  30px !default;
$cta-banner__title__font-size__m        :  false !default;
$cta-banner__title__font-size__l        :  46px !default;

$cta-banner__subtitle__font-size        :  16px !default;
$cta-banner__subtitle__font-size__m     :  false !default;
$cta-banner__subtitle__font-size__l     :  20 !default;

$cta-product__row-breakpoint            : $screen__m;

$cta-product__title__font-size          :  $h2__font-size !default;
$cta-product__title__font-size__m       :  $h2__tablet__font-size !default;
$cta-product__title__font-size__l       :  $h2__desktop__font-size !default;

$cta-banner-details__block-class                : "cta-banner-details";

@mixin lib-cta-banner-row-breakpoint(
    $block-class: $cta-banner__block-class,
    $row-breakpoint: $cta-banner__row-breakpoint,
    $body-width: $cta-banner__body-width,
    $figure-width: $cta-banner__figure-width
) {
    @include min-screen($row-breakpoint) {
        flex-direction: row;

        .#{$block-class}__body {
            flex-basis: $body-width;
            width: auto;
            padding-top: 0;
            padding-bottom: 0;
        }

        .#{$block-class}__figure {
            flex-basis: $figure-width;
        }
    }
}

@mixin lib-cta-banner-no-img-small-screens(
    $block-class: "cta-banner",
    $row-breakpoint: $screen__m
) {
    @include max-screen($row-breakpoint) {
        justify-content: center;

        .#{$block-class}__figure {
            display: none;
        }
    }
}

@mixin lib-cta-banner(
    $block-class: $cta-banner__block-class,
    $row-breakpoint: $cta-banner__row-breakpoint,
    $body-width: $cta-banner__body-width,
    $figure-width: $cta-banner__figure-width
) {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include lib-css(color, $color-sna-primario2);
    @include lib-layout-indent-main-inverse();

    img {
        display: block;
    }

    .#{$block-class}__body {
        width: 100%;
        @include lib-css(padding-top, $indent__xxl);
        @include lib-css(padding-bottom, $indent__xxl);
        @include lib-layout-indent-main();
        @include lib-typography__main__text();
        text-align: right;
    }

    .#{$block-class}__body-header {
        display: flex;
        flex-direction: column;
        text-transform: uppercase;
        @include lib-css(margin-bottom, $indent__base);
    }

    .#{$block-class}__title {
        margin-top: 0;
        margin-bottom: 0;
        overflow-wrap: break-word;
        text-align: right;
        @include lib-typography-font-size-breakpoints(
            $cta-banner__title__font-size,
            $cta-banner__title__font-size__m,
            $cta-banner__title__font-size__l
        );
    }

    .#{$block-class}__subtitle {
        order: -1;
        margin-bottom: $indent__s;
        @include lib-typography-font-size-breakpoints(
            $cta-banner__subtitle__font-size,
            $cta-banner__subtitle__font-size__m,
            $cta-banner__subtitle__font-size__l
        );
        @include lib-css(font-weight, $font-weight__hairline);
    }

    .#{$block-class}__body-content {
        > .action {
            @include lib-css(margin-top, $indent__l);
        }

        > p {
            @include lib-css(margin-bottom, 1em);

            /**
             *  1. .action should have inline-block display, so p's margin-bottom (1em) and .action's
             *     margin-top won't collapse. Manual fix.
             *
             *  RECOMENDED: wrap .action with p.actions. See next declaration.
             */
            & + .action {
                @include lib-css(margin-top, $indent__l - $main__base__font-size); /* [1] */

                @include min-screen($screen__m) {
                    @include lib-css(margin-top, $indent__l - $main__tablet__font-size); /* [1] */
                }

                @include min-screen($screen__xl) {
                    @include lib-css(margin-top, $indent__l - $main__desktop__font-size); /* [1] */
                }
            }
        }

        > .actions {
            display: block;
            @include lib-css(margin-top, $indent__l);
            margin-bottom: 0;
        }
    }

    .#{$block-class}__figure {
        align-self: stretch;
        position: relative;
        overflow: hidden;
    }

    .#{$block-class}__figure img {
        margin-left: 50%;
        transform: translateX(-50%);
        display: block;
    }

    @include lib-cta-banner-row-breakpoint(
        $block-class: $block-class,
        $row-breakpoint: $row-breakpoint,
        $body-width: $body-width,
        $figure-width: $figure-width
    );

    &.theme-dark {
        color: white;
        background-color: $color-sna-primario2;
    }
}

@mixin lib-cta-banner-reverse(
    $block-class: $cta-banner__block-class,
    $row-breakpoint: $cta-banner__row-breakpoint
) {
    .#{$block-class}__title {
        text-align: left;
    }

    .#{$block-class}__body {
        text-align: left;
        @include lib-layout-indent-main();
    }

    .#{$block-class}__figure {
        order: -1;
    }

    @include min-screen($row-breakpoint) {
        .#{$block-class}__body {
            padding-left: 4%;
        }
    }
}


.cta-banner {
    @include lib-cta-banner();
}

.cta-banner.is-reverse {
    @include lib-cta-banner-reverse();
}

/**
 *  CTA PRODUCT (class="cta-product")
 * ------------------------------------------------------------------------- */

@mixin lib-cta-banner-product ($block-class: "cta-banner") {
    @include lib-cta-banner(
        $block-class,
        $row-breakpoint: $cta-product__row-breakpoint
    );
    @include lib-cta-banner-reverse(
        $block-class,
        $row-breakpoint: $cta-product__row-breakpoint
    );

    @include lib-css(padding-left, $layout-indent-main__s);
    @include lib-css(padding-right, $layout-indent-main__s);
    @include lib-css(background-color, $color-sna-primario4);

    .#{$block-class} {
        &__body {
            @include lib-css(padding-top, $indent__xxl);
            @include lib-css(padding-bottom, $indent__xxl);
            width: 100%;
        }

        &__title {
            @include lib-typography-font-size-breakpoints(
                $cta-product__title__font-size,
                $cta-product__title__font-size__m,
                $cta-product__title__font-size__l
            );
            text-transform: none;
            font-weight: bold;
        }

        &__figure {
            display: flex;
            align-items: center;
            justify-content: center;
            height: $cta-banner-height__s;

            img {
                flex: 0 0 auto;
                max-height: 100%;
                margin-left: 0;
                transform: none;
                display: block;
            }
        }

        @include min-screen($screen__m) {
            min-height: $cta-banner-height__m;
            padding-left: 0;
            padding-right: 0;

            &__figure {
                height: auto;
                padding-left: 8.3%;
                padding-bottom: $indent__l;
                padding-top: $indent__l;

                img {
                    max-height: $cta-banner-height__m;
                }

            }
        }

        @include min-screen($screen__xl) {
            min-height: $cta-banner-height__l;

            &__figure {

                img {
                    max-height: $cta-banner-height__l;
                }
            }
        }

        &__figure {
            &.align-top {
                padding-top: 0;
            }
            &.align-bottom {
                padding-bottom: 0;
            }
        }
    }
}

.cta-banner.product {
    @include lib-cta-banner-product();
}

.cta-product {
    position: relative;
    @include lib-cta-banner-product("cta-product");

    .close {
        position: absolute;
        top: 1em;
        right: 1em;
    }
}

/**
 * 1. Extra space between cms-page-section's header and first cta product
 */
.cms-page-section > header + .cta-banner.product {
    margin-top: $indent__xl; /* [1] */
}

/**
 *  HERO BANNER (class="cta-herobanner cta-banner")
 * ------------------------------------------------------------------------- */

.cta-herobanner {
    position: relative;
    background-color: $color-sna-primario2;
    color: white;
    display: block;
    height: 75vh; /* TODO: calc header height */

    .cta-banner__body {
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
        left: 0;
        z-index: 1;
        text-align: center;
        @include lib-font-size($font-size__base);
        transform: translateY(-50%);
    }

    .cta-banner__body-header {
        display: block;
    }

    .cta-banner__title {
        @include lib-font-size($font-size__xxl);
        text-align: center;
    }

    .action {
        @include lib-css(margin-top, $indent__l);
    }

    .cta-banner__figure {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        overflow: hidden;
        align-self: auto;
        max-height: none;
        max-width: 100%;

        picture {
            width: 100%;
            height: 100%;
        }

        img {
            width: 100%;
            height: 100%;
            @include object-fit(cover);
        }
    }

    @include min-screen($screen__m) {
        .cta-banner__body {
            @include lib-font-size($font-size__l);
        }
        .cta-banner__title {
            @include lib-font-size(48);
        }
    }

    @include min-screen($screen__l) {
        height: 600px;
    }

    @include min-screen($screen__xl) {
        .cta-banner__body {
            @include lib-font-size($font-size__xxl);
        }
        .cta-banner__title {
            @include lib-font-size(64);
        }
    }
}


/**
 *  BANNER DETAILS (class="cta-banner-details")
 * ------------------------------------------------------------------------- */
@mixin lib-cta-banner-details(
    $block-class: $cta-banner-details__block-class,
    $row-breakpoint: $cta-banner__row-breakpoint,
    $body-width: $cta-banner__body-width,
    $figure-width: $cta-banner__figure-width
) {
    display: flex;
    flex-direction: column;
    @include lib-css(color, $color-sna-primario2);
    @include lib-layout-indent-main-inverse();

    img {
        display: block;
    }

    .#{$block-class}__body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        @include lib-layout-indent(
            $_layout-indent__s__left          :  $layout-indent-main__s,
            $_layout-indent__s__right         :  $layout-indent-main__s,
            $_layout-indent__m__left          :  $layout-indent-main__m,
            $_layout-indent__m__right         :  $layout-indent-main__m,
            $_layout-indent__xl__left         :  $layout-indent-main__xl * 2,
            $_layout-indent__xl__right        :  $layout-indent-main__xl
        );
        @include lib-css(padding-top, $indent__xl);
        @include lib-css(padding-bottom, $indent__xl);
    }

    .#{$block-class}__body-header {
        display: flex;
        flex-direction: column;
    }

    .#{$block-class}__title {
        @include lib-typography-light-heading();
        margin-top: 0;
        text-align: left;
    }

    .#{$block-class}__body-content {
        > p {
            @include lib-css(margin-bottom, 1em);
        }
    }

    .#{$block-class}__figure {
        align-self: stretch;
        position: relative;
        overflow: hidden;
    }

    .#{$block-class}__figure img {
        margin-left: 50%;
        transform: translateX(-50%);
    }

    @include lib-cta-banner-row-breakpoint(
        $block-class: $block-class,
        $row-breakpoint: $row-breakpoint,
        $body-width: $body-width,
        $figure-width: $figure-width
    );

    &.theme-dark {
        color: white;
        background-color: $color-sna-primario2;
    }
}

@mixin lib-cta-banner-details-reverse(
    $block-class: $cta-banner-details__block-class,
    $row-breakpoint: $cta-banner__row-breakpoint
) {
    .#{$block-class}__body {
        @include lib-layout-indent-main();
    }

    .#{$block-class}__figure {
        order: -1;
    }

    @include min-screen($row-breakpoint) {
        .#{$block-class}__body {
            padding-left: 4%;
        }
    }
}

.cta-banner-details {
    @include lib-cta-banner-details();
}

.cta-banner-details.is-reverse {
    @include lib-cta-banner-details-reverse();
}
