.block.filter {
    margin-bottom: $indent__xl;

    .filter-title {
        display: none;
    }

    .items {
        @extend .abs-reset-list;
    }

    .close-filter-options {
        display: none;
    }
}

.block-subtitle {
    display: inline-block;
    margin-bottom: $indent__s;
}

.filter-current {

    .block-subtitle.filter-current-subtitle {
        display: none;
    }

    .items {
        display: inline-block;
        li.item {
            margin-bottom: $indent__s;
            margin-right: $indent__s;
            display: inline-block;
        }
    }

    .filter-label {
        display: inline-block;
    }

    .action.remove {
        padding: $indent__s;
        background-color: $color-sna-primario4;
        @include lib-link-secondary(
            $_link-font-weight             : $font-weight__heavier,
            $_link-color                   : $color-sna-primario2,
            $_link-color-visited           : $color-sna-primario2,
            $_link-color-hover             : $color-sna-primario2,
            $_link-color-active            : $color-sna-primario2
        );
        @include lib-font-size($font-size__xs);

        &:hover {
            -webkit-box-shadow: $box-shadow-sna1;
            -moz-box-shadow: $box-shadow-sna1;
            box-shadow: $box-shadow-sna1;
        }

        .close-text {
            display: none;
        }

        svg {
            margin-left: $indent__s;
            width: 1.6rem;
            height: 1.6rem;
        }
    }

    + .filter-actions {
        margin-bottom: $indent__s;
        display: inline-block;


        .action.clear {
            @include lib-link-secondary(
                $_link-font-weight             : $font-weight__heavier,
                $_link-color                   : $color-sna-primario1,
                $_link-color-visited           : $color-sna-primario1,
                $_link-color-hover             : $color-sna-secundario1,
                $_link-text-decoration-hover   : underline,
                $_link-color-active            : $color-sna-primario1
            );
            @include lib-font-size($font-size__s);
        }
    }
}

.filter-options-content {
    .filter-count-label {
        @extend .abs-visually-hidden;
    }
}

.block.filter.category,
.filter-options .filter-options-item-category {
    .filter-options-title {
        @include lib-title(small);
        margin: 0 0 $indent__base;
        word-break: break-all;
        letter-spacing: 1px;
    }

    .filter-options-content {
        margin: 0 0 $indent__xl;
    }

    .items .item {
        margin-bottom: $indent__s;

        > a {
            @include lib-link-secondary(
                $_link-font-weight              : $font-weight__heavier,
                $_link-color                    : $color-sna-primario3,
                $_link-color-visited            : $color-sna-primario3,
                $_link-color-hover              : $color-sna-primario3,
                $_link-text-decoration-hover    : underline,
                $_link-color-active             : $color-sna-primario3
            );
            @include lib-font-size($font-size__s);
            padding: $indent__xs 0;
        }

        &.hide {
            display: none;
        }
        &.show {
            display: list-item;
        }
        &.show-more {
            .hide {
                display: none;
            }
            .show {
                display: inline-block;
                cursor: pointer;
                @include lib-link-secondary(
                    $_link-font-weight             : $font-weight__heavier,
                    $_link-color                   : $color-sna-primario1,
                    $_link-color-visited           : $color-sna-primario1,
                    $_link-color-hover             : $color-sna-secundario1,
                    $_link-text-decoration-hover   : underline,
                    $_link-color-active            : $color-sna-primario1
                );
                @include lib-font-size($font-size__s);
                padding: $indent__xs 0;
            }
        }
    }
}

.filter-options {
    margin: 0;

    .filter-options-text {
        @include lib-title(small);
        margin: 0 0 $indent__base;
        word-break: break-all;
        letter-spacing: 0.8px;
        @include lib-font-size($font-size__base);
    }

    .filter-options-item {
        background-color: $color-sna-primario4;
        @include lib-font-size($font-size__s);
        color: $color-sna-black;
        margin: 0 0 $indent__s;

        .arrow-up {
            display: none;
        }
        .arrow-down {
            display: inline-block;
        }

        .filter-options-title {
            padding: $indent__s $indent__xl $indent__s $indent__s;
            position: relative;
            @include lib-css(font-weight, $font-weight__heavier);
            cursor: pointer;

            > span {
                display: inline-block;
                word-break: break-word;
            }

            .arrow {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: $indent__base;
            }
        }

        .items .item {
            &:not(:last-child) {
                margin-bottom: $indent__s;
            }

            a {
                @include lib-link-secondary(
                    $_link-color                   : $color-sna-black,
                    $_link-color-visited           : $color-sna-black,
                    $_link-color-hover             : $color-sna-black,
                    $_link-color-active            : $color-sna-black
                );
                @include lib-font-size($font-size__s);
                position: relative;
            }
        }

        .filter-options-content {
            padding: $indent__s $indent__base $indent__m;

            .smile-es-range-slider {
                position: relative;
                display: flex;
                flex-wrap: wrap;

                .from,
                .to {
                    flex: 0 1 50%;
                    order: 1;
                }

                .to {
                    text-align: right;
                }


                .ui-slider-horizontal {
                    height: 2px;
                    margin: $indent__base 0;
                    flex: 0 1 100%;
                    order: 0;

                    .ui-slider-handle {
                        transform: translateY(-50%);
                        width: $indent__base;
                        height: $indent__base;
                        box-shadow: $box-shadow-sna1;
                        background: $color-sna-white;
                    }

                    .ui-slider-range {
                        height: 2px;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        background: $color-sna-primario1;
                    }
                }
                .actions-toolbar {
                    flex: 0 1 100%;
                    order: 2;
                    margin-top: $indent__base;
                    color: $color-sna-primario3;

                    .actions-primary {
                        margin-top: $indent__s;

                        .action.primary {
                            display: block;
                        }
                    }
                }
            }

            .show-more,
            .show-less {
                display: inline-block;
                cursor: pointer;
                @include lib-link-secondary(
                    $_link-font-weight             : $font-weight__heavier,
                    $_link-color                   : $color-sna-primario1,
                    $_link-color-visited           : $color-sna-primario1,
                    $_link-color-hover             : $color-sna-secundario1,
                    $_link-text-decoration-hover   : underline,
                    $_link-color-active            : $color-sna-primario1
                );
                @include lib-font-size($font-size__s);
                padding: $indent__xs 0;
                margin-top: $indent__s;
            }
        }

        &.active {
            .arrow-up {
                display: inline-block;
            }
            .arrow-down {
                display: none;
            }
        }
    }

    .count {
        @include lib-css(color, $text__color__muted);

        &:before {
            content: '(';
        }

        &:after {
            content: ')';
        }
    }

    .action.primary {
        min-width: unset;
        float: none;
    }
    .actions-toolbar:after {
        clear: none;
    }
}

.filtered {
    .items {
        margin: 15px 0;
    }

    .item {
        margin-bottom: 6px;
        padding-left: 22px;
        position: relative;

        .label {
            font-weight: $font-weight__bold;
        }

        .action.remove {
            @extend .abs-remove-button-for-blocks;
            left: -6px;
            position: absolute;
            top: 0;
        }
    }

    & + .actions {
        margin-bottom: 35px;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {

    .column.main .block.filter {
        display: none;
    }

    .block.filter.category,
    .filter-options .filter-options-item-category {
        display: none;
    }

    .block.filter:not(.category) {
        .filter-options-text {
            padding: $indent__base;
            display: block;
            border: 1px solid $color-sna-black;
            text-align: center;
            cursor: pointer;
        }

        .container-filter-options {
            display: none;
        }

        .filter-content {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;

            & > * {
                align-self: center;
            }

            .filter-options {
                order: -1;
                flex: 0 1 100%;

                .filter-options-item.filter-active {
                    .filter-options-title:after {
                        content: '';
                        width: 8px;
                        height: 8px;
                        background-color: $color-sna-primario1;
                        -webkit-border-radius: 50%;
                        -moz-border-radius: 50%;
                        border-radius: 50%;
                        display: inline-block;
                        margin-left: $indent__s;
                    }
                }
            }

            .filter-actions {
                margin: 0 0 $indent__s auto;
            }
        }

        &.active {
            z-index: 3;
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: $color-sna-white;
            padding: $indent__base;
            margin: 0;
            overflow-y: auto;

            .filter-content {
                position: relative;
                display: block;

                .filter-current {
                    display: none;
                }

                .filter-actions {
                    position: absolute;
                    right: 0;
                    top: 0;
                    text-align: right;
                    margin: 0;

                    .close-filter-options {
                        display: inline-block;
                        color: $color-sna-black;
                    }
                    .action.clear {
                        margin-top: $indent__m;
                        padding: $indent__xs;
                        display: block;
                    }
                }
                .filter-options-text {
                    border: none;
                    display: inline-block;
                    text-align: left;
                    padding: $indent__base 0;
                    margin-top: $indent__l;
                }

                .container-filter-options {
                    display: block;
                }
            }
        }
    }

    @include max-screen($screen__xs-2) {
        .sidebar .block.filter .filter-current,
        .sidebar .block.filter .block-actions.filter-actions .filter-clear{
            display: none;
        }
    }

    @include max-screen($screen__xs) {
        .block.filter:not(.category) {
            .filter-options-text {
                @include lib-font-size($font-size__base);
            }
        }
    }

    @include max-screen($screen__xxxs) {
        .block.filter:not(.category) {
            .filter-options-text {
                @include lib-font-size($font-size__s);
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .sidebar .block.filter .filter-current,
    .sidebar .block.filter .block-actions.filter-actions,
    .filter-options .filter-options-text {
        display: none;
    }


    .column.main .block.filter {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;

        & > * {
            align-self: center;
        }

        .filter-actions {
            margin: 0 0 $indent__s auto;
        }
    }
    .filter-options {
        .filter-options-item {
             .filter-options-content {
                 display: none;
             }
            &.active {
                .filter-options-content {
                    display: block;
                }
            }
        }
    }
}

