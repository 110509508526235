@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-font-path: "./fonts/" !default;
$slick-font-family: 'proxima-nova' !default; // 'Helvetica Neue LT W05_55 Roman' !default;
$slick-font-weight: 400 !default; // From $font-weight__regular 'Helvetica Neue LT W05_55 Roman' !default;
$slick-loader-path: "../images/" !default;
$slick-arrow-color: white !default;
$slick-dot-color: #9b9b9b !default;
$slick-dot-color-active: #292827 !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 1 !default;
$slick-opacity-on-hover: 0.75 !default;
$slick-opacity-not-active: 0 !default;

@function slick-image-url($url) {
    @if function-exists(image-url) {
        @return image-url($url);
    }
    @else {
        @return url($slick-loader-path + $url);
    }
}

@function slick-font-url($url) {
    @if function-exists(font-url) {
        @return font-url($url);
    }
    @else {
        @return url($slick-font-path + $url);
    }
}

/* Slider */

.slick-list {
    .slick-loading & {
        background: #fff slick-image-url("../images/ajax-loader.gif") center center no-repeat;
    }
}

/* Icons */
@if $slick-font-family == "slick" {
    @font-face {
        font-family: "slick";
        src: slick-font-url("slick.eot");
        src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
        font-weight: normal;
        font-style: normal;
    }
}

/* Arrows */

.slick-prev,
.slick-next {
    position: absolute;
    display: block;
    height: 48px;
    width: 48px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none;
    z-index: 1;
    &:hover, &:focus {
        outline: none;
        background: transparent;
        color: transparent;
        border: 0;
        &:before {
            opacity: $slick-opacity-on-hover;
        }
    }
    &.slick-disabled:before {
        opacity: $slick-opacity-not-active;
    }
    &:before {
        color: $slick-arrow-color;
        opacity: $slick-opacity-default;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        width: 48px;
        height: 48px;
        display: block;
    }
}

.slick-prev {
    left: -25px;
    [dir="rtl"] & {
        left: auto;
        right: -25px;
    }
    &:before {
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 48 48' style='enable-background:new 0 0 48 48;' xml:space='preserve'%3E%3Cg id='icon-flechaslider-izquierda-bahco'%3E%3Ccircle id='Oval-Copy' class='st0' fill='none' stroke='%23000000' cx='24' cy='24' r='23.5'/%3E%3Cpath id='Path' class='st1' d='M11.31,23.92c1.29-1.28,2.57-2.55,3.86-3.83c0.65-0.64,1.29-1.28,1.95-1.91 c0.11-0.11,0.25-0.17,0.41-0.18c0.15,0,0.3,0.06,0.41,0.17c0.23,0.22,0.23,0.59,0.01,0.82c-0.05,0.06-0.1,0.11-0.16,0.16 l-4.54,4.51c-0.06,0.06-0.12,0.12-0.21,0.23h23.09c0.14,0,0.27,0.01,0.4,0.03c0.27,0.05,0.46,0.28,0.46,0.55 c0,0.27-0.19,0.5-0.45,0.55c-0.13,0.03-0.27,0.04-0.41,0.03c-7.57,0-15.15,0-22.72,0h-0.37c0.1,0.11,0.16,0.18,0.22,0.24 c1.5,1.49,2.99,2.98,4.49,4.46c0.09,0.09,0.18,0.18,0.25,0.28c0.18,0.24,0.13,0.58-0.1,0.77c-0.26,0.22-0.58,0.18-0.88-0.11 c-1.91-1.89-3.81-3.78-5.71-5.67C10.9,24.61,10.9,24.33,11.31,23.92z'/%3E%3C/g%3E%3C/svg%3E");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        [dir="rtl"] & {
            content: '';
            background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 48 48' style='enable-background:new 0 0 48 48;' xml:space='preserve'%3E%3Cg id='icon-flechaslider-izquierda-bahco'%3E%3Ccircle id='Oval-Copy' class='st0' fill='none' stroke='%23000000' cx='24' cy='24' r='23.5'/%3E%3Cpath id='Path' class='st1' d='M11.31,23.92c1.29-1.28,2.57-2.55,3.86-3.83c0.65-0.64,1.29-1.28,1.95-1.91 c0.11-0.11,0.25-0.17,0.41-0.18c0.15,0,0.3,0.06,0.41,0.17c0.23,0.22,0.23,0.59,0.01,0.82c-0.05,0.06-0.1,0.11-0.16,0.16 l-4.54,4.51c-0.06,0.06-0.12,0.12-0.21,0.23h23.09c0.14,0,0.27,0.01,0.4,0.03c0.27,0.05,0.46,0.28,0.46,0.55 c0,0.27-0.19,0.5-0.45,0.55c-0.13,0.03-0.27,0.04-0.41,0.03c-7.57,0-15.15,0-22.72,0h-0.37c0.1,0.11,0.16,0.18,0.22,0.24 c1.5,1.49,2.99,2.98,4.49,4.46c0.09,0.09,0.18,0.18,0.25,0.28c0.18,0.24,0.13,0.58-0.1,0.77c-0.26,0.22-0.58,0.18-0.88-0.11 c-1.91-1.89-3.81-3.78-5.71-5.67C10.9,24.61,10.9,24.33,11.31,23.92z'/%3E%3C/g%3E%3C/svg%3E");
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }
}

.slick-next {
    right: -25px;
    [dir="rtl"] & {
        left: -25px;
        right: auto;
    }
    &:before {
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 48 48' style='enable-background:new 0 0 48 48;' xml:space='preserve'%3E%3Cg id='icon-flechaslider-derecha-bahco'%3E%3Ccircle id='Oval' fill='none' stroke='%23000000' class='st0' cx='24' cy='24' r='23.5'/%3E%3Cpath id='Path' class='st1' d='M36.68,23.92c-1.29-1.28-2.57-2.55-3.86-3.83c-0.65-0.64-1.29-1.28-1.95-1.91 C30.77,18.07,30.62,18,30.47,18c-0.15,0-0.3,0.06-0.41,0.17c-0.23,0.22-0.23,0.59-0.01,0.82c0.05,0.06,0.1,0.11,0.16,0.16 l4.54,4.51c0.06,0.06,0.12,0.12,0.21,0.23H11.87c-0.14,0-0.27,0.01-0.4,0.03C11.2,23.98,11,24.21,11,24.48 c0,0.27,0.19,0.5,0.45,0.55c0.13,0.03,0.27,0.04,0.41,0.03c7.57,0,15.15,0,22.72,0h0.37c-0.1,0.11-0.16,0.18-0.22,0.24 c-1.5,1.49-2.99,2.98-4.49,4.46c-0.09,0.09-0.18,0.18-0.25,0.28c-0.18,0.24-0.13,0.58,0.1,0.77c0.26,0.22,0.58,0.18,0.88-0.11 c1.91-1.89,3.81-3.78,5.71-5.67C37.1,24.62,37.1,24.33,36.68,23.92z'/%3E%3C/g%3E%3C/svg%3E");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        [dir="rtl"] & {
            content: '';
            background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 48 48' style='enable-background:new 0 0 48 48;' xml:space='preserve'%3E%3Cg id='icon-flechaslider-derecha-bahco'%3E%3Ccircle id='Oval' fill='none' stroke='%23000000' class='st0' cx='24' cy='24' r='23.5'/%3E%3Cpath id='Path' class='st1' d='M36.68,23.92c-1.29-1.28-2.57-2.55-3.86-3.83c-0.65-0.64-1.29-1.28-1.95-1.91 C30.77,18.07,30.62,18,30.47,18c-0.15,0-0.3,0.06-0.41,0.17c-0.23,0.22-0.23,0.59-0.01,0.82c0.05,0.06,0.1,0.11,0.16,0.16 l4.54,4.51c0.06,0.06,0.12,0.12,0.21,0.23H11.87c-0.14,0-0.27,0.01-0.4,0.03C11.2,23.98,11,24.21,11,24.48 c0,0.27,0.19,0.5,0.45,0.55c0.13,0.03,0.27,0.04,0.41,0.03c7.57,0,15.15,0,22.72,0h0.37c-0.1,0.11-0.16,0.18-0.22,0.24 c-1.5,1.49-2.99,2.98-4.49,4.46c-0.09,0.09-0.18,0.18-0.25,0.28c-0.18,0.24-0.13,0.58,0.1,0.77c0.26,0.22,0.58,0.18,0.88-0.11 c1.91-1.89,3.81-3.78,5.71-5.67C37.1,24.62,37.1,24.33,36.68,23.92z'/%3E%3C/g%3E%3C/svg%3E");
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }
}

/* Dots */

.slick-dotted.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    position: absolute;
    bottom: -25px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin-bottom: .6em;
    width: 100%;
    li {
        position: relative;
        display: inline-block;
        height: 20px;
        width: 20px;
        margin: 0 5px;
        padding: 0;

        > button {
            border: 0;
            background: transparent;
            display: block;
            color: transparent;
            cursor: pointer;
            width: 100%;
            height: 100%;
            position: relative;
            outline: none;
            line-height: 0;
            font-size: 0;
            padding: 5px;

            &::before {
                content: '';
                display: block;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-color: $slick-dot-color;
                border-radius: 50%;
                opacity: inherit;
            }
        }

        &.slick-active button:before {
            background-color: $slick-dot-color-active;
        }
    }
}
//
//  Mobile
//  _____________________________________________

@media (max-width: 768px) {
    .slick-prev {
        left: -10px;
        [dir="rtl"] & {
            right: -10px;
        }
    }

    .slick-next {
        right: -10px;
        [dir="rtl"] & {
            left: -10px;
        }
    }
}

