//
//  Typography variables
//  _____________________________________________

//
//  Fonts
//  ---------------------------------------------

//  Path
$icons__font-path               : "../fonts/Blank-Theme-Icons/Blank-Theme-Icons" !default;

//  Names
$icons__font-name               : 'icons-blank-theme' !default; // ToDo UI                                         : we need to rename (it shouldn't use blank theme name) or move icon fonts to blank theme

//  Font families
$font-family__base__sna         : 'proxima-nova';

$font-family__sans-serif        : sans-serif !default;
$font-family__serif             : Georgia, 'Times New Roman', Times, serif !default;
$font-family__monospace         : Menlo, Monaco, Consolas, 'Courier New', monospace !default;

$font-family__base              : $font-family__base__sna, $font-family__sans-serif !default;

//  Sizes
$root__font-size                : 62.5% !default; // Defines ratio between root font size and base font size, 1rem = 10px
$font-size-ratio__base          : 1.6 !default; // Defines ratio of the root font-size to the base font-size

$font-size-unit                 : rem !default; // The unit to which most typography values will be converted by default
$font-size-unit-ratio           : $root__font-size * 16 / 100 / 1% !default; // Ratio of the root font-size to the font-size unit
$font-size-unit-convert         : true !default; // Controls whether font-size values are converted to the specified font-size unit


$font-size__48px                : 48px !default;
$font-size__38px                : 38px !default;
$font-size__32px                : 32px !default;
$font-size__30px                : 30px !default;
$font-size__28px                : 28px !default;
$font-size__24px                : 24px !default;
$font-size__22px                : 22px !default;
$font-size__20px                : 20px !default;
$font-size__18px                : 18px !default;
$font-size__16px                : 16px !default;
$font-size__14px                : 14px !default;
$font-size__13px                : 13px !default;
$font-size__10px                : 10px !default;

$font-size__base                : $font-size-unit-ratio * $font-size-ratio__base * 1px !default; // Base font size value in px
$font-size__xxxxl               : ceil(3.75 * $font-size__base) !default; // 60px
$font-size__xxxl                : ceil(2.5 * $font-size__base) !default; // 40px
$font-size__xxl                 : ceil(1.87 * $font-size__base) !default; // 30px
$font-size__xl                  : ceil(1.56 * $font-size__base) !default; // 25px
$font-size__l                   : ceil(1.25 * $font-size__base) !default; // 20px
$font-size__s                   : ceil(0.81 * $font-size__base) !default; // 13px
$font-size__xs                  : floor(0.625 * $font-size__base) !default; // 10px

//  Weights
$font-weight__hairline          : 100 !default;
$font-weight__light             : 300 !default;
$font-weight__regular           : 400 !default;
$font-weight__heavier           : 500 !default;
$font-weight__semibold          : 600 !default;
$font-weight__bold              : 700 !default;

//  Styles
$font-style__base               : normal !default;
$font-style__emphasis           : italic !default;

//  Line heights
$line-height__1-67              : 1.67 !default;
$line-height__1-5               : 1.5 !default;
$line-height__1-1               : 1.1 !default;
$line-height__normal            : normal !default;

$line-height__base              : 1.428571429 !default;
$line-height__computed          : floor($font-size__base * $line-height__base) !default;
$line-height__xl                : 1.7 !default;
$line-height__l                 : $line-height__1-5;
$line-height__s                 : 1.33 !default;

//  Colors
$text__color                    : $color-sna-black !default;
$text__color__intense           : darken($color-sna-primario2, 13.5%) !default;
$text__color__muted             : $color-sna-primario2 !default;

//
//  Indents
//  ---------------------------------------------
$indent__xxxxl    : 64px !default;
$indent__xxxl     : 56px !default;
$indent__xxl      : 48px !default;
$indent__xl       : 40px !default;
$indent__l        : 32px !default;
$indent__m        : 24px !default;
$indent__base     : 16px !default;
$indent__s        : 8px !default;
$indent__xs       : 4px !default;
$indent__auto     : auto !default;

//
//  Borders
//  ---------------------------------------------

$border-color__base             : darken($page__background-color, 18%) !default;
$border-width__base             : 1px !default;

//
//  Links
//  ---------------------------------------------

$link__color                    : $color-sna-primario1 !default;
$link__text-decoration          : none !default;

$link__visited__color           : $link__color !default;
$link__visited__text-decoration : none !default;

$link__hover__color             : $color-sna-secundario1 !default;
$link__hover__text-decoration   : underline !default;

$link__active__color            : $link__hover__color !default;
$link__active__text-decoration  : underline !default;

/* Secondary links */
$link__secondary__color                    : $color-sna-primario2;
$link__secondary__font-style               : normal;
$link__secondary__font-weight              : $font-weight__regular;
$link__secondary__letter-spacing           : normal; // 1.2px
$link__secondary__text-decoration          : none;
$link__secondary__text-transform           : none; //uppercase;

$link__visited__secondary__color           : $link__secondary__color;
$link__visited__secondary__text-decoration : $link__secondary__text-decoration;

$link__hover__secondary__color             : $color-sna-secundario2;
$link__hover__secondary__text-decoration   : $link__secondary__text-decoration;

$link__active__secondary__color            : $link__secondary__color;
$link__active__secondary__text-decoration  : $link__secondary__text-decoration;

/* Light links */
$link__light__color                    : $color-sna-white;
$link__light__font-style               : normal;
$link__light__font-weight              : $font-weight__bold;
$link__light__letter-spacing           : normal; // 1.2px
$link__light__text-decoration          : none;
$link__light__text-transform           : none; //uppercase;

$link__visited__light__color           : $link__light__color;
$link__visited__light__text-decoration : $link__light__text-decoration;

$link__hover__light__color             : $color__primary--light;
$link__hover__light__text-decoration   : $link__light__text-decoration;

$link__active__light__color            : $link__light__color;
$link__active__light__text-decoration  : $link__light__text-decoration;

//
//  Focus
//  ---------------------------------------------

$focus__color                   : $color__primary--light !default;
$focus__box-shadow              : false;

//
//  Lists
//  ---------------------------------------------

$list__color__base              : false !default;
$list__font-size__base          : false !default;
$list__margin-top               : 0 !default;
$list__margin-bottom            : $indent__m !default;

$list-item__margin-top          : 0 !default;
$list-item__margin-bottom       : $indent__s !default;

$dl__margin-top                 : 0 !default;
$dl__margin-bottom              : $indent__base !default;

$dt__margin-top                 : 0 !default;
$dt__margin-bottom              : $indent__xs !default;
$dt__font-weight                : $font-weight__bold !default;

$dd__margin-top                 : 0 !default;
$dd__margin-bottom              : $indent__s !default;

//
//  Paragraphs
//  ---------------------------------------------

$p__margin-top                  : 0 !default;
$p__margin-bottom               : $indent__s !default;

//
//  Headings
//  ---------------------------------------------

$heading__color__base           : false !default;
$heading__font-family__base     : false !default;
$heading__font-weight__base     : $font-weight__bold !default;
$heading__font-style__base      : false !default;
$heading__line-height__base     : 1.1 !default;
$heading__margin-top__base      : $indent__base !default;
$heading__margin-bottom__base   : $indent__base !default;
$heading__text-transform__base  : false !default;

$h1__font-size                  : $font-size__xl !default; // 25px mobile first
$h1__font-size-screen__s        : $font-size__base; // 16x tablet
$h1__font-size-screen__xs       : $font-size__s; // 13px móvil
$h1__font-color                 : $heading__color__base !default;
$h1__font-family                : $heading__font-family__base !default;
$h1__font-weight                : $heading__font-weight__base !default;
$h1__font-style                 : $heading__font-style__base !default;
$h1__line-height                : 1.67 !default;
$h1__margin-top                 : 0 !default;
$h1__margin-bottom              : $heading__margin-bottom__base !default;
$h1__text-transform             : $heading__text-transform__base !default;

$h2__font-size                  : $font-size__l !default; // 20px mobile first
$h2__font-color                 : $heading__color__base !default;
$h2__font-family                : $heading__font-family__base !default;
$h2__font-weight                : $heading__font-weight__base !default;
$h2__font-style                 : $heading__font-style__base !default;
$h2__line-height                : $heading__line-height__base !default;
$h2__margin-top                 : $indent__m !default;
$h2__margin-bottom              : $heading__margin-bottom__base !default;
$h2__text-transform             : uppercase !default;

$h3__font-size                  : $font-size__xl !default; // 25px
$h3__font-color                 : $heading__color__base !default;
$h3__font-family                : $heading__font-family__base !default;
$h3__font-weight                : $heading__font-weight__base !default;
$h3__font-style                 : $heading__font-style__base !default;
$h3__line-height                : $heading__line-height__base !default;
$h3__margin-top                 : $indent__base * 0.75 !default;
$h3__margin-bottom              : $indent__s !default;
$h3__text-transform             : uppercase !default;

$h4__font-size                  : $font-size__base !default; // 16px
$h4__font-color                 : $heading__color__base !default;
$h4__font-family                : $heading__font-family__base !default;
$h4__font-weight                : $font-weight__bold !default;
$h4__font-style                 : $heading__font-style__base !default;
$h4__line-height                : $heading__line-height__base !default;
$h4__margin-top                 : $heading__margin-top__base !default;
$h4__margin-bottom              : $heading__margin-bottom__base !default;
$h4__text-transform             : $heading__text-transform__base !default;

$h5__font-size                  : $font-size__s !default; // 12px
$h5__font-color                 : $heading__color__base !default;
$h5__font-family                : $heading__font-family__base !default;
$h5__font-weight                : $font-weight__bold !default;
$h5__font-style                 : $heading__font-style__base !default;
$h5__line-height                : $heading__line-height__base !default;
$h5__margin-top                 : $heading__margin-top__base !default;
$h5__margin-bottom              : $heading__margin-bottom__base !default;
$h5__text-transform             : $heading__text-transform__base !default;

$h6__font-size                  : $font-size__xs !default; // 10px
$h6__font-color                 : $heading__color__base !default;
$h6__font-family                : $heading__font-family__base !default;
$h6__font-weight                : $font-weight__bold !default;
$h6__font-style                 : $heading__font-style__base !default;
$h6__line-height                : $heading__line-height__base !default;
$h6__margin-top                 : $heading__margin-top__base !default;
$h6__margin-bottom              : $heading__margin-bottom__base !default;
$h6__text-transform             : $heading__text-transform__base !default;

$heading__small-color           : $primary__color !default;
$heading__small-line-height     : 1 !default;
$heading__small-size            : ($font-size__xs / $font-size__base) * 100% !default;

//
//  Titles
//  ---------------------------------------------

$text__font-family__base     : $font-family__base !default;
$text__font-weight__base     : $font-weight__hairline !default;
$text__color__base           : false !default;
$text__font-style__base      : false !default;
$text__margin-top__base      : $indent__s;
$text__margin-bottom__base   : $indent__s;


$title-big__font-size                  : $font-size__xxxl; // 40px
$title-big__font-size-screen__s        : $font-size__xxl; // 30x tablet
$title-big__font-size-screen__xs       : $font-size__xl; // 25px móvil
$title-big__font-color                 : $color-sna-primario2;
$title-big__font-family                : $text__font-family__base !default;
$title-big__font-weight                : $text__font-weight__base !default;
$title-big__font-style                 : $text__font-style__base !default;
$title-big__line-height                : normal;
$title-big__line-height-screen__s      : normal; // tablet
$title-big__line-height-screen__xs     : normal; // móvil
$title-big__text-transform             : uppercase !default;
$title-big__margin-top                 : 0 !default;
$title-big__margin-bottom              : $text__margin-bottom__base !default;

$title-normal__font-size                  : $font-size__xxl; // 30px
$title-normal__font-size-screen__s        : $font-size__l; // 20px tablet
$title-normal__font-size-screen__xs       : $font-size__l; // 20px móvil
$title-normal__font-color                 : $color-sna-primario2;
$title-normal__font-family                : $text__font-family__base !default;
$title-normal__font-weight                : $text__font-weight__base !default;
$title-normal__font-style                 : $text__font-style__base !default;
$title-normal__line-height                : normal;
$title-normal__line-height-screen__s      : normal; // tablet
$title-normal__line-height-screen__xs     : normal; // móvil
$title-normal__text-transform             : uppercase !default;
$title-normal__margin-top                 : 0 !default;
$title-normal__margin-bottom              : $text__margin-bottom__base !default;

$title-small__font-size                  : $font-size__l; // 20px
$title-small__font-size-screen__s        : $font-size__base; // 16px tablet
$title-small__font-size-screen__xs       : $font-size__s; // 13px móvil
$title-small__font-color                 : $color-sna-black;
$title-small__font-family                : $text__font-family__base !default;
$title-small__font-weight                : $text__font-weight__base !default;
$title-small__font-style                 : $text__font-style__base !default;
$title-small__line-height                : normal;
$title-small__line-height-screen__s      : normal; // tablet
$title-small__line-height-screen__xs     : normal; // móvil
$title-small__text-transform             : uppercase !default;
$title-small__margin-top                 : 0 !default;
$title-small__margin-bottom              : $indent__m !default;


//  Code blocks
$code__background-color         : $panel__background-color !default;
$code__color                    : $primary__color__darker !default;
$code__font-size                : $font-size__s !default;
$code__padding                  : 2px 4px !default;

$pre__background-color          : $panel__background-color !default;
$pre__border-color              : $border-color__base !default;
$pre__border-width              : $border-width__base !default;
$pre__color                     : $primary__color__darker !default;

$kbd__background-color          : $panel__background-color !default;
$kbd__color                     : $primary__color__darker !default;

//  Blockquote
$blockquote__border-color       : $border-color__base !default;
$blockquote__border-width       : 0 !default;
$blockquote__content-before     : '\2014 \00A0' !default;
$blockquote__font-size          : $font-size__base !default;
$blockquote__font-style         : $font-style__emphasis !default;
$blockquote__margin             : 0 0 $indent__base $indent__xl !default;
$blockquote__padding            : 0 !default;

$blockquote-small__color        : $primary__color !default;
$blockquote-small__font-size    : $font-size__xs !default;

$cite__font-style               : $font-style__base !default;

//  Misc
$hr__border-color               : $border-color__base !default;
$hr__border-style               : solid !default;
$hr__border-width               : $border-width__base !default;

$mark__color                    : $primary__color__dark !default;
$mark__background-color         : $panel__background-color !default;

$abbr__border-color             : $border-color__base !default;

//  Disable filters output in css
$disable-filters                : false !default;


$font-styles: (
    'display-1': (
        font-family: $font-family__base,
        font-style: $font-style__base,
        font-weight: $font-weight__bold,
        font-size: $font-size__30px,
        line-height: $line-height__normal,
        text-transform: uppercase,
        'phone-375':(
            font-size: linear-fluid-value($font-size__30px, $font-size__38px, $screen__375, $screen__550),
        ),
        'tablet':(
            font-size: linear-fluid-value($font-size__38px, $font-size__48px, $screen__550, $screen__1920),
        ),
        'desktop-1920':(
            font-size: $font-size__48px,
        ),
    ),
    'display-2': (
        font-family: $font-family__base,
        font-style: $font-style__base,
        font-weight: $font-weight__bold,
        font-size: $font-size__22px,
        line-height: $line-height__normal,
        text-transform: uppercase,
        'phone-375':(
            font-size: linear-fluid-value($font-size__22px, $font-size__28px, $screen__375, $screen__550),
        ),
        'tablet':(
            font-size: linear-fluid-value($font-size__28px, $font-size__32px, $screen__550, $screen__1920),
        ),
        'desktop-1920':(
            font-size: $font-size__32px,
        ),
    ),
    'heading-h1': (
        font-family: $font-family__base,
        font-style: $font-style__base,
        font-weight: $font-weight__bold,
        font-size: $font-size__22px,
        line-height: $line-height__1-67,
        'phone-375':(
            font-size: linear-fluid-value($font-size__22px, $font-size__24px, $screen__375, $screen__550),
        ),
        'tablet':(
            font-size: linear-fluid-value($font-size__24px, $font-size__28px, $screen__550, $screen__1920),
        ),
        'desktop-1920':(
            font-size: $font-size__28px,
        ),
    ),
    'heading-h2': (
        font-family: $font-family__base,
        font-style: $font-style__base,
        font-weight: $font-weight__bold,
        font-size: $font-size__20px,
        line-height: $line-height__1-1,
        'phone-375':(
            font-size: linear-fluid-value($font-size__20px, $font-size__24px, $screen__375, $screen__1920),
        ),
        'desktop-1920':(
            font-size: $font-size__24px,
        ),
    ),
    'heading-h3': (
        font-family: $font-family__base,
        font-style: $font-style__base,
        font-weight: $font-weight__bold,
        font-size: $font-size__18px,
        line-height: $line-height__1-1,
        'phone-375':(
            font-size: linear-fluid-value($font-size__18px, $font-size__20px, $screen__375, $screen__1920),
        ),
        'desktop-1920':(
            font-size: $font-size__20px,
        ),
    ),
    'label-1': (
        font-family: $font-family__base,
        font-style: $font-style__base,
        font-weight: $font-weight__bold,
        font-size: $font-size__16px,
        line-height: $line-height__1-1,
        'phone-375':(
            font-size: linear-fluid-value($font-size__16px, $font-size__18px, $screen__375, $screen__1920),
        ),
        'desktop-1920':(
            font-size: $font-size__18px,
        ),
    ),
    'label-2': (
        font-family: $font-family__base,
        font-style: $font-style__base,
        font-weight: $font-weight__bold,
        font-size: $font-size__14px,
        line-height: $line-height__1-1,
    ),
    'label-3': (
        font-family: $font-family__base,
        font-style: $font-style__base,
        font-weight: $font-weight__bold,
        font-size: $font-size__10px,
        line-height: $line-height__1-1,
    ),
    'body-1': (
        font-family: $font-family__base,
        font-style: $font-style__base,
        font-weight: $font-weight__regular,
        font-size: $font-size__16px,
        line-height: $line-height__1-5,
    ),
    'body-2': (
        font-family: $font-family__base,
        font-style: $font-style__base,
        font-weight: $font-weight__regular,
        font-size: $font-size__13px,
        line-height: $line-height__normal,
    ),
);
