.mn-submenu {
    position: absolute;
    background-color: $color-sna-white;
    padding: $indent__s $indent__l 0;
    transition: transform 0.3s;
    z-index: 1;

    @include on4menu-mobile {
        @include lib-css(box-shadow, $box-shadow-sna3);
        top: 0 !important; /* Don't use style="top: ..." setted via JS (it's only for desktop) */
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(100%);
        /*max-height: none !important; /* Don't use style="max-height: ..." setted via JS (it's only for desktop) */
        overflow-x: hidden;

        &.is-expanded {
            transform: translateX(0);

            .mn-submenu__header {
                border-bottom: 1px solid $color-sna-primario4;
            }
            .mn-category-button__arrow--flechaizda {
                display: inline-block;
            }
        }
    }

    @include on4menu-desktop {
        display: flex;
        flex-wrap: wrap;
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        padding: $indent__l;
        overflow-y: auto;
        transform-origin: top;
        transform: scaleY(0);
        transition: transform 0.2s 0.2s;
        box-shadow: $box-shadow-sna1, $box-shadow-sna3;

        > * {
            opacity: 0;
            transition: opacity 0.2s;
        }
        img {
            display: none;
        }

        &.is-expanded {
            transform: scaleY(1);
            transition: transform 0.2s;

            > * {
                opacity: 1;
                transition: opacity 0.2s 0.2s;
            }
            img {
                display: block;
            }
        }
    }
}

.mn-submenu__header {
    display: flex;
    flex-direction: column;
    @include on4menu-mobile {
        justify-content: space-between;
    }
}
.mn-submenu__content {
    width: 100%;
}

.mn-submenu__items {
    margin: 0;
    padding: 0;

    @include on4menu-desktop {
        display: flex;
    }
}

.mn-submenu__item {
    @include on4menu-mobile {
        margin-bottom: 0;
    }
}

// Columns
// ----------------------------------------------------------------------------
.mn-submenu__content {
    @include on4menu-desktop-column-container();
}
