//
// Default Banner
// ----------------------------------------------------------------------------
.mn-banner {
    @include on4menu-mobile-products-menu-item();
    @include lib-button-as-link();
    @include lib-link-secondary(
        $_link-font-weight: $font-weight__heavier
    );
    text-align: left;
    &__figure {
        position: relative;
        margin-right: $indent__s;
    }
    &__img {
    }
    .arrow {
        display: none;
    }
}

@include on4menu-mobile {
    .mn-banner {
        text-transform: none;

        &__figure {
            width: 52px;
            height: 40px;
            overflow: hidden;
        }

        &__img {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            display: block;
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
            box-sizing: border-box;
            margin: auto;
        }
        &.banner-link {
            display: none;
        }
    }
}

@include on4menu-desktop {
    .mn-banner {
        display: flex;
        align-items: center;
        padding: $indent__s;
        width: 100%;
        height: 100%;

        &:hover {
            @include lib-css(box-shadow, $box-shadow-sna1);
        }
    }
    button.mn-banner,
    button.mn-banner:hover,
    button.mn-banner:active,
    button.mn-banner:focus,
    a.mn-banner.banner-link,
    a.mn-banner.banner-link:hover,
    a.mn-banner.banner-link:active,
    a.mn-banner.banner-link:focus {
        background: $color-sna-primario4;
        border: none;
    }
    a.mn-banner,
    a.mn-banner:hover,
    a.mn-banner:active,
    a.mn-banner:focus {
        background: transparent;
        border: 1px solid $color-sna-primario3;
    }

    .mn-banner__arrow {
        display: none;
    }

    .mn-banner__figure {
        flex: 0 1 71px;
        display: flex;
        align-items: center;
        height: 71px;
    }

    .mn-banner__img {
        flex: 0 1 100%;
    }

    .mn-banner__text {
        flex: 1 1 auto;
        word-wrap: break-word;
        overflow-wrap: break-word;
    }
}
