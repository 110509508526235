.page-layout-text-only {
    
    .page-title-wrapper {
        @include lib-css(margin-bottom, $indent__l);
        border-bottom: 1px solid $color-sna-primario4;

        h1 {
            @include lib-css(margin-bottom, $h1__margin-bottom);
            @include lib-font-size($h1__font-size);
            text-transform: uppercase;
            text-align: left;
            
            @include min-screen($screen__m) {
                @include lib-css(margin-bottom, $h1__tablet__margin-bottom);
                @include lib-font-size($h1__tablet__font-size);
            }
            
            @include min-screen($screen__xl) {
                @include lib-css(margin-bottom, $indent__m);
                /* @include lib-css(margin-bottom, $h1__desktop__margin-bottom); */
                @include lib-font-size($h1__desktop__font-size);
            }
        }
    }
    
    .main {
        @include lib-layout-indent-main();
        
        @include min-screen($screen__xl) {
            @include lib-font-size($font-size__base);
        }

        * {
            @include lib-css(margin-top, 1em);
    
            &:first-child {
                margin-top: 0;
            }
        
        }

        h1, h2, h3 {
            @include lib-css(margin-bottom, $h1__margin-bottom);
            @include lib-font-size($h1__font-size);
            text-transform: uppercase;
            text-align: left;
            
            @include min-screen($screen__m) {
                @include lib-css(margin-bottom, $h1__tablet__margin-bottom);
                @include lib-font-size($h1__tablet__font-size);
            }
            
            @include min-screen($screen__xl) {
                @include lib-css(margin-bottom, $indent__m);
                /* @include lib-css(margin-bottom, $h1__desktop__margin-bottom); */
                @include lib-font-size($h1__desktop__font-size);
            }
        }
    
        h2, h3 {
            @include lib-css(margin-top, $indent__xxxxl);
        }
    
        .subtitle {
            @include lib-layout-indent-main-inverse();
            /* @include lib-css(font-weight, $font-weight__heavier); Font does not supports it */
            @include lib-css(font-weight, $font-weight__bold);
        }
    
        table {
            tr:nth-child(odd) {
                @include lib-css(background-color, $color-sna-primario4);
            }
            
            th, td {
                margin-top: 0;
                @include lib-css(padding, $indent__base);
            }
            
            th {
                @include lib-css(background-color, $color-sna-primario2);
                @include lib-css(color, $color-white);
            }
            
            td {
                * {
                    @include lib-css(margin-bottom, 1em);
                }
                :last-child {
                    margin-bottom: 0;
                }
            }
        }
    
        .cookie-table td:first-child {
            strong {
                display: inline-block;
            }
            ul {
                @extend .abs-reset-list;
                li {
                    @include lib-css(margin-bottom, 1em);
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    
        .two-cols {
            @include lib-layout-two-columns-component();
        }
        
        dl {
            dt {
                @include lib-css(font-weight, $font-weight__bold);
            }
            dd {
                margin-top: 0;
            }
        }
    
        dl.big-dts {
            @extend .faqs;
            dt {
                text-transform: none;
            }
            
            dd {
                ol {
                    padding-left: 0;
                    list-style-position: inside;
                }
                :last-child {
                    margin-bottom: 0;
                }
            }
        }

        dl.one-line {
            dt {
                text-decoration: underline;
                font-weight: inherit;
                float: left;
                clear: left;
                margin-top: 0;
                margin-right: .4em;
            }
            dt::after {
                content: ': ';
                display: inline;
                text-decoration: none;
            }
        }
    
        a {
            color: currentColor;
            text-decoration: underline;
        }

        form {
            * {
                margin-top: 0;
            }

            :not(.single-checkbox) > label {
                display: inline-block;
                @include lib-css(margin-bottom, $indent__s);
                @include lib-css(font-weight, $font-weight__bold);
            }

            label p {
                margin: 0;
            }
    
            input[type="text"],
            input[type="password"],
            input[type="url"],
            input[type="tel"],
            input[type="search"],
            input[type="number"],
            input[type="datetime"],
            input[type="email"],
            select,
            .select-container,
            textarea {
                background-color: $color-white;
                border: 1px solid $color-black;
            }

            .field {
                @include lib-css(margin-bottom, $indent__l);
            }
        }

    }
}
