//
//  Column-divider
//  _____________________________________________

@mixin lib-column-divider($color: $color-black) {
    @include min-screen($screen__m) {
        .pagebuilder-column + .pagebuilder-column {
            border-left: $color 2px solid;
        }
    }
}
