.premium-partners {
    margin-top: $indent__base;
    border-top: 1px solid $color-sna-primario4;
    padding-top: $indent__xl;
    .title {
        @include lib-title(normal);
    }
    .partners-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;
        padding: $indent__l 0;

        @include max-screen($screen__xs) {
            flex-direction: column;
            .item img {
                max-width: 274px;
            }
        }

        @include min-screen($screen__xs) {
            .item {
                flex-basis: 50%;
            }
        }

        @include min-screen($screen__m) {
            .item {
                flex-basis: 33%;
            }
        }

        @include min-screen($screen__m-2) {
            .item img {
                max-width: 274px;
            }
        }

        @include min-screen($screen__l-3) {
            .item {
                flex-basis: 25%;
            }
        }

        .item img {
            padding: $indent__m;
            margin: auto;
            display: block;
            max-height: 107px;
        }

    }
}