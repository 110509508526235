.retailer {
    display: grid;
    align-items: center;
    justify-items: start;
    grid-gap: 2vw;
    grid-template-areas: 
        "logo" 
        "name" 
        "info" 
        "link";
    grid-template-columns: 1fr;

    margin-bottom: 56px;
    padding: 2%;

    @include min-screen($screen__m) {
        grid-template-areas: 
            "logo name"
            "logo info"
            "logo link";
        grid-template-columns: 1fr 3fr;

        &:hover {
            @include lib-css(box-shadow, $box-shadow-sna1);
        }
    }
}

.retailer >
.retailer :last-child {
    margin-bottom: 0;
}

.retailer__name {
    grid-area: name;
    display: block;
    @include lib-font-size($font-size__base);
}

.retailer__logo {
    grid-area: logo;
    align-self: start;

    @include max-screen($screen__m) {
        max-width: 50%;
    }
}

.retailer__info {
    grid-area: info;
    @include lib-font-size($font-size__s);
    margin-bottom: 0;

    ul {
        margin: 0;
        padding: 0;
    }

    li:not([data-on4u-template]) {
        display: inline;
        text-transform: capitalize;

        &::after {
            content: ', ';
            display: inline;
        }

        &:last-child::after {
            content: '.';
        }
    }
}

.retailer__links {
    grid-area: link;
    /* @include abs-visually-hidden();
    
    @include min-screen($screen__m) {
        .retailer:hover &,
        &:focus {
            @include lib-visually-hidden-reset();
        }
    } */
}
