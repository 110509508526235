.media-downloader {
    @include _lib-sna-grid-list();
    @include lib-category-product-list();
    .download {
        @include lib-category-list__item();
        .media-download{
            &__title{
                text-align: center;
                word-wrap: break-word

            }
            &__info{
                @include lib-css(background-color, $color-sna-primario4);
                @include lib-css(padding, 0 $indent__base $indent__base);
                span.description{
                    margin: 16px 0;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: block;
                }
                .action.primary{
                    display: none;
                }
            }
            &__image{
                display: inline-block;
                max-width: 100%;
                width: 100%;
                margin: 0 0 auto;
                padding: 0 16px;
                .image-wrapper{
                    padding-bottom: 80%;
                    display: block;
                    height: 0;
                    overflow: hidden;
                    position: relative;
                    z-index: 1;
                    img{
                        bottom: 0;
                        display: block;
                        width: auto;
                        height: auto;
                        left: 0;
                        margin: auto;
                        max-width: 100%;
                        max-height: 100%;
                        position: absolute;
                        right: 0;
                        top: 0;
                    }
                }
            }
        }
        &:hover{
            @include lib-css(box-shadow, $box-shadow-sna1);
            .media-download {
                &__info {
                    .action.primary{
                        display: block;
                    }
                }
                &__image {
                    .image-wrapper {
                        padding-bottom: calc(80% - 40px);
                    }
                }
            }
        }
    }
}
