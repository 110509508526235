//
//  Account minicart section
//  ---------------------------------------------
@include on4menu-mobile {
    .page-header.fixed {
        .nav-account-minicart {
            display: none; /* Primera fase: quitar link a Mi Cuenta */
        }
    }
}
@include max-screen($screen__xs-2) {
    .page-header {
        .nav-account-minicart {
            display: none; /* Primera fase: quitar link a Mi Cuenta */
        }
    }
}
