//
//  Indents
//  ---------------------------------------------
$indent__xxxxl    : 64px !default;
$indent__xxxl     : 56px !default;
$indent__xxl      : 48px !default;
$indent__xl       : 40px !default;
$indent__l        : 32px !default;
$indent__m        : 24px !default;
$indent__base     : 16px !default;
$indent__s        : 8px !default;
$indent__xs       : 4px !default;
$indent__auto     : auto !default;

$indent__footer-lateral : 6.5% !default;
$indent__ios-bottom     : env(safe-area-inset-bottom);

//
//  Icons
//  ---------------------------------------------
$icons__font-name : 'icons-blank-theme' !default;
