.cms-page-fullpic {
    display: block;
    @include lib-layout-indent-main-inverse();

    @include lib-cms-page-picture-fixed-height();

    > picture > img,
    > img {
        display: block;
    }
}

.cms-page-fullpic--mv-medium {
    @include max-screen($landing-section-bg-img-fixed-height__mediaquery) {
        height: $landing-section-fixed-height__m;
    }
}

.cms-page-fullpic.has-bg-img {
    position: relative;
    
    .cms-page-fullpic__front {
        position: absolute;
        top: 10%;
        bottom: 10%;
        right: 0;
        left: 0;
        margin: 0 auto;
        transform: none;
        max-width: 80%;
        max-height: 80%;
    }
}