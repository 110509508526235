.registered-products {
    margin-top: $indent__base;
    margin-bottom: $indent__base;

    @include screen($screen__m, $screen__m-2) {
        @include lib-font-size($font-size__s);

        .download-link {
            &__text {
                @include lib-visually-hidden();
            }
        }
    }

    .col {
        vertical-align: middle;
    }

    .download-link {
        @include lib-link-secondary();

        display: inline-flex;
        align-items: center;
        vertical-align: middle;

        &__icon {
            margin: $indent__s;
        }
    }
}

.product-registration-top-banner {
    margin-bottom: $indent__base;
}

.product-registration-bottom-banner {
    margin-top: $indent__base;
    margin-bottom: $indent__base;
}

.product-registration-form {
    .search-autocomplete {
        .autocomplete-list {
            background-color: $color-sna-primario4;
            border-right: 1px solid $color-sna-primario1;
            border-bottom: 1px solid $color-sna-primario1;
            border-left: 1px solid $color-sna-primario1;
            padding: $input-text__padding;
            margin-bottom: 0;
            max-height: 300px;
            overflow-y: auto;
        }

        dt {
            @include lib-font-size($input-text__font-size);
            font-weight: $input-text__font-weight;
            padding: $indent__s 0;
            cursor: pointer;

            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }
    }

    .fieldset[data-hasrequired] {
        @include lib-form-hasrequired(bottom);
        &:after {
            margin-top: $indent__m;
        }
    }

    .field-proof_of_purchase .control::after {
        content: attr(data-note);
        display: block;
        margin-top: $indent__s;
    }

    @include min-screen($screen__m) {
        .field.choice .mage-error,
        .fieldset[data-hasrequired]:after {
            margin-left: $form-field-type-label-inline__width;
        }
    }

    .action.file {
        @include lib-visually-hidden();
    }

    .file-label {
        @include lib-button-secondary();
    }
}
