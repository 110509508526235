.amlocator-index-index {
    overflow-x: unset !important;
}

.amlocator-index-index .page-main > .page-title-wrapper .page-title {
    margin-top: .6em;
}

.amlocator-main-container.amlocator-main-container.amlocator-main-container.amlocator-main-container {
    /**
       *  Base: forms
       * ------------------------------------------------------------------------- */
    .amlocator-attribute-wrapper .amlocator-select {
        padding: inherit;
        height: auto;
        max-height: none;
        display: none;
    }

    /**
       *  Base: buttons
       * ------------------------------------------------------------------------- */

    .amlocator-button,
    .amlocator-button.-nearby {
        height: auto;
        border-radius: inherit;
        box-shadow: none;
        @include lib-button();

        &:hover {
            background-color: $color-sna-primario3;
            color: $color-sna-white;
            border-color: $color-sna-primario3;
        }
    }

    .amlocator-button.-nearby {
        margin-top: $indent__s;
    }

    .amlocator-button,
    .amlocator-button-nearby.btn-special {
        min-width: $button-primary-small__min-width;
        padding: $button-primary-small__padding;

        @include min-screen($screen__xxs) {
            min-width: $button-primary__min-width;
            padding: $button-primary__padding;
        }
    }
}

.amlocator-left-wrapper {
    max-height: inherit;
    display: flex;
    flex-direction: column;
    height: 100%;

    .amlocator-wrapper{
        overflow-y: auto;
    }
}

/**
 *  Filters
 * ------------------------------------------------------------------------- */
.amlocator-main-container .amlocator-range-slider {
    height: 3px;
    background-color: $color-sna-primario3;
    .ui-slider-range {
        background-color: $color-sna-primario1;
    }
    .amlocator-tooltip,
    .amlocator-tooltip::after {
        background-color: $color-sna-primario1;
    }
    .amlocator-handle {
        background-color: $color-sna-white;
        width: 18px;
        height: 18px;
        &:hover,
        &:focus,
        &:active {
            background-color: $color-sna-white;
        }
    }
}

.amlocator-main-container .amlocator-search-container {
    background-color: $color-sna-primario4;
    padding: $indent__base;
    margin-bottom: 1px;

    .amlocator-block.amlocator-block {
        margin-bottom: 0;

        .amlocator-title {
            margin-bottom: $indent__base;
        }
    }

    .amlocator-search-radius {
        font-size: $font-size__base;
    }

    .amlocator-current-location {
        .amlocator-text:focus {
            border-color: $color-sna-primario1;
            background-color: white;
            box-shadow: $box-shadow-sna1;
        }
        .amlocator-search {
            background: none !important;
            top: 50%;
            transform: translateY(-50%);
        }
        .amlocator-text {
            max-width: 100%;
            border: 1px solid $color-sna-black;
            height: $form-element-input__height;
        }
    }

    .amlocator-button-nearby {
        width: 100%;
    }

    .amlocator-quick-alert {
        margin-top: $indent__s;
        @include lib-form-validation-note();
    }

    @include max-screen($screen__m) {
        .amlocator-search-radius {
            text-align: center;

            .amlocator-title {
                margin-top: $indent__s;
                margin-bottom: $indent__l;
            }
        }
    }

    @include min-screen($screen__m) {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;

        .amlocator-current-location {
            flex: 0 1 40%;
            .amlocator-wrapper {
                margin-top: 0;
            }
        }
        .amlocator-button-nearby {
            width: auto;
            margin: $indent__base auto 0;
        }
        .amlocator-search-radius {
            flex: 0 1 40%;
            margin: 0 auto;
        }
    }

    @include min-screen($screen__m-2) {
        .amlocator-current-location {
            flex: 0 1 33%;
        }
        .amlocator-search-radius {
            flex: 0 1 33%;
        }
        .amlocator-button-nearby {
            margin: 0;
        }
    }
}

.amlocator-main-container .main-map .amlocator-filters-container {
    background-color: $color-sna-primario4;
    margin-bottom: $indent__s;

    .amlocator-title {
        text-transform: uppercase;
    }

    .amlocator-attribute-wrapper {
        display: block;
        padding: 0;
        margin: 0 $indent__base $indent__base;
        .amlocator-label {
            display: block;
            margin: 0 0 $indent__s;
        }
        .amlocator-input {
            width: 100%;

            &.multicheckbox {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;

                label {
                    align-items: center;
                    padding: $indent__s $indent__base;
                    margin: $indent__s;
                    border-bottom: 3px solid transparent;

                    svg {
                        margin-right: $indent__s;
                    }

                    &:before {
                        content: none;
                    }
                }

                label:hover,
                [type="checkbox"]:checked ~ label {
                    color: $color-sna-primario1;
                    border-bottom: 3px solid $color-sna-primario1;
                }
            }
        }
    }

    .chosen-container {
        @include lib-font-size($font-size__s);
    }

    .chosen-container {
        width: 100% !important;


        .chosen-choices li.search-field input[type="text"] {
            font-size: inherit;
        }

        .chosen-choices li.search-choice {
            background-color: $color-sna-primario2;
            color: $color-sna-white;
        }
        .chosen-results li.active-result.highlighted {
            background-color: $color-sna-primario1;
        }
    }

    .amlocator-clear {
        @include lib-link();
        @include lib-font-size($font-size__s);
        font-weight: $font-weight__bold;
        margin-left: $indent__base;
        order: 1;
    }

    @include max-screen($screen__m) {
        .amlocator-actions {
            flex-direction: column;

            .filter-attribute {
                width: 100%;
            }
            .amlocator-clear {
                margin: $indent__s 0 0;
                padding: $indent__s;
            }
        }
    }

    @include min-screen($screen__l-3) {
        .amlocator-content {
            display: flex;

            #attribute-form {
                flex-grow: 1;
            }
        }
    }
}

.amlocator-main-container .amlocator-map-container.main-map {
    margin-top: 0;

    .amlocator-store-list.amlocator-block.-storelist {
        overflow: visible;

        .amlocator-stores-wrapper {
            padding-left: $indent__xs;
            margin-left: -$indent__xs;
        }

        .amlocator-pager-container .pages .items {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            .item {
                margin-top: $indent__xs;
            }
        }
        .amlocator-pager-container .pages .current .page,
        .amlocator-pager-container .pages .pages-item-previous .previous:hover,
        .amlocator-pager-container .pages .pages-item-next .next:hover,
        .amlocator-pager-container .pages .page:hover {
            background-color: $color-sna-primario2;
            color: $color-sna-white;
        }
        .amlocator-pager-container .pages .page,
        .amlocator-pager-container .pages .pages-item-previous .previous,
        .amlocator-pager-container .pages .pages-item-next .next {
            font-weight: $font-weight__bold;
            color: $color-sna-primario3;
            background-color: $color-sna-primario4;
            width: $indent__xl;
            height: $indent__xl;
            display: inline-flex;
            justify-content: center;
            align-items: center;
        }
    }
}

@include max-screen($screen__m) {
    .amlocator-main-container .amlocator-map-container.main-map {
        .amlocator-store-list.amlocator-block.-storelist {
            margin-top: $indent__s;
        }
    }
}
@include min-screen($screen__m) {
    .amlocator-main-container .amlocator-map-container.main-map {
        flex-direction: row;
        max-height: none;
        height: 100%;

        .amlocator-search-container,
        .amlocator-block.-filter {
            width: 100%;
        }
        .amlocator-store-list.amlocator-block.-storelist {
            flex: 1 1 auto;
            max-height: 800px;
        }
        .amlocator-block.-map {
            height: auto;
        }
    }
    .amlocator-main-container .amlocator-map {
        min-height: 300px;
    }
}

/**
 *  Store info on list 
 * ------------------------------------------------------------------------- */
.amlocator-main-container {
    .amlocator-store-desc {
        background-color: $color-sna-white;
        margin-bottom: $indent__s;
        margin-right: $indent__s;

        &:not(:first-of-type) {
            margin-top: $indent__s;
        }

        .amlocator-block {
            display: block;
            margin: $indent__base;
        }

        &.-active {
            background-color: inherit;
            color: inherit;
            border: 1px solid $color-sna-primario1;
            box-shadow: $box-shadow-sna1;
        }
    }
}

.amlocator-store__title {
    font-weight: $font-weight__bold;
    margin-bottom: $indent__s;
}

.amlocator-store__information {
    @include lib-font-size($font-size__s);
}

.amlocator-store__address {
    margin-bottom: $indent__base;
}

.amlocator-store__links {
    list-style: none;
    margin: 0;
    padding: 0;

    > [data-value=""] {
        display: none;
    }

    &:not(:last-child) {
      margin-bottom: 0.8em;
    }

    svg {
      height: 1.2em;
      width: 1.2em;
      margin-right: $indent__s;
    }
}

.amlocator-store__segments {
    color: $color-sna-primario1;
    margin-top: $indent__base;

    svg {
        margin-right: $indent__base;
    }
}

/**
 *  Map info popup
 * ------------------------------------------------------------------------- */
.amlocator-map {
    .gm-style {
        .gm-style-iw-c {
            background-color: $color-sna-primario2;
            border-radius: 0;
            padding: 0;
            color: white;
            transform: translate(-75%, -100%);

            > button {
                display: inline-block;
                width: 14px;
                height: 14px;
                overflow: hidden;
                color: currentColor;

                &:hover {
                    &::before,
                    &::after {
                        background: $color-sna-primario1;
                    }
                }

                &::before,
                &::after {
                    content: "";
                    position: absolute;
                    height: 2px;
                    width: 50%;
                    top: 50%;
                    left: 25%;
                    margin-top: -1px;
                    background: currentColor;
                }
                &::before {
                    transform: rotate(45deg);
                }
                &::after {
                    transform: rotate(-45deg);
                }

                > img {
                    display: none !important;
                }
            }
        }
        .gm-style-iw-d {
            overflow: hidden !important;
        }
        .gm-style-iw-t::after {
            background: linear-gradient(45deg, $color-sna-primario2 50%, $color-sna-primario2 51%, $color-sna-primario2 100%);
        }
    }
    .amlocator-info-popup {
        padding: $indent__base;

        .amlocator-title {
            @include lib-font-size($font-size__base);
            font-weight: $font-weight__bold;
        }
    }
}
