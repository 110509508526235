.mktoForm {
    margin: 0 auto;

    .mktoFieldsWrapper {
        margin-bottom: -$indent__l;

        @include min-screen($screen__l) {
            display: flex;
            flex-wrap: wrap;
            margin-left: -$indent__m;
            margin-right: -$indent__m;

            > * {
                flex-basis: 40%;
                flex-grow: 1;
                margin: 0 $indent__m;
            }
        }

        @include min-screen($screen__xl) {
            margin-left: -$indent__xxl;
            margin-right: -$indent__xxl;

            > * {
                margin-left: $indent__xxl;
                margin-right: $indent__xxl;
            }
        }
    }

    .mktoFormRow--fieldset {
        margin-bottom: $indent__xl;

        & + .mktoFormRow--fieldset {
            padding-top: $indent__xl;
            border-top: 1px solid $color-sna-primario4;
        }
    }

    .mktoFormRow--textarea {
        flex-basis: 100%;
    }

    .mktoFormCol {
        float: none;
        padding: 0;
    }

    .mktoFieldWrap {
        float: none;
        @include lib-css(margin-bottom, $indent__l);
        text-align: left;

        .mktoLabel {
            display: inline-flex !important;
            justify-content: flex-start;
            float: none;
            @include lib-css(margin-bottom, $indent__s);
            @include lib-css(font-weight, $font-weight__bold);

            .mktoAsterix {
                display: none;
            }
        }

        .mktoField {
            float: none;
            background-color: $color-white;
            border: 1px solid $color-black;

            &.mktoInvalid,
            &.mktoInvalid ~ .select-container {
                @include lib-form-element-color(
                    $_border-color : $form-element-validation__border-error,
                    $_background   : $form-element-validation__background-error,
                    $_color        : $form-element-validation__color-error
                );
            }
        }

        textarea {
            width: 100%;
            min-height: 6em;
        }

        .mktoError {
            margin-top: 7px;
            @include lib-form-validation-note();
        }

        &--checkbox {
            display: flex;

            > .mktoLogicalField {
                order: -1;
            }

            .mktoLabel {
                font-weight: inherit !important;
            }
        }

        &.mktoRequiredField {
            .mktoLabel {
                .mktoAsterix {
                    float: none;
                    display: inline;
                    margin-left: .5em;
                    order: 2;
                }
            }
        }
    }

    .mktoButtonRow {
        text-align: center;
    }

    .mktoButton {
        @include lib-button-primary();
    }

    .select-hidden {
        visibility: visible;
    }

    &.form-as-marketo-preview {
        .mktoFieldsWrapper {
            margin-bottom: 0;
            @include min-screen($screen__l) {
                display: block;
            }
        }
        .mktoFormRow {
            @include min-screen($screen__l) {
                display: flex;
                flex-wrap: wrap;
                margin-left: -$indent__m;
                margin-right: -$indent__m;

                > .mktoFormCol {
                    flex-basis: 40%;
                    flex-grow: 1;
                    margin: 0 $indent__m;
                }
            }

            @include min-screen($screen__xl) {
                margin-left: -$indent__xxl;
                margin-right: -$indent__xxl;

                > .mktoFormCol {
                    margin-left: $indent__xxl;
                    margin-right: $indent__xxl;
                }
            }
        }
    }
}

