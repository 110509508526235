.media-card {
    @include lib-css(margin-bottom, $indent__m);

    &:hover {
        @include lib-css(box-shadow, $box-shadow-sna1);
    }

    &__title {
        @include lib-heading(h3);
        @include lib-css(padding-right, $indent__base);
        @include lib-css(padding-left, $indent__base);
        text-align: center;
        word-wrap: break-word;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    &__picture {
        display: inline-block;
        max-width: 100%;
        width: 100%;
        margin: 0 0 $indent__base;
        padding: 0 $indent__base;
    }

    &__info {
        @include lib-css(background-color, $color-sna-primario4);
        @include lib-css(padding, $indent__base);

        margin-top: auto;
    }

    &__description {
        @include lib-font-size($font-size__13px);
        @include lib-css(font-weight, $font-weight__bold);
        @include lib-css(margin-bottom, $indent__base);

        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;

        @include min-screen($screen__m) {
            @include lib-font-size($font-size__16px);
        }
    }

    &__action {
        @include lib-button-secondary(
            $_button-width: 100%,
            $_button-min-width: unset,
            $_button-background: transparent,
            $_button-background-hover: transparent,
            $_button-border-hover: 1px solid $color-sna-primario2,
            $_button-background-active: transparent,
            $_button-border-active: 1px solid $color-sna-primario2
        );

        word-wrap: break-word;

        &[download]::before {
            content: '';
            display: inline-block;
            height: 2.4rem;
            width: 2.4rem;
            margin-right: $indent__s;
            background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M20.39 7.478C20.18 3.878 17.212 1 13.634 1c-2.172 0-4.276 1.065-5.578 2.803-.03-.015-.062-.026-.092-.04a3.729 3.729 0 0 0-.38-.159 3.625 3.625 0 0 0-.247-.076c-.046-.012-.091-.025-.138-.036a3.827 3.827 0 0 0-.275-.05c-.04-.006-.08-.014-.12-.018A3.604 3.604 0 0 0 2.8 7c0 .052.003.102.007.152C1.143 8.06 0 9.96 0 11.862A5.144 5.144 0 0 0 5.138 17H7.2a.4.4 0 1 0 0-.8H5.138A4.343 4.343 0 0 1 .8 11.862C.8 10.2 1.882 8.475 3.373 7.76l.227-.108V7.4c0-.048.003-.097.006-.146l.004-.074-.005-.078C3.603 7.068 3.6 7.034 3.6 7c0-1.544 1.256-2.8 2.8-2.8a2.798 2.798 0 0 1 .46.041c.107.018.212.041.316.071l.042.01c.114.036.225.08.334.13.028.012.056.026.084.04A2.798 2.798 0 0 1 9.2 7a.4.4 0 1 0 .8 0 3.592 3.592 0 0 0-1.28-2.748c1.147-1.5 3.035-2.452 4.914-2.452 3.098 0 5.67 2.454 5.94 5.555a7.64 7.64 0 0 0-1.634.05.4.4 0 0 0 .12.79c.89-.134 1.82-.008 1.874 0 1.862.354 3.266 2.07 3.266 3.994 0 2.211-1.8 4.011-4.011 4.011H17.6a.4.4 0 1 0 0 .8h1.589A4.817 4.817 0 0 0 24 12.189c0-2.196-1.53-4.165-3.61-4.711z'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='m15.336 18.117-2.447 2.517V12.4c0-.221-.174-.4-.389-.4a.394.394 0 0 0-.389.4v8.234l-2.447-2.517a.38.38 0 0 0-.55 0 .408.408 0 0 0 0 .566l3.11 3.2a.385.385 0 0 0 .551 0l3.111-3.2a.408.408 0 0 0 0-.566.38.38 0 0 0-.55 0z'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            vertical-align: middle;
        }
    }
}

@include min-screen($screen__m) {
    .pagebuilder-column:has(.media-card) {
        @include lib-css(padding-right, $indent__m/2);
        @include lib-css(padding-left, $indent__m/2);
    }

    .pagebuilder-column .media-card {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    .media-card {
        &__title {
            min-height: 55px;
        }
    }
}
