@mixin _lib-sna-grid-list() {
    @extend .abs-reset-list;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include lib-css(margin-top, -$indent__base);
    @include lib-css(margin-left, -$indent__base/2);
    @include lib-css(margin-right, -$indent__base/2);

    > li {
        @include lib-css(margin-top, $indent__base);
        @include lib-css(margin-left, $indent__base/2);
        @include lib-css(margin-right, $indent__base/2);

        .action.primary {
            display: none;
            width: 100%;
            min-width: unset;
        }

        @include min-screen($screen__m) {
            &:hover {
                @include lib-css(box-shadow, $box-shadow-sna1);

                .action.primary {
                    display: block;
                }
            }
        }
    }

    @include min-screen($screen__m) {
        @include lib-css(margin-top, -$indent__m);
        @include lib-css(margin-left, -$indent__m/2);
        @include lib-css(margin-right, -$indent__m/2);
        > li {
            @include lib-css(margin-top, $indent__m);
            @include lib-css(margin-left, $indent__m/2);
            @include lib-css(margin-right, $indent__m/2);
        }
    }

    @supports (display: grid) {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        @include lib-css(grid-gap, $indent__base);
        margin: 0;

        > li {
            margin: 0;
        }

        @include max-screen($screen__xs-2) {
            @include lib-css(grid-gap, $indent__base $indent__s);
        }
        @include min-screen($screen__s) {
            grid-template-columns: repeat(3, minmax(0, 1fr));
            @include lib-css(grid-gap, $indent__m);
        }
        @include min-screen($screen__m) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
            @include lib-css(grid-gap, $indent__m);
        }
        @include min-screen($screen__l) {
            grid-template-columns: repeat(3, minmax(0, 1fr));
            @include lib-css(grid-gap, $indent__m);
        }
        @include min-screen($screen__xl) {
            grid-template-columns: repeat(4, minmax(250px, 320px));
        }
    }

}
@mixin _lib-sna-grid-list-secondary() {
    @extend .abs-reset-list;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    @include lib-css(margin-top, -$indent__base);
    @include lib-css(margin-left, -$indent__base/2);
    @include lib-css(margin-right, -$indent__base/2);

    > li {
        @include lib-css(margin-top, $indent__base);
        @include lib-css(margin-left, $indent__base/2);
        @include lib-css(margin-right, $indent__base/2);

        .action.primary {
            display: none;
            width: 100%;
            min-width: unset;
        }

        @include min-screen($screen__m) {
            &:hover {
                @include lib-css(box-shadow, $box-shadow-sna1);

                .product-item-sku {
                    margin-bottom: $indent__s;
                }

                .action.primary {
                    display: block;
                }
            }
        }
    }

    @include min-screen($screen__m) {
        @include lib-css(margin-top, -$indent__m);
        @include lib-css(margin-left, -$indent__m/2);
        @include lib-css(margin-right, -$indent__m/2);
        > li {
            @include lib-css(margin-top, $indent__m);
            @include lib-css(margin-left, $indent__m/2);
            @include lib-css(margin-right, $indent__m/2);
        }
    }

    @supports (display: grid) {
        display: grid;
        grid-template-columns: repeat(2, minmax(320px, 420px));
        @include lib-css(grid-gap, $indent__xl);
        margin: 0;

        > li {
            margin: 0;
        }

        @include max-screen($screen__m-2) {
            @include lib-css(grid-gap, $indent__xxl);
            grid-template-columns: repeat(1, 1fr);
            > li {
                margin: 0 auto;
            }
        }

        @include min-screen($screen__l-3) {
            grid-template-columns: repeat(3, minmax(320px, 420px));
        }

        @include min-screen($screen__xxl-2) {
            grid-template-columns: repeat(4, minmax(320px, 420px));
        }
    }

}

@mixin _lib-sna-grid-list__item() {
    display: flex;
    flex-direction: column;
    flex-basis: 320px;
    flex-shrink: 0;
    @include lib-css(padding-top, $indent__base);
    @include lib-font-size($font-size__s);


    > article {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    > img,
    > article > img {
        order: -1;
        @include lib-css(padding, 0 $indent__base);
    }

    &__name {
        @include lib-css(margin-bottom, $indent__s);
        font-size: 1em;
    }

    @include min-screen($screen__m) {
        @include lib-font-size($font-size__base);
    }

    @include max-screen($screen__xs-2) {
        > img,
        > article > img {
            @include lib-css(padding, 0 $indent__xs);
        }
    }
}

@mixin _lib-sna-grid-list__item-secondary() {
    display: flex;
    flex-direction: row;
    flex-basis: 420px;
    flex-shrink: 0;
    @include lib-css(padding, 0);
    @include lib-font-size($font-size__s);
    margin: 0 $indent__xl $indent__xl 0;


    .product-item-name {
        @include lib-css(margin-bottom, $indent__s);
    }

    @include min-screen($screen__m) {
        @include lib-font-size($font-size__base);
    }
}

@mixin lib-category-product-list() {
    @include _lib-sna-grid-list();
}

@mixin lib-category-product-list-secondary() {
    @include _lib-sna-grid-list-secondary();
}

@mixin lib-category-list__item() {
    @include lib-css(font-weight, $font-weight__bold);

    @include _lib-sna-grid-list__item();

    &__info,
    .product-item-details {
        @include lib-css(padding, 0 $indent__base $indent__base);
        @include lib-css(margin-top, $indent__s);
    }
    &__info {
        @include lib-css(background-color, $color-sna-primario4);
    }
    &__name {
        text-transform: uppercase;
    }

    &__num-products {
        @include lib-css(color, $color-sna-primario1);
        @include lib-font-size($font-size__s);
    }

    @include max-screen($screen__xs-2) {
        &__info,
        .product-item-details {
            @include lib-css(padding, 0 $indent__xs $indent__base);
        }
    }
}

@mixin lib-product-list__item() {
    @include lib-css(font-weight, $font-weight__bold);
    max-width: 320px;

    @include _lib-sna-grid-list__item();

    &__info,
    .product-item-details {
        @include lib-css(padding, 0 $indent__base $indent__base);
        @include lib-css(margin-top, $indent__s);
    }
    &__info {
        @include lib-css(background-color, $color-sna-primario4);
    }
    &__name {
        text-transform: uppercase;
    }

    &__num-products {
        @include lib-css(color, $color-sna-primario1);
        @include lib-font-size($font-size__s);
    }

    @include max-screen($screen__xs-2) {
        &__info,
        .product-item-details {
            @include lib-css(padding, 0 $indent__xs $indent__base);
        }
    }
}

@mixin lib-product-list__item-secondary() {
    @include lib-css(font-weight, $font-weight__bold);
    max-width: 420px;

    @include _lib-sna-grid-list__item-secondary();


    .product-item-details {
        @include lib-css(padding, 0);
        @include lib-css(margin, 0);
    }
    .product-item-photo {
        flex: 0 1 45%;
    }
    .product-item-details {
        flex: 0 1 55%;
    }
    .product-item-info {
        flex-direction: row;
        padding: $indent__base;
        align-items: center;
    }

    .product-item-photo {
        margin: 0 $indent__m auto 0;
    }

    @include max-screen($screen__xs-2) {
        .product-item-info{
            @include lib-css(padding, $indent__s);
        }
    }
}

.category-list {
    @include lib-category-product-list();
}

.category-item {
    @include lib-category-list__item();


    .action.primary {
        margin-top: $indent__m;
    }

    .category-image {
        margin: 0 0 auto;
        @include lib-css(padding, 0 $indent__base);
    }

    &__name,
    &__num-products {
        display: block;
    }

    &__num-products {
        margin: 0;
    }

    &__name {
        margin-top: $indent__base;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    @include min-screen($screen__m) {

        &:hover {

            .category-image-wrapper {
                padding-bottom: calc(80% - 72px);
            }

        }
    }
}

.product-list,
.product-items,
.product-items-secondary {
    .special-product-label {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;

        .label {
            @include lib-link-secondary(
                $_link-font-weight             : $font-weight__heavier,
                $_link-text-transform          : uppercase,
                $_link-color                   : $color-sna-primario2,
                $_link-color-visited           : $color-sna-primario2,
                $_link-color-hover             : $color-sna-primario2,
                $_link-color-active            : $color-sna-primario2
            );
            font-weight: $font-weight__heavier;
            @include lib-font-size($font-size__s);
            border: 1px solid $color-sna-primario2;
            padding: $indent__xs $indent__s;
            background-color: $color-sna-white;
        }
    }

    .product-ergo {
        position: absolute;
        bottom: 0;
        left: 0;
        max-width: 72px;

        > img {
            display: block;
            max-width: 100%;
        }
    }

    .slick-track {
        display: flex;
    }

    .product-item.slick-slide {
        flex-basis: auto;
        display: flex;
    }
}


.product-list,
.product-items {
    @include lib-category-product-list();
}

.product-items-secondary {
    @include lib-category-product-list-secondary();
}

.product-item-secondary {
    border: 1px solid $color-sna-primario3;

    &:hover {
        border: 1px solid $color-sna-black;
    }

    @include lib-product-list__item-secondary();
}

.product-item-tertiary {
    background-color: $color-sna-primario4;

    @include lib-product-list__item-secondary();
}

.product-item {
    @include lib-product-list__item();
}

.catalog-list {
    @include lib-category-product-list();

    .list-item {
        max-width: 320px;

        &__link {
            display: block;
        }
        img {
            display: block;
        }
    }
}
