.cta-banner-details,
.column-slider,
.cta-banner,
.cta-banner,
.cta-double,
.heroslider,
.range-preview,
.row-slider,
.cms-page-section,
.home-section {
    &[data-theme="theme-dark"],
    &.theme-dark,
    &--dark {
        .action.go {
            @include lib-button-go--white();
        }
    }
}
