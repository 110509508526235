//
//  Tablet + Desktop
//  _____________________________________________

@include min-screen($screen__s) {
    #super-product-table {
        thead {
            background-color: $color__primary--light;
            color: $color-sna-black;
        }
    }
}
