//
//  Variables
//  _____________________________________________

$account-nav-background              : $sidebar__background-color !default;
$account-nav-color                   : $color-sna-primario3 !default;

$account-nav-current-border          : 3px solid transparent !default;
$account-nav-current-border-color    : $color-sna-primario1 !default;
$account-nav-current-color           : $color-sna-white !default;
$account-nav-current-font-weight     : $font-weight__bold !default;

$account-nav-delimiter__border-color : $color-sna-primario3 !default;

$account-nav-item-hover              : $color-gray91 !default;

$_password-default                   : $color-gray-light01 !default;
$_password-weak                      : #ffafae !default;
$_password-medium                    : #ffd6b3 !default;
$_password-strong                    : #c5eeac !default;
$_password-very-strong               : #81b562 !default;

.login-container {
    .block {
        &-new-customer {
            .actions-toolbar {
                margin-top: 25px;
            }
        }

        .block-title {
            @extend .abs-login-block-title;
            @include lib-font-size(18);
        }
    }

    .fieldset {
        @include lib-form-hasrequired(bottom);
        &:after {
            margin-top: 35px;
        }
    }
}
.control {
    .select-input-container {
        width: 100%;
        max-width: 650px;
    }
}

.block-addresses-list {
    .items.addresses {
        > .item {
            margin-bottom: $indent__base;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.block-dashboard-info,
.block-dashboard-addresses {
    .block-content {
        .box-actions {
            display: flex;
            justify-content: space-between;

            .action.change-password {
                @include lib-link-secondary(
                        $_link-color-hover              : $color-sna-primario2,
                        $_link-text-decoration-hover    : underline
                );
            }
        }
    }
}

.form-address-edit {
    #region_id {
        display: none;
    }
}

.form-edit-account {
    .fieldset.password {
        display: none;
    }
}

.form.form-newsletter-manage {
    div.actions-toolbar {
        margin-left: 0;
    }
    .field.choice:before {
        content: none;
    }
}

.form.create.account, .form.form-edit-account {
    .beun-checkbox {
        margin-top: $indent__base;
        margin-bottom: $indent__m;
    }
    .beun-content .description {
        .items {
            list-style-type:lower-roman;
            list-style-position: inside;
        }
    }
}

.box-billing-address,
.box-shipping-address,
.box-information,
.box-newsletter {
    .box-content {
        line-height: 26px;
    }
}

//  Full name fieldset
.fieldset {
    .fullname {
        &.field {
            > .label {
                @extend .abs-visually-hidden;

                + .control {
                    width: 100%;
                }
            }
        }

        .field {
            @extend .abs-add-clearfix;
        }
    }
}

//
//  My account
//  ---------------------------------------------

.account {
    .column.main {
        h2 {
            margin-top: 0;
        }

        .toolbar {
            text-align: center;
            .limiter-options {
                width: auto;
            }
        }

        .limiter {
            >.label {
                @extend .abs-visually-hidden;
            }
        }

        .block:not(.widget) {
            @extend .abs-account-blocks;
        }
    }

    .sidebar-additional {
        margin-top: 40px;
    }

    .table-wrapper {
        &:last-child {
            margin-bottom: 0;
        }

        .action {
            margin-right: 15px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .table-return-items {
        .qty {
            .input-text {
                @extend .abs-input-qty;
            }
        }
    }
}

//  Checkout address (create shipping address)
.field.street {
    .field.additional {
        .label {
            @extend .abs-visually-hidden;
        }
    }
}

//
//  Account navigation
//  ---------------------------------------------

.account-nav {
    .title {
        @extend .abs-visually-hidden;
    }

    .content {
        @include lib-css(background, $account-nav-background);
        padding: 15px 0;
    }

    .item {
        margin: 3px 0 0;
        padding: $indent__xs $indent__base;

        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-top: 0;
        }

        a,
        > strong {
            @include lib-css(color, $account-nav-color);
            border-bottom: $account-nav-current-border;
            display: block;
            padding: 0 0 $indent__s;
            font-weight: $account-nav-current-font-weight;
        }

        a {
            text-decoration: none;

            &:hover {
                @include lib-css(border-color, $account-nav-current-border-color);
                @include lib-css(color, $account-nav-current-color);
            }
        }

        small {
            display: block;
            font-size: $font-size__xs;
        }

        &.current {
            a,
            strong {
                @include lib-css(border-color, $account-nav-current-border-color);
                @include lib-css(color, $account-nav-current-color);
            }

            a {
                @include lib-css(border-color, $account-nav-current-border-color);
            }
        }

        .delimiter {
            border-top: 1px solid $account-nav-delimiter__border-color;
            display: block;
            margin: $indent__s 0;
        }
    }
}

//
//  Blocks & Widgets
//  ---------------------------------------------

.block {
    @extend .abs-margin-for-blocks-and-widgets;
    .column.main & {
        &:last-child {
            margin-bottom: 0;
        }
    }

    .title {
        margin-bottom: $indent__s;

        strong {
            @include lib-heading(h4);
            .column.main & {
                font-size: $h3__font-size;
            }
        }
    }

    p:last-child {
        margin: 0;
    }

    .box-actions {
        margin-top: $indent__xs;
    }
}

//
//  Password Strength Meter
//  ---------------------------------------------

.field.password {
    .control {
        @include lib-vendor-prefix-display();
        @include lib-vendor-prefix-flex-direction();

        .mage-error {
            @include lib-vendor-prefix-order(2);
        }

        .input-text {
            @include lib-vendor-prefix-order(0);
        }
    }
}

.password-strength-meter {
    background-color: $_password-default;
    height: $form-element-input__height;
    line-height: normal;
    padding: $form-element-input__padding;
    position: relative;
    z-index: 1;
    max-width: 650px;

    &:before {
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        z-index: -1;
    }

    .password-none & {
        &:before {
            background-color: $_password-default;
            width: 100%;
        }
    }

    .password-weak & {
        &:before {
            background-color: $_password-weak;
            width: 25%;
        }
    }

    .password-medium & {
        &:before {
            background-color: $_password-medium;
            width: 50%;
        }
    }

    .password-strong & {
        &:before {
            background-color: $_password-strong;
            width: 75%;
        }
    }

    .password-very-strong & {
        &:before {
            background-color: $_password-very-strong;
            width: 100%;
        }
    }
}

.control.captcha-image {
    @include lib-css(margin-top, $indent__s);

    .captcha-img {
        vertical-align: middle;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .account {
        .column.main,
        .sidebar-additional {
            margin: 0;
        }
    }
}

@include max-screen($screen__m) {

    body.account {
        .page-title-wrapper {
            @include lib-css(background, $account-nav-background);
            @include lib-css(color, $account-nav-current-color);

            .page-title {
                margin-bottom: 0;
                padding: $indent__base;

                .base {
                    padding-right: $indent__m;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 6'%3E%3Cpath d='M11.78.11a.443.443 0 0 0-.59 0L5.96 4.98.72.11C.56-.04.29-.04.13.11s-.16.4 0 .55L5.65 5.8a.4.4 0 0 0 .3.11c.1 0 .22-.04.3-.11L11.78.67c.17-.16.17-.4 0-.56z' fill-rule='evenodd' fill='%239b9b9b' clip-rule='evenodd'/%3E%3C/svg%3E%0A");
                    background-position: right center;
                    background-repeat: no-repeat;
                    background-size: $indent__base;
                }
            }
            &.active {
                .page-title .base {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 12 6' style='enable-background:new 0 0 12 6;' xml:space='preserve'%3E%3Cg id='icon-arrow-up-bahco' transform='translate(0.052535, 0.038095)'%3E%3Cpath class='st0' fill='%239b9b9b' d='M11.78,5.8c-0.16,0.15-0.43,0.15-0.59,0L5.96,0.93L0.72,5.8c-0.16,0.15-0.43,0.15-0.59,0s-0.16-0.4,0-0.55 l5.53-5.13C5.73,0.04,5.84,0,5.95,0c0.1,0,0.22,0.04,0.3,0.11l5.53,5.13C11.95,5.4,11.95,5.65,11.78,5.8z'/%3E%3C/g%3E%3C/svg%3E");
                }
            }
        }

        .sidebar-main {
            display: none;

            .block.account-nav {
                margin-bottom: 0;

                .account-nav-content {
                    padding: 0;

                    .item {
                        margin: 0;
                        padding: 0 $indent__base;

                        a,
                        > strong {
                            padding: $indent__base 0;
                        }
                    }
                }
            }
        }

        .page.messages,
        .page.messages * {
            margin-bottom: 0;
        }

        .column.main {
            margin-top: $indent__xl;
        }
    }

    .login-container {
        .fieldset {
            &:after {
                text-align: center;
            }
            &.login,
            &.create {
                @include lib-font-size($font-size__s);
            }
        }
    }

    .account {
        .page.messages {
            margin-bottom: $indent__xl;
        }

        .toolbar {
            @extend .abs-pager-toolbar-mobile;
        }
    }

    .control.captcha-image {
        .captcha-img {
            @include lib-css(margin-bottom, $indent__s);
            display: block;
        }
    }

    .customer-account-index {
        .page-title-wrapper {
            position: relative;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

    body.account.page-layout-2columns-left {
        $account-page__sidebar-max-width: 250px;

        .sidebar-main {
            min-width: $account-page__sidebar-max-width;
            padding-top: 0;
        }
        div.column.main {
            max-width: calc(100% - #{$account-page__sidebar-max-width});
            width: 100%;
            padding-left: $indent__m;
        }
    }

    .block-dashboard-info,
    .block-dashboard-addresses {
        .block-content {
            display: flex;
            flex-direction: column;
        }
    }

    .form.claims {
        margin-top: $indent__base;

        .field.send-attachment,
        .field.single-checkbox.terms-conditions,
        .actions {
            margin-left: $form-field-type-label-inline__width;
        }
    }

    .login-container {
        display: flex;
        justify-content: space-between;

        .block {
            flex: 0 1 49%;
            &.login {
                .actions-toolbar {
                    > .primary {
                        margin-bottom: 0;
                        margin-right: $indent__l;
                    }

                    > .secondary {
                        float: left;
                    }
                }
            }
        }

        .fieldset {

            > .field {
                > .control {
                    width: 55%;
                }
            }
        }
    }

    //  Full name fieldset
    .fieldset {
        .fullname {
            .field {
                .label {
                    @include lib-css(margin, $form-field-type-label-inline__margin);
                    @include lib-css(padding, $form-field-type-label-inline__padding);
                    @include lib-css(text-align, $form-field-type-label-inline__align);
                    @include lib-css(width, $form-field-type-label-inline__width);
                    box-sizing: border-box;
                    float: left;
                }

                .control {
                    @include lib-css(width, $form-field-type-control-inline__width);
                    float: left;
                }
            }
        }

        &.login {

            .field.email,
            .field.password {
                display: flex;
                flex-direction: column;

                .label,
                .control {
                    width: 100%;
                    text-align: left;
                }
                .label {
                    margin-bottom: $indent__s;
                }
            }

            div.actions-toolbar {
                text-align: right;
                margin-left: 0;
                .secondary {
                    display: block;
                    text-align: right;

                    .remind {
                        @include lib-font-size($font-size__s);
                        width: auto;
                    }
                }
                .primary,
                .secondary {
                    float: none;
                }
            }
        }
    }

    .form.password.reset,
    .form.send.confirmation,
    .form.password.forget,
    .form.create.account {
        min-width: 600px;
        width: 50%;

        .terms-conditions-checkbox,
        .field-gdpr .control,
        .beun-checkbox,
        .beun-content .description {
            margin-left: $form-field-type-label-inline__width;
        }
    }

    .form.form-giftcard-redeem {

        div.actions-toolbar {
            display: flex;
            flex-direction: column;
            .primary,
            .secondary {
                max-width: $button-primary__min-width;
                margin-bottom: $indent__s;
            }
        }
    }


    //
    //  My account
    //  ---------------------------------------------

    .account.page-layout-2columns-left {
        .sidebar-main,
        .sidebar-additional {
            width: 22.3%;
        }

        .column.main {
            width: 77.7%;
        }
    }

    .account {
        .toolbar {
            @extend .abs-pager-toolbar;
        }
    }

    .block-addresses-list {
        .items.addresses {
            @extend .abs-add-clearfix-desktop;
            font-size: 0;

            > .item {
                display: inline-block;
                margin-bottom: $indent__base;
                vertical-align: top;
                width: 48.8%;

                &:nth-last-child(1),
                &:nth-last-child(2) {
                    margin-bottom: 0;
                }

                &:nth-child(even) {
                    margin-left: 2.4%;
                }
            }
        }
    }

    //
    //  Welcome block
    //  ---------------------------------------------

    .dashboard-welcome-toggler {
        @extend .abs-visually-hidden-desktop;
    }

    .control.captcha-image {
        .captcha-img {
            margin: 0 $indent__s $indent__s 0;
        }
    }
}

@include min-screen($screen__m-2) {
    .fieldset {
        &.login {
            .field.email,
            .field.password {
                flex-direction: row;

                .label {
                    flex: 0 1 40%;
                    max-width: 180px;
                    margin-bottom: 0;
                    text-align: right;
                }

                .control {
                    flex: 0 1 60%;
                }
            }

            div.actions-toolbar {
                text-align: left;

                .secondary {
                    text-align: left;
                }
            }
        }
    }



    .block.block-dashboard-info,
    .block.block-dashboard-addresses {
        .block-content {
            flex-direction: row;
            justify-content: space-between;

            .box {
                flex: 0 1 45%;
            }
        }
    }
}

@include min-screen($screen__l-2) {
    .form.form-giftcard-redeem {

        div.actions-toolbar {
            flex-direction: row;

            .secondary {
                margin-left: auto;
            }
        }
    }
}

@include min-screen($screen__xl) {
    .form.create.account {
        min-width: unset;
        width: 100%;

        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .fieldset,
        .actions-toolbar {
            flex: 0 48%;
        }
        .actions-toolbar {
            margin-left: auto;
            > .primary {
                margin-left: $form-field-type-label-inline__width;
            }
        }
    }
}
