.breadcrumbs {
    @include lib-breadcrumbs();
}

body.catalog-product-view {
    .breadcrumbs {
        background-color: $color-sna-primario4;
    }
}

@include max-screen($screen__m) {
    .breadcrumbs .items {
        @include lib-font-size(11px);
    }
}

@include min-screen($screen__m) {
    .breadcrumbs {
        @include lib-css(padding-bottom, $indent__l);
    }
}

@include min-screen($screen__xl) {
    .breadcrumbs {
        @include lib-css(padding-bottom, $indent__xxl);
    }
}

/**
 * Breadcrums in CMS pages with landing header
 * --------------------------------------------- */
.catalog-category-view-landing .breadcrumbs,
.breadcrumbs-with-background .breadcrumbs {
    margin-bottom: -2.1em;
    padding-bottom: 0;
    z-index: 2;
    @include lib-css(color, $color-white);

    @include max-screen($screen__m) {
        position: absolute; /* Make them visible when .page-wrapper has display:flex */
    }

    *, 
    a {
        color: currentColor;
    }
}
