//
//  Full Bleed row appearance styles
//  _____________________________________________

[data-content-type='row'][data-appearance='full-bleed'] {
    box-sizing: border-box;
}

.page-main {
    [data-content-type='row'][data-appearance='full-bleed'] {
        margin-left: -$layout-indent-main__s !important; // sass-lint:disable-line no-important
        margin-right: -$layout-indent-main__s !important; // sass-lint:disable-line no-important
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    [data-content-type='row'][data-appearance='full-bleed'] {
        background-attachment: scroll !important; // sass-lint:disable-line no-important
        .page-main-full-width & {
            padding-left: $layout-indent-main__s;
            padding-right: $layout-indent-main__s;
        }
    }
}

@include min-screen($screen__m) {
    [data-content-type='row'][data-appearance='full-bleed'] {
        .page-main & {
            margin-left: -$layout-indent-main__m !important; // sass-lint:disable-line no-important
            margin-right: -$layout-indent-main__m !important; // sass-lint:disable-line no-important
        }

        .page-main-full-width & {
            padding-left: $layout-indent-main__m;
            padding-right: $layout-indent-main__m;
        }
    }
}

@include min-screen($screen__xl) {
    [data-content-type='row'][data-appearance='full-bleed'] {
        .page-main & {
            margin-left: -$layout-indent-main__xl !important; // sass-lint:disable-line no-important
            margin-right: -$layout-indent-main__xl !important; // sass-lint:disable-line no-important
        }

        .page-main-full-width & {
            padding-left: $layout-indent-main__xl;
            padding-right: $layout-indent-main__xl;
        }
    }
}
