$screen__sx: 650px;
.hide {
    display: none;
}
body {
    &.bss-popup-opened {
        overflow-y: hidden;
    }
    &.safari-context {
        width: 100%;
        height: 100%;
    }
}
.mfp-wrap {
    &.safari-context-wrap {
        top: 50% !important;
        left: 50% !important;
        transform:translate(-50%, -50%);
    }
}
.popup_wrapper {
    display: none;
    background-color: #fff;
    z-index: 99;
    padding: 10px 15px;
    border: 1px solid gray;
    border-radius: 2px;
    box-shadow: 3px 3px 15px #222222;
    font-size: 14px;
    position: relative;
    margin: auto;
    max-width: 70%;

    .block-static-block.widget {
        margin: (-10px) (-15px);
    }
}
.bss_popup {
    .opened {
        overflow-y: hidden;
    }
}
#template-contact-form {
    max-width: 309px;
    .popup_content {
        width: 100%;
        margin: 12px auto;
        border-radius: 10px;
        border: 2px solid #1979c3;
        padding: 15px;
        box-sizing: border-box;
        .legend {
            border: none;
            text-align: center;
            color: #1979c3;
            font-weight: bold;
            margin-bottom: 10px;
        }
        .fieldset {
            margin: 0;
            >.field {
                margin: 0 0 10px;
            }
        }
    }
}
#template-age-verification {
    width: 700px;
    max-width: 90vw;
    height: auto;
    text-align: center;
    background-image:url('../../images/bg-popup-ageverification.png');
    background-size: cover;
    color:#fff;
    box-sizing: border-box;
    .popup-title {
        margin-top: 40px;
        font-size: 50px;
        text-transform: uppercase;
        &:before {
            content:"";
            width:40px;
            height:45px;
            background-image:url('../../images/lock.png');
            display: inline-block;
            background-position: center;
            position: relative;
            top: 5px;
            padding-right:5px;
        }
    }
    .popup-description {
        font-size: 18px;
        margin: 20px auto;
        padding: 0 15px;
        max-width: 410px;
        box-sizing: border-box;
    }
    button {
        width: 270px;
        height: 60px;
        color: #fff;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        border: none;
        &#age_verification_yes {
            background: #00b14f;
        }
        &#age_verification_no {
            background: #069ef7;
            margin-left: 10px;
        }
    }
    #age_verification_redirect {
        margin: 10px 58px 60px 0;
        max-width: 270px;
        float: right;
    }
}
#template-newsletter {
    max-width: 600px;
    padding: 0;
    border-radius: 25px;
    text-align: center;
    .popup_content {
        > .newsletter {
            width:100%;
            max-width: 100%;
            &:before {
                content:"";
                background:url('../../images/banner-newletter.png');
                width: 100%;
                border-top-left-radius: 25px;
                border-top-right-radius: 25px;
                height: 290px;
                display: block;
                background-repeat: no-repeat;
                background-size: cover;
            }
            .title-popup {
                font-size: 30px;
                color: #000;
                padding: 30px 0 10px;
            }
            .description-popup {
                padding: 0 40px;
            }
            .subscribe {
                margin-top: 20px;
                max-width: 300px;
                margin: 20px auto 0;
                .actions,
                .newsletter {
                    display:block;
                    width: 100%;
                }
                .field {
                    .control {
                        &:before {
                            display:none;
                        }
                        #newsletter {
                            border: 1px solid #ececec;
                            padding: 25px 50px;
                            border-radius: 30px;
                            font-style: italic;
                            color: #222;
                            &::placeholder {
                                color: #222;
                                text-transform: uppercase;
                            }
                            &:-ms-input-placeholder {
                                color: #222;
                                text-transform: uppercase;
                            }
                            &::-ms-input-placeholder {
                                color: #222;
                                text-transform: uppercase;
                            }
                        }
                    }
                }
                .actions {
                    .subscribe {
                        background: #3fa9f5;
                        width: 100%;
                        max-width: 300px;
                        border-radius: 20px;
                        text-transform: uppercase;
                        border: none;
                        margin: 12px 0 0;
                    }
                }
            }
        }
    }
    .mfp-close {
        display:none;
    }
}
#template-social-sharing {
    background-image:url('../../images/bg-popup-social.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    color: #fff;
    max-width: 650px;
    padding: 70px 25px;
    box-sizing: border-box;
    .title-popup {
        font-size:40px;
        font-weight:bold;
        text-transform: uppercase;
        line-height: 40px;
    }
    .mfp-close {
        display:none;
    }
    .des-popup {
        font-size: 22px;
        margin-top: 50px;
    }
    .social {
        list-style: none;
        margin: 15px 0 0;
        padding:0;
        li {
            display: inline-block;
            padding: 0 5px;
            margin-bottom:0;
            a {
                display: block;
            }
        }
    }
}
#template-hot-deals {
    max-width: 754px;
    border-radius: 10px;
    .block-products-list {
        text-align: center;
        .block-title {
            margin: 40px 0;
            strong {
                font-size: 25px;
                line-height: 25px;
                font-weight: bold;
            }
        }
        .product-item {
            width: 23%;
        }
    }
}

.popup_content {
    display: grid;
    .products-grid .product-item-actions {
        margin: 0;
        .block-title {
            font-size: 25px;
            line-height: 25px;
            font-weight: bold;
        }
    }
}

.popup_content p {
    margin-top: 0;
    margin-bottom: 0;
}

.mfp-bg {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1042;
    overflow: hidden;
    position: fixed;
    background: #0b0b0b;
    opacity: 0.8;
}

.mfp-wrap {
    top: unset !important;
    left: unset !important;
    width: 100%;
    height: 100%;
    z-index: 1043;
    position: fixed !important;
    outline: none !important;
    -webkit-backface-visibility: hidden;
}

.mfp-container {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0 8px;
    box-sizing: border-box;
}

.mfp-container:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
    display: none;
}

.mfp-content {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 0 auto;
    text-align: left;
    z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
    width: 100%;
    cursor: auto;
}

.mfp-ajax-cur {
    cursor: progress;
}

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
    cursor: -moz-zoom-out;
    cursor: -webkit-zoom-out;
    cursor: zoom-out;
}

.mfp-zoom {
    cursor: pointer;
    cursor: -webkit-zoom-in;
    cursor: -moz-zoom-in;
    cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
    cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.mfp-loading.mfp-figure {
    display: none;
}

.mfp-hide {
    display: none !important;
}

.mfp-preloader {
    color: #CCC;
    position: absolute;
    top: 50%;
    width: auto;
    text-align: center;
    margin-top: -0.8em;
    left: 8px;
    right: 8px;
    z-index: 1044;
}

.mfp-preloader a {
    color: #CCC;
}

.mfp-preloader a:hover {
    color: #FFF;
}

.mfp-s-ready .mfp-preloader {
    display: none;
}

.mfp-s-error .mfp-content {
    display: none;
}

button.mfp-close,
button.mfp-arrow {
    overflow: visible;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
    display: block;
    outline: none;
    padding: 0;
    z-index: 1046;
    box-shadow: none;
    touch-action: manipulation;
}

button::-moz-focus-inner {
    padding: 0;
    border: 0;
}

.mfp-close {
    width: 30px;
    height: 30px;
    line-height: 30px;
    position: absolute;
    right: 0;
    top: 0;
    text-decoration: none;
    text-align: center;
    opacity: 0.65;
    padding: 0 0 18px 10px;
    color: #FFF;
    font-style: normal;
    font-size: 28px;
    font-family: Arial, Baskerville, monospace;
}

.mfp-close:hover,
.mfp-close:focus {
    opacity: 1;
}

.mfp-close:active {
    top: 1px;
}

.mfp-close-btn-in .mfp-close {
    color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
    color: #FFF;
    right: -6px;
    text-align: right;
    padding-right: 6px;
    width: 100%;
}

.mfp-counter {
    position: absolute;
    top: 0;
    right: 0;
    color: #CCC;
    font-size: 12px;
    line-height: 18px;
    white-space: nowrap;
}

.mfp-arrow {
    position: absolute;
    opacity: 0.65;
    margin: 0;
    top: 50%;
    margin-top: -55px;
    padding: 0;
    width: 90px;
    height: 110px;
    -webkit-tap-highlight-color: transparent;
}

.mfp-arrow:active {
    margin-top: -54px;
}

.mfp-arrow:hover,
.mfp-arrow:focus {
    opacity: 1;
}

.mfp-arrow:before,
.mfp-arrow:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent;
}

.mfp-arrow:after {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px;
}

.mfp-arrow:before {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7;
}

.mfp-arrow-left {
    left: 0;
}

.mfp-arrow-left:after {
    border-right: 17px solid #FFF;
    margin-left: 31px;
}

.mfp-arrow-left:before {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F;
}

.mfp-arrow-right {
    right: 0;
}

.mfp-arrow-right:after {
    border-left: 17px solid #FFF;
    margin-left: 39px;
}

.mfp-arrow-right:before {
    border-left: 27px solid #3F3F3F;
}

.mfp-iframe-holder {
    padding-top: 40px;
    padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
    top: -40px;
}

.mfp-iframe-scaler {
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000;
}

img.mfp-img {
    width: auto;
    max-width: 100%;
    height: auto;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 40px 0 40px;
    margin: 0 auto;
}

.mfp-figure {
    line-height: 0;
}

.mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444;
}

.mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px;
}

.mfp-figure figure {
    margin: 0;
}

.mfp-bottom-bar {
    margin-top: -36px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    cursor: auto;
}

.mfp-title {
    text-align: left;
    line-height: 18px;
    color: #F3F3F3;
    word-wrap: break-word;
    padding-right: 36px;
}

.mfp-image-holder .mfp-content {
    max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
    cursor: pointer;
}

.mfp-zoom-in {

    .mfp-with-anim {
        opacity: 0;
        transition: all 0.2s ease-in-out;
        transform: scale(0.8);
    }

    &.mfp-bg {
        opacity: 0;
        transition: all 0.3s ease-out;
    }

    &.mfp-ready {
        .mfp-with-anim {
            opacity: 1;
            transform: scale(1);
        }

        &.mfp-bg {
            opacity: 0.8;
        }

    }

    &.mfp-removing {
        .mfp-with-anim {
            transform: scale(0.8);
            opacity: 0;
        }

        &.mfp-bg {
            opacity: 0;
        }

    }

}

.mfp-newspaper {

    .mfp-with-anim {
        opacity: 0;
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.5s;
        transform: scale(0) rotate(500deg);
    }

    &.mfp-bg {
        opacity: 0;
        transition: all 0.5s;
    }

    &.mfp-ready {
        .mfp-with-anim {
            opacity: 1;
            transform: scale(1) rotate(0deg);
        }

        &.mfp-bg {
            opacity: 0.8;
        }

    }

    &.mfp-removing {
        .mfp-with-anim {
            transform: scale(0) rotate(500deg);
            opacity: 0;
        }

        &.mfp-bg {
            opacity: 0;
        }

    }

}

.mfp-move-horizontal {

    .mfp-with-anim {
        opacity: 0;
        transition: all 0.3s;
        transform: translateX(-50px);
    }

    &.mfp-bg {
        opacity: 0;
        transition: all 0.3s;
    }

    &.mfp-ready {
        .mfp-with-anim {
            opacity: 1;
            transform: translateX(0);
        }

        &.mfp-bg {
            opacity: 0.8;
        }

    }

    &.mfp-removing {
        .mfp-with-anim {
            transform: translateX(50px);
            opacity: 0;
        }

        &.mfp-bg {
            opacity: 0;
        }

    }

}

.mfp-move-from-top {

    .mfp-with-anim {
        opacity: 0;
        transition: all 0.2s;
        transform: translateY(-100px);
    }

    &.mfp-bg {
        opacity: 0;
        transition: all 0.2s;
    }

    &.mfp-ready {
        .mfp-with-anim {
            opacity: 1;
            transform: translateY(0);
        }

        &.mfp-bg {
            opacity: 0.8;
        }

    }


    &.mfp-removing {
        .mfp-with-anim {
            transform: translateY(-50px);
            opacity: 0;
        }

        &.mfp-bg {
            opacity: 0;
        }

    }

}

.mfp-3d-unfold {
    .mfp-content {
        perspective: 2000px;
    }


    .mfp-with-anim {
        opacity: 0;
        transition: all 0.3s ease-in-out;
        transform-style: preserve-3d;
        transform: rotateY(-60deg);
    }

    &.mfp-bg {
        opacity: 0;
        transition: all 0.5s;
    }

    &.mfp-ready {
        .mfp-with-anim {
            opacity: 1;
            transform: rotateY(0deg);
        }

        &.mfp-bg {
            opacity: 0.8;
        }

    }

    &.mfp-removing {
        .mfp-with-anim {
            transform: rotateY(60deg);
            opacity: 0;
        }

        &.mfp-bg {
            opacity: 0;
        }

    }

}


.mfp-zoom-out {


    .mfp-with-anim {
        opacity: 0;
        transition: all 0.3s ease-in-out;
        transform: scale(1.3);
    }

    &.mfp-bg {
        opacity: 0;
        transition: all 0.3s ease-out;
    }


    &.mfp-ready {
        .mfp-with-anim {
            opacity: 1;
            transform: scale(1);
        }

        &.mfp-bg {
            opacity: 0.8;
        }

    }


    &.mfp-removing {
        .mfp-with-anim {
            transform: scale(1.3);
            opacity: 0;
        }

        &.mfp-bg {
            opacity: 0;
        }

    }

}


.mfp-fade.mfp-bg {
    opacity: 0;

    -webkit-transition: all 0.15s ease-out;
    -moz-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
}

.mfp-fade.mfp-bg.mfp-ready {
    opacity: 0.8;
}

.mfp-fade.mfp-bg.mfp-removing {
    opacity: 0;
}

.mfp-fade.mfp-wrap .mfp-content {
    opacity: 0;

    -webkit-transition: all 0.15s ease-out;
    -moz-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
}

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
    opacity: 1;
}

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
    opacity: 0;
}

@include min-screen($screen__m) {
    #template-contact-form {
        .actions-toolbar {
            .primary {
                float:right;
                button {
                    margin:0;
                }
            }
        }
    }
}

@include max-screen($screen__m) {
    .popup_wrapper {
        max-width: 100%;
    }
    #template-age-verification {
        #age_verification_redirect {
            float:none;
            margin: 10px 0px 40px 0;
        }
        .popup-title {
            font-size: 35px;
            &:before {
                display:none;
            }
        }
        button {
            width:250px;
        }
        #age_verification_redirect {
            max-width:250px;
        }
    }
    .mfp-img-mobile .mfp-image-holder {
        padding-left: 0;
        padding-right: 0;
    }
    .mfp-img-mobile img.mfp-img {
        padding: 0;
    }
    .mfp-img-mobile .mfp-figure:after {
        top: 0;
        bottom: 0;
    }
    .mfp-img-mobile .mfp-figure small {
        display: inline;
        margin-left: 5px;
    }
    .mfp-img-mobile .mfp-bottom-bar {
        background: rgba(0, 0, 0, 0.6);
        bottom: 0;
        margin: 0;
        top: auto;
        padding: 3px 5px;
        position: fixed;
        box-sizing: border-box;
    }
    .mfp-img-mobile .mfp-bottom-bar:empty {
        padding: 0;
    }
    .mfp-img-mobile .mfp-counter {
        right: 5px;
        top: 3px;
    }
    .mfp-img-mobile .mfp-close {
        top: 0;
        right: 0;
        width: 25px;
        height: 25px;
        line-height: 25px;
        background: rgba(0, 0, 0, 0.6);
        position: fixed;
        text-align: center;
        padding: 0;
    }

}
@include max-screen($screen__s) {
    #template-age-verification {
        button {
            &#age_verification_no {
                display:block;
                margin: 0 auto;
            }
            &#age_verification_yes {
                margin-bottom:10px;
                margin: 10px auto;
                display:block;
            }
        }
    }
    #template-newsletter {
        .popup_content {
            >.newsletter {
                &:before {
                    height:200px;
                }
            }
        }
    }
    #template-hot-deals {
        max-height: 400px;
        overflow-y: scroll;
        .block-products-list {
            .product-item {
                width: 49%;
            }
            .block-title {
                margin: 30px 0;
                strong {
                    font-size:20px;
                }
            }
        }
    }
}
@include max-screen($screen__xs) {
    #template-age-verification {
        button {
            width: 100%;
        }
        #age_verification_redirect {
            max-width:100%;
        }
    }
}

