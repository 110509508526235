$icons-pagebuilder__font-name: 'pagebuilder-font';
$icons-pagebuilder__font-path: '../Magento_PageBuilder/fonts/pagebuilder-icons/pagebuilder-icons';

$icon-pagebuilder-caret-up__content: '\f104';
$icon-pagebuilder-caret-down__content: '\f101';
$icon-pagebuilder-caret-next__content: '\f102';
$icon-pagebuilder-caret-prev__content: '\f103';

@import 'icons';
@import 'layout';
//@import 'slick/slick';
@import 'content-type/import';

.cms-content-important {
    background-color: $color-white-smoke;
    color: $color-gray20;
    font-family: 'proxima-nova', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.1;
    margin: 0 0 35px -20px;
    padding: 20px;
}

.pagebuilder-full-width {
    float: left;
    width: 100%;
}

.pagebuilder-content-type {
    box-sizing: border-box;
    margin-bottom: 20px;
}

//
//  Styles for legacy content types
//  ---------------------------------------------

.pagebuilder-accordion {
    @include lib-data-accordion();
}

//
//  Responsive Classes
//  _____________________________________________

@include min-screen($screen__m) {
    .pagebuilder-mobile-only {
        display: none !important; // sass-lint:disable-line no-important
    }
}

@include max-screen($screen__m - 1) {
    .pagebuilder-mobile-hidden {
        display: none !important; // sass-lint:disable-line no-important
    }
}

