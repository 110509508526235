.page-main-full-width .row-slider {
    [data-content-type='row'][data-appearance='full-width'],
    [data-content-type='row'][data-appearance='full-bleed'],
    [data-content-type='row'][data-appearance='contained'] {
        margin: 0!important;
        padding: 0;
    }

    [data-content-type='row'][data-appearance='contained'] {
        max-width: 100%;
    }

    .slick-prev {
        left: $indent__base;

        [dir="rtl"] & {
            right: $indent__base;
        }
    }

    .slick-next {
        right: $indent__base;

        [dir="rtl"] & {
            left: $indent__base;
        }
    }
}

.page-main .row-slider {
    [data-content-type='row'][data-appearance='contained'],
    [data-content-type='row'][data-appearance='full-width'],
    [data-content-type='row'][data-appearance='full-bleed'] {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .slick-prev {
        left: 0;

        [dir="rtl"] & {
            right: 0;
        }
    }

    .slick-next {
        right: 0;

        [dir="rtl"] & {
            left: 0;
        }
    }
}

.row-slider {
    .slick-list,
    .slick-slide {
        padding: 0;
    }
}

//
//  Desktop
//  _____________________________________________
//

@media (min-width: $screen__m) {
    .page-main-full-width .row-slider {
        .slick-prev {
            left: $indent__xl;

            [dir="rtl"] & {
                right: $indent__xl;
            }
        }

        .slick-next {
            right: $indent__xl;

            [dir="rtl"] & {
                left: $indent__xl;
            }
        }
    }
}

