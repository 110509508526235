//
//  Full Width row appearance styles
//  _____________________________________________

[data-content-type='row'][data-appearance='full-width'] {
    box-sizing: border-box;

    .page-main-full-width & {
        padding-right: 0;
        padding-left: 0;
        margin-right: 0;
        margin-left: 0;
    }

    > .row-full-width-inner {
        box-sizing: border-box;
        margin-left: auto;
        margin-right: auto;
        max-width: $layout__max-width;
        width: 100%;
    }
}

.page-main {
    [data-content-type='row'][data-appearance='full-width'] {
        margin-left: -$layout-indent-main__s !important; // sass-lint:disable-line no-important
        margin-right: -$layout-indent-main__s !important; // sass-lint:disable-line no-important
    }
}

.page-main-full-width {
    .cta-banner,
    .cta-banner-details,
    .cta-double,
    .heroslider,
    .range-preview,
    .cms-page-section {
        margin-left: auto !important; // sass-lint:disable-line no-important
        margin-right: auto !important; // sass-lint:disable-line no-important
        max-width: $layout__max-width;
        width: 100%;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    [data-content-type='row'][data-appearance='full-width'] {
        background-attachment: scroll !important; // sass-lint:disable-line no-important

        > .row-full-width-inner {
            .page-main-full-width & {
                padding-left: $layout-indent-main__s;
                padding-right: $layout-indent-main__s;
            }
        }
    }
}

@include min-screen($screen__m) {
    [data-content-type='row'][data-appearance='full-width'] {
        .page-main & {
            margin-left: -$layout-indent-main__m !important; // sass-lint:disable-line no-important
            margin-right: -$layout-indent-main__m !important; // sass-lint:disable-line no-important
        }

        > .row-full-width-inner {
            .page-main-full-width & {
                padding-left: $layout-indent-main__m;
                padding-right: $layout-indent-main__m;
            }
        }
    }
}

@include min-screen($screen__xl) {
    [data-content-type='row'][data-appearance='full-width'] {
        .page-main & {
            margin-left: -$layout-indent-main__xl !important; // sass-lint:disable-line no-important
            margin-right: -$layout-indent-main__xl !important; // sass-lint:disable-line no-important
        }

        > .row-full-width-inner {
            .page-main-full-width & {
                padding-left: $layout-indent-main__xl;
                padding-right: $layout-indent-main__xl;
            }
        }
    }
}

