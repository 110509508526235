.country-selector-popup {

    &-wrapper.modal-popup {
        @include max-screen($screen__m) {
            @include lib-css(left, 0);
        }
    }

    &.modal-inner-wrap {
        @include lib-css(max-width, 1000px);

        overflow-y: auto;
        background-color: $color-sna-primario2;
        background-position: top center;
        background-repeat: no-repeat;
        max-height: 100%;

        @include max-screen($screen__m) {
            top: 0;
            bottom: 0;
            background-size: auto 100vh;
        }

        @include min-screen($screen__m) {
            background-size: cover;
        }
    }
    &-wrapper.modal-popup._show &.modal-inner-wrap {
        @include max-screen($screen__m) {
            transform: translateY(0);
        }
    }

    &__header,
    &__body {
        padding-left: $layout-indent-main__s;
        padding-right: $layout-indent-main__s;

        @include min-screen($screen__m) {
            padding-left: $layout-indent-main__m;
            padding-right: $layout-indent-main__m;
        }
        @include min-screen($screen__xl) {
            padding-left: $layout-indent-main__xl;
            padding-right: $layout-indent-main__xl;
        }
    }

    &__header {
        position: relative;
        padding-top: $indent__l;
        padding-bottom: $indent__l;

        @include min-screen($screen__m) {
            padding-bottom: $indent__xl;
        }
    }

    &__title {
        @include lib-heading(h1);
        margin-top: $indent__base;
        text-align: center;
        text-transform: uppercase;
        color: $color-sna-white;
        @include lib-typography-font-size-breakpoints(
            $font-size__base,
            $font-size__xxl,
            $font-size__xxl
        );
    }

    &__close {
        @include lib-button-as-link();
        position: absolute;
        top: 0;
        right: 0;
        @include lib-link-secondary(
            $_link-font-weight             : $font-weight__bold,
            $_link-color                   : $color-sna-white,
            $_link-color-visited           : $color-sna-white,
            $_link-color-hover             : $color-sna-secundario1,
            $_link-color-active            : $color-sna-white
        );
        svg {
            margin: 0.8rem;
        }
    }

    &__body {
        display: flex;
        justify-content: center;
        padding-bottom: $indent__m;
        flex-direction: row;
        flex-wrap: wrap;

        @include max-screen($screen__m) {
            > * {
                flex-basis: 100%;
            }
        }
    }

    &__countries {
        @include lib-font-size($font-size__base);
        @include max-screen($screen__m) {
            margin-bottom: $indent__base;
        }
    }

    &__continent-title {
        @include lib-button-expandable(
            $_button-expandable-display             : flex,
            $_button-expandable-padding             : $indent__s 0,
            $_button-expandable-color               : $color-sna-white,
            $_button-expandable-cursor              : text,
            $_button-expandable-font-size           : $font-size__base,
            $_button-expandable-font-weight         : $font-weight__bold,
            $_button-expandable-text-transform      : uppercase
        );
        &:focus,
        &:active,
        &:hover {
            color: $color-sna-primario1;
            @include min-screen($screen__m) {
                color: $color-sna-white;
            }
        }
        justify-content: space-between;
        width: 100%;
    }

    &__list {
        @include lib-list-reset-styles();
        margin-top: $indent__base;
        margin-bottom: $indent__s;

        @include min-screen($screen__m) {
            margin-bottom: $indent__l;
        }
    }

    @include min-screen($screen__m) {
        &__countries {
            margin-left: $indent__s;
            margin-right: $indent__s;
        }

        &__continent-title.activated {
             color: $color-sna-primario1;
         }

        &__list.two-column {
            column-count: 2;
        }
    }

    &__item {
        color: $color-sna-primario3;
        margin-bottom: $indent__xs;

        @include min-screen($screen__m) {
            break-inside: avoid;
            min-width: 160px;
        }
    }

    &__link {
        display: inline-block;
        padding: $indent__s 0;
        @include lib-link(
            $_link-color                   : $color-sna-white,
            $_link-color-visited           : $color-sna-white,
            $_link-color-hover             : $color-sna-white,
            $_link-color-active            : $color-sna-white,
        );

        @include min-screen($screen__m) {
            padding: $indent__xs 0;
        }
    }
}