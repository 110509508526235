@mixin _lib-landing-section-padding-top-and-bottom() {
    padding-top: $landing-section-padding-top;
    padding-bottom: $landing-section-padding-bottom;

    @include min-screen($screen__xl) {
        padding-top: $landing-section-padding-top--1440;
        padding-bottom: $landing-section-padding-bottom--1440;
    }
}

@mixin _lib-cms-page-body-headings() {
    .heading {
        @include lib-typography-light-heading();
        margin-top: 0;
        text-align: left;
    }

    .heading--alt {
        @include lib-css(font-weight, $font-weight__bold);
        text-transform: none;

        .heading__pre {
            @include lib-typography-light-heading();
        }
        .heading__pre {
            display: block;
            @include lib-css(margin-bottom, $indent__s);
        }
    }

    .heading--center {
        text-align: center;
    }

    .heading--spaced {
        @include _lib-landing-section-padding-top-and-bottom();
        margin-bottom: 0;
    }
}

@mixin lib-cms-page-picture-fixed-height() {
    @include max-screen($landing-section-bg-img-fixed-height__mediaquery) {
        height: $landing-section-fixed-height__s;
        overflow: hidden;

        > picture > img,
        > img,
        > iframe {
            max-height: 100%;
            max-width: none;
            margin-left: 50%;
            transform: translateX(-50%);
        }
    }
}

@mixin lib-landing-section() {
    @include _lib-landing-section-padding-top-and-bottom();

    &__title {
        @include lib-heading(h2);
        margin-top: 0;
        @include lib-typography__main__heading2();
        text-align: center;
        text-transform: uppercase;
    }

    &__pretitle {
        display: block;
        margin: 0 auto $indent__s;

        & + br {
            display: none;
        }
    }

    &__title--light {
        @include lib-css(font-weight, $font-weight__hairline);
    }

    &__subtitle {
        @include lib-typography__main__heading2();
        @include lib-typography-light-heading();
        text-align: center;
    }

    &__title + [class$="__subtitle"] {
        margin-top: 0;
    }

    &__body {
        @include lib-layout-columnify(2);
        @include lib-typography__main__text();

        section,
        .section {
            margin-bottom: 2em;
            break-inside: avoid;

            > :last-child,
            &:last-child {
                margin-bottom: 0;
            }

        }

        .actions {
            display: flex;
            justify-content: space-around;
            margin-top: 2.1em;
        }

        @include _lib-cms-page-body-headings();
    }


    /* Columns: Component */
    &.has-one-col {
        @include lib-landing-section--one-col();

        @include min-screen($screen__l) {
            &--left {
                .cms-page-section {
                    &__body {
                        width: 48%;
                    }
                }
            }
        }
    }

    /* Columns: Component children */
    .has-one-col {
        @include lib-layout-columnify(1);
    }
    .has-two-cols {
        @include lib-layout-columnify(2);
    }

    &:empty {
        display: none;
    }
}

@mixin lib-landing-section--extra-indent() {
    @include min-screen($screen__xl) {
        @include lib-css(padding-left, $indent__xxl);
        @include lib-css(padding-right, $indent__xxl);
    }
}

@mixin lib-landing-section--full-width($_extra-indent-on-desktop: false) {
    @include lib-layout-indent-main-inverse();

    $_indent-main__s: $layout-indent-main__s;
    $_indent-main__m: $layout-indent-main__m;
    $_indent-main__xl: $layout-indent-main__xl;
    @if $_extra-indent-on-desktop == true {
        $_indent-main__xl: $layout-indent-main__xl * 2;
    }

    @include lib-layout-indent(
        $_layout-indent__s__left        :  $_indent-main__s,
        $_layout-indent__s__right       :  $_indent-main__s,
        $_layout-indent__m__left        :  $_indent-main__m,
        $_layout-indent__m__right       :  $_indent-main__m,
        $_layout-indent__xl__left       :  $_indent-main__xl,
        $_layout-indent__xl__right      :  $_indent-main__xl,
        $_property                      :  "padding"
    );

    /** sections anidadas */
    [class*="-section__body"] > & {
        @include lib-layout-indent(
            $_layout-indent__s__left        :  -$_indent-main__s,
            $_layout-indent__s__right       :  -$_indent-main__s,
            $_layout-indent__m__left        :  -$_indent-main__m,
            $_layout-indent__m__right       :  -$_indent-main__m,
            $_layout-indent__xl__left       :  -$_indent-main__xl,
            $_layout-indent__xl__right      :  -$_indent-main__xl,
            $_property                      :  "margin"
        );
        @include lib-layout-indent(
            $_layout-indent__s__left        :  $_indent-main__s,
            $_layout-indent__s__right       :  $_indent-main__s,
            $_layout-indent__m__left        :  $_indent-main__m,
            $_layout-indent__m__right       :  $_indent-main__m,
            $_layout-indent__xl__left       :  $_indent-main__xl,
            $_layout-indent__xl__right      :  $_indent-main__xl,
            $_property                      :  "padding"
        );
    }
}

@mixin lib-landing-section--one-col($blockName: "cms-page-section") {
    .#{$blockName}__body {
        @include lib-layout-columnify(1);
    }
}

@mixin lib-landing-theme-dark() {
    @include lib-css(background-color, $color-sna-primario2);
    @include lib-css(color, $color-white);
}

@mixin lib-landing-theme-light() {
    @include lib-css(background-color, $color-sna-primario4);
    @include lib-css(color, $color-sna-primario2);
}

.cms-page-section,
.landing-section,
.home-section {
    @include lib-landing-section();
}

/**
* 1. En la home las secciones no tienen un indentado extra en pantallas grandes.
*/
.cms-page-section,
.landing-section {
    @include lib-landing-section--extra-indent(); /* [1] */
}

.home-section {
    @include lib-landing-section--one-col("home-section");
    &__title {
        text-align: left;
    }
}

/**
 *  SECTION THEMES
 * ------------------------------------------------------------------------- */
.landing-section,
.cms-page-section {
    &.theme-dark,
    &.theme-light,
    &--dark,
    &--light {
        @include lib-landing-section--full-width(true);
    }
}

.home-section {
    &.theme-dark,
    &.theme-light {
        @include lib-landing-section--full-width(false);
    }
}

.cta-herobanner,
.cms-page-section {
    &:not([data-theme="theme-dark"]):not([data-theme="theme-light"]) {
        .action.primary {
            @include lib-button-primary-over-dark-bg();
        }
    }
}

.cta-banner-details,
.column-slider,
.cta-banner,
.cta-banner,
.cta-double,
.heroslider,
.range-preview,
.row-slider,
.cms-page-section,
.home-section {
    &[data-theme="theme-dark"],
    &.theme-dark,
    &--dark {
        @include lib-landing-theme-dark();
    }

    &[data-theme="theme-light"],
    &.theme-light,
    &--light {
        @include lib-landing-theme-light();
    }
}

[data-content-type="row"][data-appearance="full-width"] {
    @include lib-landing-section--full-width(false);
}

/**
*  SECTION MODIFIERS
* ------------------------------------------------------------------------- */
.landing-section,
.cms-page-section,
.home-section {
    &--center-content,
    &--compact-content {
        @include lib-landing-section--one-col();

        .cms-page-section__title {
            text-align: center;
        }

        .cms-page-section__body {
            text-align: center;
        }

        @include min-screen($screen__l) {
            .cms-page-section__body {
                max-width: 75%;
                margin: 0 auto;
            }
        }
    }
    &--compact-content {
        padding-top: 1vw;
        padding-bottom: 1vw;
    }

    &--collapse {
        padding-top: $landing-section-padding-top / 2;
        padding-bottom: $landing-section-padding-bottom / 2;

        &:first-of-type {
            padding-top: $landing-section-padding-top;
        }

        .cms-page-section__body > & {
            padding-left: 0;
            padding-right: 0;

            &:last-child {
                margin-bottom: -$landing-section-padding-bottom / 2;
            }
        }

        @include min-screen($screen__xl) {
            padding-top: $landing-section-padding-top--1440 / 2;
            padding-bottom: $landing-section-padding-bottom--1440 / 2;

            &:first-of-type {
                padding-top: $landing-section-padding-top--1440;
            }

            .cms-page-section__body > & {
                &:last-child {
                    margin-bottom: -$landing-section-padding-bottom--1440 / 2;
                }
            }
        }
    }
}


.cms-page-section.icon-list {
    @include lib-layout-indent-main-inverse();
    @include lib-layout-indent-main();
    @include lib-css(background-color, $color-sna-primario2);
    @include lib-css(color, $color-white);
    text-align: center;
    @include lib-landing-section--one-col("cms-page-section");

    ul {
        @extend .abs-reset-list;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        @include lib-css(margin-top, $indent__xxl);
        margin-bottom: -3.2em;

        li,
        .ico-txt {
            margin-bottom: 3.2em;
            display: flex;
            flex-direction: column;

            > * {
                width: 80%;
                margin: 1.6em auto;

                &:first-child {
                    margin-top: 0;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }

            > svg,
            .ico-txt__icon {
                display: block;
                height: 4em;
                width: 4em;
            }

            .ico-txt__icon {
                position: relative;

                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            .ico-txt__title {
                @include lib-css(font-weight, $font-weight__bold);
                margin-top: 0;
                margin-bottom: 0;
            }
        }
    }

    @include min-screen($screen__m) {
        ul {
            flex-wrap: nowrap;
            flex-basis: 50%;

            > * {
                margin-bottom: 0;
            }
        }
    }

    @include min-screen($screen__xl) {
        > * {
            @include lib-css(margin-left, $indent__xxl);
            @include lib-css(margin-right, $indent__xxl);
        }

        ul {
            @include lib-css(margin-top, $indent__xxxxl);
        }
    }
}

.cms-page-section.has-bottom-img {
    display: flex;
    flex-direction: column;
    padding-bottom: 0;

    > img:last-child {
        margin: $indent__xl auto 0;
    }
}

/**
 *  CUSTOMIZE BY LOCATION
 * ------------------------------------------------------------------------- */
 .cms-page-section.page-home {
     @include min-screen($screen__xl) {
        .cms-page-section__title {
            @include lib-font-size(48);
        }
    }
}


/**
 *  MINOR ADJUSTMENTS
 * ------------------------------------------------------------------------- */

.cms-page-section .block-products-list:last-child {
    margin-bottom: 0;
}

.cms-page-section .cta-banner.product {
    background-color: inherit;
}

.home-section {
    padding-left: 0;
    padding-right: 0;
}

.main > [data-content-type="products"] {
    @include _lib-landing-section-padding-top-and-bottom();
}
