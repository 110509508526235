@mixin on4menu-mobile-menu-item() {
    @include on4menu-mobile {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: auto;
        cursor: pointer;
        padding: $indent__s 0;
    }
    &__figure,
    &__text {
        display: inline-block;
    }
}
@mixin on4menu-mobile-products-menu-item() {
    @include on4menu-mobile-menu-item();
    @include on4menu-mobile {
        &--level--1 {
            @include _on4menu-mobile-products-menu-item--level--1();
        }

        &--level--2 {
            @include _on4menu-mobile-products-menu-item--level--2();
        }

        &--level--3 {
            @include _on4menu-mobile-menu-item--level--3();
        }
    }
}
@mixin on4menu-mobile-categories-menu-item() {
    @include on4menu-mobile-menu-item();
    @include on4menu-mobile {
        &--level--1,
        &--level--2 {
            @include _on4menu-mobile-menu-item--level--1();
        }

        &--level--3 {
            @include _on4menu-mobile-menu-item--level--3();
        }
    }
}

@mixin _on4menu-mobile-menu-item--level--1() {
    @include lib-link-secondary(
        $_link-font-weight: $font-weight__bold,
        $_link-text-transform: uppercase
    );
}
@mixin _on4menu-mobile-products-menu-item--level--1() {
    @include lib-link-secondary(
        $_link-font-weight: $font-weight__bold,
        $_link-text-transform: uppercase
    );
}
@mixin _on4menu-mobile-products-menu-item--level--2() {
    @include lib-link-secondary(
        $_link-font-weight: $font-weight__heavier
    );
}

@mixin _on4menu-mobile-menu-item--level--3() {
    padding: $indent__s 0;
}

@mixin on4menu-mobile-menu-item--level--1() {
    @include on4menu-mobile {
        @include _on4menu-mobile-menu-item--level--1();
    }
}
@mixin on4menu-mobile-products-menu-item--level--1() {
    @include on4menu-mobile {
        @include _on4menu-mobile-products-menu-item--level--1();
    }
}

@mixin on4menu-mobile-menu-item--level--3() {
    @include on4menu-mobile {
        @include _on4menu-mobile-menu-item--level--3();
    }
}