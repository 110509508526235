.fotorama__nav__shaft {
    @extend .fotorama-inline-block;
    @extend .fotorama-no-tap;
    position: relative;
    top: 0;
    left: 0;
    text-align: left;

    .fotorama__wrap--css3 & {
        @extend .fotorama-transition-for-slide;
    }

    .fotorama__nav-wrap--vertical & {
        width: 100%;
    }
}

@include min-screen($screen__m) {
    .fotorama__nav__shaft {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
}
