.cms-alt-ergo {
    .cms-page-header {
        &__subtitle {
            @include lib-font-size($font-size__s);
            
            @include min-screen($screen__l) {
                @include lib-font-size($font-size__l);
            }

            img {
                @include lib-css(margin-bottom, $indent__m);
            }
        }
    }

    .ergo-process {
        @include lib-css(background-color, $color-sna-primario4);
        @include lib-layout-indent-main-inverse();
        
        > * {
            @include lib-layout-indent-main();
        }
    }

    .ergonomic-science {
        @include lib-landing-section--one-col();
    }
}