.heroslider {
  @include lib-layout-indent-main-inverse();

  &.slick-dotted {
    margin-bottom: 0;
  }

  .slick-list,
  .slick-slide {
    padding: 0;
  }

  .slick-dots {
    bottom: 1em;
    font-size: 10px;

    > li {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 40px;
      margin: 0;
      background-color: hsla(0,0%,0%,.7);

      > button {
        position: relative;
        font-size: inherit;
        height: 100%;
        width: 100%;

        &::before {
          content: '';
          width: 1em;
          height: 1em;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: $color-sna-secundario3;
          border-radius: 50%;
          font-size: 1em;
        }
      }

      &.slick-active button:before {
          background-color: $color-sna-white;
      }
    }
  }

  .cta-herobanner {
    @include lib-layout-indent-main-inverse-reset();
    display: block !important;

    &:not([data-theme="theme-light"]) .cta-banner__title {
      @include lib-css(text-shadow, 2px 2px #333);
    }
    &:not([data-theme="theme-light"]) .cta-banner__body-content {
      > p {
          @include lib-css(text-shadow, 1px 1px $color-sna-primario2);
      }
    }
  }

  > .cta-herobanner:not(:first-of-type) {
    @include lib-visually-hidden();
  }
}
