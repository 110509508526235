@import 'mkcss/amlocator.extended';
@import 'mkcss/premium_partners';

.amlocator-store {
    &__distributor-icon {
        color: $color-sna-primario1;
        width: 1.6rem;
        height: 1.6rem;
    }
    &__distributor-icon,
    &__sales-rep-icon {
        margin-right: $indent__s;
        vertical-align: text-bottom;
    }
    &__sales-rep-icon {
        width: 1.2rem;
        height: 1.6rem;
        margin-right: $indent__s;
    }
    &__sales-rep-text {
        color: $color-sna-primario1;
    }
}
.amlocator-salesrep-filter {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    border-bottom: 1px solid $color-sna-primario1;
    padding-bottom: $indent__s;
    margin-bottom: $indent__xs/2;

    &__item {
        color: $color-sna-primario1;
        font-weight: $font-weight__bold;
        text-transform: uppercase;
    }
    &__label.amlocator-salesrep-filter__label {
        align-items: center;
    }
    &__icon {
        width: 1.6rem;
        height: 1.6rem;
        margin: $indent__s;
    }
}