.betms-trolley-pdf{
    color-adjust:exact;
    -webkit-print-color-adjust: exact;
    .img-logo-mobile,
    .panel.wrapper,
    #back-to-top,
    #top-to-back,
    .copyright
    {
        display: none;
    }
    h2{
        padding: 10px;
        text-align: left;
        font-size: 2.5rem;
        background: $color-sna-primario2 !important;
        color: $color-sna-primario4 !important;
    }
    .pagebreak { page-break-before: always; }
    .betms-trolley{
        margin-top: 20px;
        h3{
            font-size: 2rem;
            margin-top: 4.2rem;
        }
        .model{
            h3{
                font-weight: 400;
            }
            > span {
                display: block;
                font-size: 1.5rem;
            }
            .subtotal{
                border-top: 1px solid;
                text-align: right;
                margin-top: 20px;
                padding-top: 20px;
            }
        }
        .trolley-model{
            column-count: 2;
            .preview-image {
                padding: 0 20px;
            }
        }
        .table-content {
            padding: 20px;
        }
        .content > tbody > tr:nth-child(odd) {
            background: $color-gray89 !important;
        }
        .total .content > tfoot > tr {
            background: $color-sna-primario2 !important;
            strong, span{
                color: $color-sna-primario4 !important;
                font-size: 2rem;
            }
        }
        td{
            vertical-align: middle;
            &.table-image{
                 width: 150px;
                .item-image {
                    padding: 10px;
                }
            }
        }
        tfoot{
            text-align: right;
        }
        .item-content{
            span{
                display: block;
                font-weight: 600;
                &.sku{
                    color: $color-sna-primario1 !important;
                }
                &.size span{
                    display: inline-block;
                    padding: 5px;
                    border: 1px solid;
                    border-radius: 20px;
                }
            }
        }
    }
}