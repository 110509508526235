.cms-cordless,
.cms-alt-cordless {
    img.cms-page-section__pretitle {
        height: 3em;

        @include min-screen($screen__xl) {
            height: 2em;
        }
    }

    .slick-dots {
        position: relative;
        bottom: inherit;
        margin-bottom: .6em;
        
        button {
            position: relative;
        }

        button::before {
            content: '';
            display: block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: $color-sna-primario3;
            opacity: inherit;
        }
        
        .slick-active button::before {
            background-color: $color-sna-primario2;
        }
    }
    
    .slick-dotted.slick-slider {
        margin-bottom: 0;
    }

    .slick-arrow {
        font-size: 1em;
        top: 15em;
    }
}