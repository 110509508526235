.mn-children__heading {
    text-transform: uppercase;
    font-weight: $font-weight__heavier;
    @include on4menu-desktop {
        display: block;
    }
}

.mn-children__content {
    @include on4menu-mobile {
        @include lib-css(box-shadow, $box-shadow-sna3);
        background-color: $color-sna-white;
        position: absolute;
        top: 0 !important; /* Don't use style="top: ..." setted via JS (it's only for desktop) */
        right: 0;
        bottom: 0;
        left: 0;
        padding: $indent__s $indent__l 0;
        transform: translateX(100%);
        transition: transform 0.3s;
        /*max-height: none !important; /* Don't use style="max-height: ..." setted via JS (it's only for desktop) */
        overflow-x: hidden;

        &.is-expanded {
            transform: translateX(0);

            .mn-submenu__header {
                border-bottom: 1px solid $color-sna-primario4;
            }
            .mn-category-button__arrow--flechaizda {
                display: inline-block;
            }
        }
    }
}

.mn-children__item-list {
    margin: 0;
    padding: 0;
}

.mn-children__item {
    @include on4menu-mobile {
        margin-bottom: 0;
    }
}

//
// List of small banners
// ----------------------------------------------------------------------------
@include on4menu-desktop {
    .mn-children__item-list--has-banner-sm {
        display: block;
    }
}