.panel.wrapper {
    background-color: $color-sna-black;

    .header.panel {
        text-align: right;

        .header-top-links {
            span {
                margin-right: $indent__l;
            }
            .arrow {
                margin-left: $indent__s;
            }
        }
        .country-selector {
            margin: (-$indent__s) 0 (-$indent__s) $indent__xl;
            padding: $indent__s 0;
            position: relative;
        }
        .country-selector__back-btn {
            display: none;
        }
        .header-top-links,
        .country-selector {
            display: inline-block;
            a {
                @include lib-link-light();
                padding: $indent__xs $indent__s;
                @include lib-font-size($font-size__s);
            }

            .arrow-left {
                display: none;
            }

            .country-selector__header,
            span {
                position: relative;
                padding: $indent__xs $indent__s;
                cursor: pointer;

                @include lib-link-light();
                @include lib-font-size($font-size__s);

                > span {
                    vertical-align: middle;
                    color: inherit;
                    text-transform: uppercase;
                }

                .nav-header-top {
                    left: -50%;
                    right: -50%;
                }
            }

        }

        .country-selector .country-selector__header {
            display: inline;
        }

        .country-selector .nav-country-selector {
            left: -230%;
            right: -15%;
            column-count: 2;
        }

        .header-top-links .nav-header-top,
        .country-selector .nav-country-selector {
            position: absolute;
            background-color: $color-sna-black;
            text-align: center;
            top: 100%;
            z-index: 21;
            display: none;
            padding: $indent__xl 0 $indent__l;
            box-shadow: $box-shadow-sna1;

            &.activated {
                display: block;
            }
            ul {
                padding: 0 $indent__xs;
                margin: 0;
                li {
                    list-style: none;
                    a, a > span {
                        @include lib-css(font-weight, $font-weight__heavier);
                    }
                }
                li:not(:last-child) {
                    margin-bottom: $indent__m;
                }
                li:last-child {
                    margin-bottom: 0;
                }
            }
        }
        .social-networks {
            display: inline-block;
            margin-left: $indent__xl;
            a {
                @include lib-link-light();
            }
        }
    }
}
