.fotorama__thumb-border {
    @extend .fotorama-print-background;
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    backface-visibility: hidden;
    z-index: 0;
    background-color: $color-sna-primario3;

    .fotorama__nav--dots & {
        display: none;
    }

    .fotorama__wrap--css3 & {
        @extend .fotorama-transition-for-slide;
    }
}
