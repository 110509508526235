@mixin lib-video-container($aspect-ratio: 16/9) {
    @include _lib-video-mixed-media-container($aspect-ratio);
}

@mixin lib-video-iframe-container($aspect-ratio: 16/9) {
    @include _lib-video-mixed-media-container($aspect-ratio);
}

@mixin _lib-video-mixed-media-container($aspect-ratio: 16/9) {
    position: relative;
    max-width: 100%;
    margin: 0 auto;
    overflow: hidden;

    &::before {
        content: '';
        display: block;
        padding-top: (1/$aspect-ratio) * 100%;
    }

    > img,
    > iframe,
    > video {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border: 0;
        width: 100%;
        height: 100%;
    }
    
    /**
     * IE11 object-fit-images polyfill
     */
    > img {
        @include object-fit(cover);
    }

    /**
     * IE11 object-fit-video polyfill
     */
    > object-fit {
        position: absolute !important;
        top: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        left: 0 !important;
        border: 0 !important;
        width: 100% !important;
        height: 100% !important;
        @include object-fit(cover);
    }
    > video {
        @include object-fit(cover);
    }
}