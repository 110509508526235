.careers {
    .two-cols {
        column-count: inherit;

        @include min-screen($screen__l){
            display: grid;
            grid-template-columns: 1fr 1fr;
        }

        :first-child {
            grid-column: 1/2;
        }

        :last-child {
            grid-column: 2/3;
            display: flex;
            flex-direction: column;

            & > .field {
                flex: 1 1 50%;
                display: flex;
                flex-direction: column;

                & > .control {
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;

                    > * {
                        flex-grow: 1;
                    }
                }
            }
        }
    }

    .field {
        @include lib-form-field();
    }

    .send-resume {
        @include lib-css(padding, $indent__xxl);
        @include lib-css(background-color, $color-sna-primario4);

        &__title {
            @include lib-css(margin-bottom, $indent__base);
            @include lib-font-size($font-size__base);
            @include lib-css(font-weight, $font-weight__hairline);
            text-transform: uppercase;

            @include min-screen($screen__m) {
                @include lib-font-size($font-size__l);
            }
        }

        &__subtitle {
            @include lib-css(margin-bottom, $indent__base);
        }
    }

    .input-file-button {

        label {
            @include lib-button();
            background-color: transparent;
            margin-bottom: 0 !important;
        }

        input {
            @extend .abs-visually-hidden;
        }
    }

    .terms-conditions {
        display: flex;
        justify-content: center;
    }

    .actions {
        text-align: center;
    }
}
