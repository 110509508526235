.cms-video-section {
    @include lib-layout-indent-main-inverse();

    .cms-video-section__body {
        text-align: center;
    }

    .cms-video-section__video {
        width: 1008px;
        @include lib-video-iframe-container();
    }

    &.theme-black {
        background-color: $color-sna-black;
    }
}

.pagebuilder-video-wrapper {
    .pagebuilder-video-container {
        @include lib-video-iframe-container();
    }
}
