.cms-page-section--bahco-world {
    .cms-page-section__title {
        text-align: left;
    }
}

.bahco-world {

  &__social-network-name {
    font-size: 1em;
    @include lib-css(font-weight, $font-weight__hairline);
    margin-bottom: .8em;
  }

  &__video {
      .video-wrapper {
          @include lib-video-iframe-container();
      }
  }

  .media-slider-wrapper-title { display: none }
  .slick-list {
      padding: 0;
      margin: 0 -5px;
  }
  .slick-arrow::before { display: none !important; }
  .slick-arrow svg {
      height: 48px;
      width: 48px;
      color: white;
  }

  @include max-screen($screen__l-2) {
      text-align: center;

      & > * {
          margin-bottom: 48px;
      }

      &__fb {
          margin-left: auto;
          margin-right: auto;
      }
  }

  @include min-screen($screen__l-2) {
      display: grid;
      gap: 48px;
      grid-template-columns: 1fr 2fr;
      grid-template-rows: auto auto;
      grid-template-areas:
          "fb video"
          "fb instagram";
      @include lib-layout-indent-main();

      &__video {
          grid-area: video;
          padding: 0 26px;
      }

      &__fb {
          grid-area: fb;
      }

      &__instagram {
          grid-area: instagram;
          overflow: hidden;
          padding: 0 26px;
      }
  }

  @include min-screen($screen__xl) {
      gap: 92px;
  }
}
