.gallery-placeholder {
    .loading-mask {
        position: static;
        padding: 0 0 565px !important;
    }

    .loader img {
        position: absolute;
    }
}
