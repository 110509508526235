.pages {
    @include lib-pager();

    .action {
        width: 34px;

        &.previous {
            margin-right: 12px;
        }

        &.next {
            margin-left: 12px;
        }
    }
}

#ot-sdk-cookie-policy #ot-sdk-cookie-policy-v2 {
    table {
        display: block;
        overflow-y: auto;
    }

    #cookie-policy-title {
        @include lib-heading(h3);
        color: $color-black;
    }

    #cookie-policy-description,
    .ot-sdk-cookie-policy-group-desc {
        color: $color-black;
        font-size: $font-size__base;
    }

    .ot-sdk-cookie-policy-group {
        @include lib-heading(h4);
        color: $color-black;
    }

    table thead .ot-table-header {
        background-color: $color-black;
        color: $color-white;
        font-size: $font-size__base;
    }

    table tbody td {
        color: $color-black;
        font-size: $font-size__base;
    }
}