//
// Small banner
// ----------------------------------------------------------------------------
.mn-banner-sm {
    @include on4menu-mobile-categories-menu-item();
    @include lib-button-as-link(
        $_padding: $indent__s 0
    );
    @include lib-link-secondary();
}

@include on4menu-mobile {
    .mn-banner-sm {
        &__figure {
            display: block;
            width: 1.5em;
            height: 1.5em;
            margin-right: 1em;
        }
    }
}

@include on4menu-desktop {
    .mn-banner-sm {
        display: flex;
        width: auto;

        &__figure {
            align-items: center;
            display: flex;
            width: 1.5em;
            height: 1.5em;
            margin-right: 1em;
        }
        &__img {
            display: block;
            width: 100%;
        }
    }
}