/**
 * Returns a fluid value between two points in the viewport variable dimension.
 * This linear function can be applied to any property (width, font-size, ...).
 * @param {Number} $v1 - Value at init point - Linear function's Y1
 * @param {Number} $v2 - Value at end point - Linear function's Y2
 * @param {Number} $init - Init point in pixels - Linear function's X1
 * @param {Number} $end - End point in pixels - Linear function's X2
 * @param {Number} $var-dim - Variable dimension: 100vw as default, or 100vh, 100%
 * examples:
 * width: linear-fluid-value(70vw, 58vw, 810px, 1024px);
 * padding-bottom: linear-fluid-value(24px, 48px, 1025px, 1920px, 100%);
 * font-size: linear-fluid-value(1rem, 2rem, 320px, 768px),
 * letter-spacing: linear-fluid-value(0, 1px, 768px, 1024px),
 */

@function linear-fluid-value($v1, $v2, $init: 320px, $end: 1920px, $var-dim: 100vw) {
  $u1: unit($v1);
  $u2: unit($v2);

  // If both values are unitless, assume px
  // (i.e. due to the stylelint autofixing 0px into 0).
  @if $u1 == '' and $u2 == '' {
    $u1: 'px';
    $u2: 'px';
    $v1: add-unit($v1, $u1);
    $v2: add-unit($v2, $u2);
  }
  // Else if one value is unitless use the unit from the other value
  @else if $u1 == '' {
    $v1: add-unit($v1, $u2);
    $u1: $u2;
  }

  @else if $u2 == '' {
    $v2: add-unit($v2, $u1);
    $u2: $u1;
  }

  // Both value unit must be the same so the linear formula works.
  @if ($u1 != $u2) {
    @error 'Property $v1 and $v2 must use the same unit.';
  }

  @if $var-dim != 100vw and $var-dim != 100vh and $var-dim != 100% {
    @error 'Optional argument $var-dim must be 100vw (default), 100vh or 100%.';
  }

  // take unit from 1st value for simplicity
  // (they should be the same now)
  $unit: $u1;

  // When used to make fluid font-size using rem units, we need to
  // convert to px so calc can work with the two breakpoint values.
  @if $unit == 'rem' {
    $v1: px-from-rem($v1);
    $v2: px-from-rem($v2);
  }

  // When used with other fluid units, we need to
  // convert to px so calc can work with the two breakpoint values.
  @if $unit == 'vw' or $unit == 'vh' or $unit == '%' {
    $v1: ($v1 / (100 * map-get($units, $unit))) * $init;
    $v2: ($v2 / (100 * map-get($units, $unit))) * $end;
  }

  // slope = ( y2 - y1 ) / ( x2 - x1 )
  $slope: ($v2 - $v1) / ($end - $init);

  // y2 = slope * x1 + intercept
  $intercept: $v1 - ($init * $slope);

  // f(x) = slope * x + intercept
  @return calc(#{$slope} * #{$var-dim} + #{$intercept});
}
