.cms-alt-responsibility-sustainability {
    .highlights {
        background-position: bottom right;
        background-repeat: no-repeat;
        background-size: contain;

        header {
            position: relative;

            img {
                margin-bottom: 1em;
            }

            @include min-screen($screen__s) {
                display: flex;
                align-items: center;
                transform: translateY(-25%);

                img {
                    margin-bottom: 0;
                }

                .cms-page-section__title {
                    position: absolute;
                    left: 0;
                    right: 0;
                }
            }

            @include min-screen($screen__l) {
                transform: translateY(-50%);
            }
        }

        .cms-page-section__body {
            column-gap: 20%;
            max-width: 80%;
            margin: 0 auto;
            clear: left;
        }

        dl {
            margin-bottom: -3.5em;

            dt {
                @include lib-font-size(3.8em);
                @include lib-css(color, $color-sna-primario1);
            }
            
            dd {
                margin-bottom: 3.5em;
            }
        }
    }
}