a.action.primary,
button {
    @include lib-css(border-radius, $button__border-radius);
}
button {
    &:active {
        @include lib-css(box-shadow, $button__shadow);
    }
}

a.action {
    @include lib-link-as-button();
}

.action.primary {
    @include lib-button-primary();
    @include lib-css(min-width, 25.6rem);
}

.action.towishlist {
    @include lib-button-secondary();
}

.action.primary.small,
.action.primary-small {
    @include lib-button-primary-small();
}

.action.remind,
.action.edit {
    @include lib-link-secondary(
        $_link-text-decoration         : underline,
        $_link-color-hover             : $color-sna-secundario1,
        $_link-text-decoration-hover   : underline
    );
}
.action.secondary {
    @include lib-button();
    @include lib-css(min-width, 25.6rem);
}

.btn-arrow,
.action.go {
    @include lib-button-go();

    &.white {
        @include lib-button-go--white();
    }
}

button.action.back {
    @include lib-button-as-link();
}
button.action.back,
a.action.back {
    @include lib-button-go-back();
}

.btn-expandable {
    @include lib-button-expandable();
}

.btn-special,
a.btn-special {
    @include lib-button-special();
}

.btn-special-inverted,
a.btn-special-inverted {
    @include lib-button-primary-over-dark-bg();
}

.btn-buy-now {
    background-color: $color-black;
    color: $color-white;
    padding: 4px 8px;

    .product-items & {
        @include lib-button-special(
            $_button-min-width: unset,
            $_button-padding: 4px 8px
        );
    }

    .btn-buy-now__arrow,
    .btn-buy-now__cart {
        margin-right: 8px;
        transition: transform .5s;
    }

    .btn-buy-now__cart {
        margin-right: 0;
    }

    &:active,
    &:hover {
        background-color: $color-white;
        color: $color-sna-primario1;

        .btn-buy-now__arrow {
            color: $color-black;
            transform: translateX(33%)
        }

        .btn-buy-now__cart {
            transform: translateX(-33%)
        }
    }

    &[disabled] {
        border: none;
        background: none;
        color: $color-black;
        line-height: 26px;

        > svg {
            display: none;
        }

        &::after {
            display: inline;
            content: "-"
        }

        &:active,
        &:hover {
            border: none;
            background: none;
            color: $color-black;
        }
    }
}

.uploadcare-widget-button.uploadcare-widget-button {
    @include lib-button-secondary();

    border-radius: 0;
    margin: 0 0   $indent__s;
}
