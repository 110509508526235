.mn-category-button {
    @include lib-button-as-link(
        $_link-color       : $color-sna-primario2,
        $_link-color-hover : $link__color
    );
    @include on4menu-mobile-categories-menu-item();

    width: 100%;
    font-size: inherit;
    text-align: left;

    @include on4menu-mobile {
        padding-left: 0;
        padding-right: 0;

        .arrow--expand,
        .arrow--collapse {
            display: none;
        }
    }
}
.mn-submenu--products-menu .mn-category-button {
    @include on4menu-mobile-products-menu-item();
}

.mn-category-button:not(&--level--1) {
    @include on4menu-desktop {
        display: none;
    }
}

.mn-category-button__arrow,
.mn-banner__arrow {
    width: $on4menu-icon-width;
    height: $on4menu-icon-width;
    margin: 0;

    &--flechaizda {
        display: none;
        width: 2.6rem;
        margin-right: $indent__s;
    }
    @include on4menu-mobile {
        &--arrow-right {
            margin-left: $indent__xs;
        }
    }
    @include on4menu-desktop {
        &--arrow-right {
            display: none;
        }
    }
}

//
// Variante: ButtonBack
// ----------------------------------------------------------------------------
.mn-submenu--products-menu .mn-category-button--back,
.mn-category-button--back {
    width: auto;
    flex-grow: 2;
    color: $color-sna-primario1;

    &:hover {
        overflow: visible;
        .mn-category-button__arrow--flechaizda {
            @include lib-animations-bounce-left();
        }
    }
  
    .mn-category-button__arrow,
    .mn-banner__arrow {
        &--left {
            display: inline;
            margin-right: 1em;
        }

        &--arrow-right {
            display: none;
        }
    }

    @include on4menu-mobile {
        margin-left: -$indent__base;
        .mn-category-button__text-desktop {
            display: none;
        }
    }

    @include on4menu-desktop {
        display: none;
        .mn-category-button__text {
            display: none;
        }
    }
}

//
// Variante: BtnSpecial
// ----------------------------------------------------------------------------
.topmenu-main__item-link--last .btn-special {
    min-width: auto;
    max-width: 100%;

    @include on4menu-desktop {
        > svg {
            margin-right: 0;
        }
        > span {
            display: none;
            width: calc(100% - 32px);
            line-height: normal;
        }
    }
    @include on4menu-desktop-normal {
        > svg {
            margin-right: $indent__s;
        }
        > span {
            display: inline-block;
        }
    }
}

//
// Variante: View all
// ----------------------------------------------------------------------------
.mn-submenu__view-all,
.mn-submenu__view-all.action.primary,
.mn-submenu .view-all {
    @include lib-css(border-radius, $button__border-radius);
    @include lib-button-primary();

    @include on4menu-mobile {
        margin-top: $indent__s;
        margin-bottom: $indent__s;
    }
    @include on4menu-desktop {
        margin-top: $indent__base;
        min-width: 0;
    }
}