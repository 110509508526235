.width-limit-1440 {
    box-sizing: border-box;
    width: 100%;
    max-width: 144rem;
}

.width-limit-1280 {
    box-sizing: border-box;
    width: 100%;
    max-width: 128rem;
}

.width-limit-960 {
    box-sizing: border-box;
    width: 100%;
    max-width: 96rem;
}

.width-limit-580 {
    box-sizing: border-box;
    width: 100%;
    max-width: 58rem;
}

.width-limit-240 {
    box-sizing: border-box;
    width: 100%;
    max-width: 24rem;
}

.width-limit-align-right {
    margin-left: auto;
}

.width-limit-align-center {
    margin-right: auto;
    margin-left: auto;
}

@include max-screen($screen__m) {
    .width-limit-mobile-580 {
        box-sizing: border-box;
        width: 100%;
        max-width: 58rem;
    }

    .width-limit-mobile-240 {
        box-sizing: border-box;
        width: 100%;
        max-width: 24rem;
    }

    .width-limit-mobile-align-right {
        margin-left: auto;
    }

    .width-limit-mobile-align-center {
        margin-right: auto;
        margin-left: auto;
    }
}
@include min-screen($screen__m) {
    .width-limit-desktop-1440 {
        box-sizing: border-box;
        width: 100%;
        max-width: 144rem;
    }

    .width-limit-desktop-1280 {
        box-sizing: border-box;
        width: 100%;
        max-width: 128rem;
    }

    .width-limit-desktop-960 {
        box-sizing: border-box;
        width: 100%;
        max-width: 96rem;
    }

    .width-limit-desktop-580 {
        box-sizing: border-box;
        width: 100%;
        max-width: 58rem;
    }

    .width-limit-desktop-240 {
        box-sizing: border-box;
        width: 100%;
        max-width: 24rem;
    }

    .width-limit-desktop-align-right {
        margin-left: auto;
    }

    .width-limit-desktop-align-center {
        margin-right: auto;
        margin-left: auto;
    }
}
