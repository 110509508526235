body.smile_elasticsuite_cms-result-index {
    .page-title {
        margin-top: $indent__base;
    }
}

.block-search .search.results.cms-pages {
    margin: $indent__s 0;
}

.search.results.cms-pages {
    border: 1px solid $color-sna-black;

    .landing-pages-links {
        flex: 0 1 100%;
        max-width: 440px;
        margin: $indent__s 0 $indent__base;
    }

    .title {
        @include lib-title(small);
        margin: 0 0 $indent__base;
        word-break: break-all;
        letter-spacing: 1px;
        text-align: left;
    }

    .action.go {
        text-transform: none;
        margin-left: $indent__s;
    }

    .list {
        @extend .abs-reset-list;
        font-size: $font-size__base;
        padding: $indent__xs 0;

        .list-item {
            margin-top: 0;

            & + .list-item{
                margin-top: $indent__base;
            }
        }
    }
    .view-more {
        font-size: $font-size__s;
        display: inline-block;
        margin-top: $indent__s;
        padding: $indent__xs 0;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .search.results.cms-pages {
        display: flex;
        flex-direction: column;
        padding: $indent__m $indent__l;
    }
}

@include max-screen($screen__xs) {
    .search.results.cms-pages {
        .list {
            font-size: $font-size__s;
        }
    }
}


//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .search.results.cms-pages {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: $indent__m $indent__xxxxl;

        .landing-pages-links {
            flex: 0 1 50%;
            min-width: 300px;
        }

        .title {
            font-size: $font-size__base;
        }
    }
}
