$retailer-sidebar-width: 90vw;
$retailer-sidebar-gutter__lateral: 2vw;
$retailer-sidebar-gutter__vertical: 1.5vw;

.retailers-sidebar {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    
    z-index: 21;
    transition: transform 0 ease-out;
    opacity: 1;
    
    &::before {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        background-color: rgba($color: #000, $alpha: .6);
        // transition: transform .6s ease-out;
    }
    
    &[hidden] {
        display: block;
        transform: translateX(100%);
        opacity: 0;
        transition: transform .1s ease-out .8s, opacity .5s ease-out .3s;

        &::before {
            transform: translateX(-200%);
            transition: transform .1s .8s;
        }
    }
}

.retailers-sidebar__panel {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: $retailer-sidebar-width;
    
    overflow-y: auto;
    scroll-behavior: smooth;
    transform: translateX(0);
    transition: transform .5s ease-out;
    
    background-color: $color-white;
    
    [hidden] > & {
        display: block;
        transform: translateX(100%);
    }
}

.retailers-sidebar__header {
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    margin-bottom: $retailer-sidebar-gutter__vertical;
    padding: $retailer-sidebar-gutter__vertical $retailer-sidebar-gutter__lateral;
    background-color: $color-sna-primario4;
    
    > * {
        margin-left: $retailer-sidebar-gutter__lateral !important;
        margin-right: $retailer-sidebar-gutter__lateral !important;
        padding: 0;
    }

    @include min-screen($screen__m) {
        display: flex;
        flex-wrap: wrap;
        padding: $retailer-sidebar-gutter__vertical 0;
    }
}

.retailers-sidebar__content {
    padding: 0 $retailer-sidebar-gutter__lateral;

    > * {
        margin-left: $retailer-sidebar-gutter__lateral;
        margin-right: $retailer-sidebar-gutter__lateral;
        padding: 0;
    }
    
    @include min-screen($screen__m) {
        display: flex;
        flex-wrap: wrap;
    }
}
    

.retailers-filter-list {
    flex: 0 0 13em;
}

    .retailers-filter {
        list-style: none;
    }

.retailer-list {
    flex: 1 1 50%;
}

.product-info {
    display: grid;
    grid-template-areas: 
        "img heading"
        "img description";
    grid-template-columns: 144px auto;
    grid-gap: $indent__base;
    align-items: start;

    flex-grow: 1;
}

.product-info__img {
    grid-area: img;
}

.product-info__heading {
    grid-area: heading;
    align-self: end;
    margin: 0;
}

.product-info__name {
    font-weight: $font-weight__bold;
}

.product-info__sku {
    color: $color-sna-primario1;
}

.product-info__description {
    grid-area: description;
    margin: 0;
    color: $color-sna-primario3;
    @include lib-font-size($font-size__s);
}