.topmenu-main__item-link {
    display: none;
}

.topmenu-main__item-link--last {
    .btn-special {
        @include lib-button-has-svg-icon();

        //It might be displayed again on phase 2
        display: none !important;
    }
}
