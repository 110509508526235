[data-content-type="picture"] {
    position: relative;
    display: inline-block;
}

.pb-picture {
    display: block;

    &-wrapper {
        position: relative;
        display: block;
        width: 100%;
    }

    &__img {
        display: block;
        width: 100%;
    }

    &__veil {
        position: absolute;
        display: none;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
    }

    &-wrapper:hover &__veil {
        display: block;
    }

    &__link {
        position: absolute;
        top: 50%;
        right: 0;
        left: 0;
        text-align: center;
        transform: translateY(-50%);
        z-index: 1;
    }
}
