.mn-submenu__inline-items,
.mn-submenu__inline-all-items {
    display: flex;
    flex-direction: column;
    padding-left: 0;

    .mn-inline-banner {
        @include lib-link-secondary(
            $_link-font-weight             : $font-weight__bold,
            $_link-color                   : $color-sna-black
        );
    }

    @include on4menu-mobile {
        padding-top: $indent__s;
        .action.primary {
            display: none;
        }
        .mn-inline-banner {
            display: flex;
            padding: 0;
            align-items: center;

            &__text {
                padding: $indent__s 0;
            }

            &__figure {
                flex: 0 1 50%;
            }

            &__figure + .mn-inline-banner__text {
                flex: 0 1 50%;
                padding-left: $indent__base;
            }

            &__description {
                display: none;
            }
        }
    }
    @include on4menu-desktop {
        flex-direction: row;
        flex-grow: 1;
        @include on4menu-desktop-column-container();

        > li {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            justify-content: space-between;
            text-align: center;
            max-width: (312 * 1px) + $on4menu-column-gutter;
            width: 100%;
            @include on4menu-desktop-column();
        }

        .mn-inline-banner {
            @include lib-link-secondary(
                $_link-font-weight             : $font-weight__bold,
                $_link-color                   : $color-sna-black
            );
            flex-grow: 1;
            width: 100%;

            &__figure {
                display: block;
                width: 100%;
                margin-bottom: $indent__base;
                position: relative;
                padding-bottom: 56.25%;
            }

            &__img {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                margin: auto;
                max-width: 100%;
                max-height: 100%;
            }

            &__text {
                @include lib-font-size($font-size__base);
                display: block;
                margin-bottom: $indent__base;
            }

            &__description {
                @include lib-css(font-weight, $font-weight__regular);
                margin-bottom: $indent__m;
                cursor: default;
            }
        }

        .action.primary {
            margin-top: auto;
            width: 100%;
            min-width: 0;
            letter-spacing: normal;
        }
    }
}
.mn-submenu__inline-all-items {
    @include on4menu-desktop {
        flex-wrap: wrap;
        margin-bottom: -$indent__m;

        > li {
            margin-bottom: $indent__m;
        }
    }
}

ul.mn-submenu__inline-items li.mn-category-list__item  .cms-page-section,
ul.mn-submenu__inline-all-items li.mn-category-list__item  .cms-page-section,
ul.mn-submenu__inline-items li.mn-category-list__item p.mn-inline-banner__description,
ul.mn-submenu__inline-all-items li.mn-category-list__item p.mn-inline-banner__description {
    display:none;
}
