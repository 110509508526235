.cms-page-section.sna-europe {
    @include lib-font-size($font-size__base);
    text-align: center;

    @include min-screen($screen__m) {
        @include lib-font-size($font-size__l);
    }
    
    @include min-screen($screen__xl) {
        @include lib-font-size($font-size__xxl);
    }
}

.cms-page-section.welcome-to-sna {
    @include lib-layout-indent-main-inverse();
    padding-bottom: 0;
    @include lib-css(background-color, $color-sna-primario4);
    
    > * {
        @include lib-layout-indent-main();
    }
}

.cms-page-section.sna-europe-presence {
    @include lib-layout-indent-main-inverse();
    padding: 0;
    margin-top: $landing-section-padding-top;
    margin-bottom: $landing-section-padding-bottom;
    
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom right; 

    > * {
        @include lib-layout-indent-main();
    }

    ul {
        @extend .abs-reset-list;
        margin: $indent__xl 0;

        > li {
            margin-bottom: 1em;
            @include lib-font-size($font-size__s);
            @include lib-css(font-weight, $font-weight__bold);
            text-transform: uppercase;

            &:last-child {
                margin-bottom: 0;
            }
            
            svg { 
                width: $indent__l;
                height: $indent__l;
                @include min-screen($screen__m){
                    width: $indent__xl;
                    height: $indent__xl;
                }
                @include min-screen($screen__xl){
                    width: $indent__xxl;
                    height: $indent__xxl;
                }
            }
            
            &:nth-child(1) svg { color: #525252; }
            &:nth-child(2) svg { color: #1cbefa; }
            &:nth-child(3) svg { color: #d73a00; }

            @include min-screen($screen__m) {
                @include lib-font-size($font-size__base);
            }
        }
    }

    .img-logo-snaeurope {
        margin-bottom: 90vw;
    }
    
    @include min-screen($screen__l) {
        background-position: center right;
        
        .cms-page-section__body {
            width: 48%;
        }
        
        .img-logo-snaeurope {
            margin-bottom: 10%;
        }
    }
}

.cms-page-section.partnerships {
    @include lib-css(background-color, $color-snaeurope-blue);
    color: white;

    ul {
        flex-wrap: wrap;
        justify-content: center;
    }

    .ico-txt {
        flex-basis: 100%;
        @include min-screen($screen__m) {
            flex-basis: 50%;
        }
        @include min-screen($screen__xl) {
            flex-basis: 33%;
        }
    }
}
    
.marian-wells-quote {
    display: grid;
    grid-template-areas: 
        "content"
        "author";
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    column-gap: inherit;
    
    @include min-screen($screen__l) {
        grid-template-areas: "content author";
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
    }

    &__content {
        grid-area: content;
    
        padding-bottom: $indent__l;
    
        @include min-screen($screen__l) {
            padding-bottom: $landing-section-padding-bottom * 2;
        }
    }
    
    &__author {
        grid-area: author;
        display: flex;
        flex-direction: column;
        align-items: start;
    
        height: 320px;
        
        background-repeat: no-repeat;
        background-position: top right;
        background-size: contain;
        
        @include min-screen($screen__xs) {
            background-position: top center;
        }
        
        @include min-screen($screen__m) {
            height: 640px;
        }
        
        @include min-screen($screen__l) {
            height: auto;
            padding-bottom: $landing-section-padding-bottom * 2;
            background-position: top right;
        }
        
        @include min-screen($screen__l-3) {
            background-position: top center;
        }
    
        > * {
            width: 100px;
    
            @include min-screen($screen__m) {
                width: 150px;
            }
        }
    }
    
    &__photo {
        @include lib-css(margin-bottom, $indent__m);
    
    }
    
    &__name {
        @include lib-css(color, $color-snaeurope-blue);
        font-size: 1em;
        font-weight: inherit;
    }
    
    &__post {
        font-size: 1em;
        font-weight: inherit;
    }
}
