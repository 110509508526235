@mixin _lib-animations-bounce(
    $_animation-bounce__name                :  false,
    $_animation-bounce__duration            :  $animation-bounce__duration,
    $_animation-bounce__iteration-count     :  $animation-bounce__iteration-count,
    $_animation-bounce__timing-function     :  $animation-bounce__timing-function
) {
    @include lib-css(animation-name, $_animation-bounce__name);
    @include lib-css(animation-timing-function, $_animation-bounce__timing-function);
    @include lib-css(animation-duration, $_animation-bounce__duration);
    @include lib-css(animation-iteration-count, $_animation-bounce__iteration-count);
}

@mixin lib-animations-bounce-left {
    @include _lib-animations-bounce(bounce-left);
}

@mixin lib-animations-bounce-right {
    @include _lib-animations-bounce(bounce-right);
}