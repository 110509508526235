.cms-alt-sponsorship {

    .product-preview {
        position: relative;

        .cta-product {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            min-height: inherit;
            margin-left: 0;
            margin-right: 0;
            background-color: rgba($color: #000000, $alpha: 0.7);

            @include lib-cta-banner-no-img-small-screens("cta-product");

            .sku {
                color: $color-sna-primario1;
                margin-left: 1em;
            }

            &__figure img {
                @include object-fit(contain);
            }

            .close {
                color: white;
                background-color: transparent;
                border: none;
            }
        }
    }

    .sponsorship-races {
        list-style: none;
        counter-reset: races;
        margin: -$indent__xxl 0 0;
        padding: 0;
    
        > * {
            margin: $indent__xxl 0 0;
            padding: 0;
        }
    
        @include min-screen($screen__xs) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
    
            > * {
                flex-basis: 47%;
            }
        }
        @include min-screen($screen__l) {
            > * {
                flex-basis: 30%;
            }
        }
    }
    
    .sponsorship-race {
        text-align: center;
    
        > * {
            display: block;
            margin-bottom: $indent__s;
    
            &:last-child {
                margin-bottom: 0;
            }
        }
        
        &__img {
            margin-bottom: $indent__m;
        }
        
        &__name {
            font-weight: bold;
            text-transform: uppercase;
            
            &::before {
                counter-increment: races;
                content: counter(races)'';
                display: block;
                width: 2.1em;
                height: 2.1em;
                background-color: #fff;
                border-radius: 50%;
                @include lib-css(color, $color-sna-primario1);
                @include lib-font-size($font-size__base);
                font-weight: bold;
                line-height: 2.1;
                text-align: center;
                margin: 0 auto $indent__base;
            }
        }
        
    }
    
}
