.fotorama__arr__arr {
    .fotorama__arr & {
        @extend .fotorama-sprite;
        width: $size-fotorama-arrows;
        height: $size-fotorama-arrows;
        @include fotorama-abs-center();

        .ie9 & {
            margin: (-$size-fotorama-arrows / 2) 0 0 (-$size-fotorama-arrows / 2);
        }

        &:hover {
            opacity: 0.75;
        }
    }

    .fotorama__arr--prev & {
        background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 6 12' style='enable-background:new 0 0 6 12;' xml:space='preserve'%3E%3Cg id='icon-arrow-left-bahco' transform='translate(3.000000, 6.000000) scale(-1, 1) rotate(90.000000) translate(-3.000000, -6.000000) translate(-3.000000, 3.000000)'%3E%3Cpath id='Path' class='st0' fill='%23292827' d='M11.78,5.8c-0.16,0.15-0.43,0.15-0.59,0L5.96,0.93L0.72,5.8c-0.16,0.15-0.43,0.15-0.59,0 c-0.16-0.15-0.16-0.4,0-0.55l5.53-5.13C5.73,0.04,5.84,0,5.95,0c0.1,0,0.22,0.04,0.3,0.11l5.53,5.13 C11.95,5.4,11.95,5.65,11.78,5.8z'/%3E%3C/g%3E%3C/svg%3E");
    }

    .fotorama__arr--next & {
        background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 6 12' style='enable-background:new 0 0 6 12;' xml:space='preserve'%3E%3Cg id='icon-arrow-right-bahco' transform='translate(3.000000, 6.000000) rotate(90.000000) translate(-3.000000, -6.000000) translate(-3.000000, 3.000000)'%3E%3Cpath id='Path' fill='%23292827' class='st0' d='M11.78,5.84c-0.16,0.15-0.43,0.15-0.59,0L5.96,0.97L0.72,5.84c-0.16,0.15-0.43,0.15-0.59,0 s-0.16-0.4,0-0.55l5.53-5.13c0.08-0.08,0.18-0.11,0.3-0.11c0.1,0,0.22,0.04,0.3,0.11l5.53,5.13C11.95,5.44,11.95,5.69,11.78,5.84z' /%3E%3C/g%3E%3C/svg%3E");
    }
}
