//
//  Default appearance styles
//  _____________________________________________

[data-content-type='products'][data-appearance='carousel'] {
    .slick-initialized {
        li.product-item {
            display: flex;

            &:first-child {
                visibility: visible;
            }

        }
    }

    li.product-item {
        display: none;

        &:first-child {
            display: flex;
            visibility: hidden;
        }
    }

    &.center-mode {
        .product-item {
            opacity: 0.5;
            transition: all 300ms ease;

            &:hover {
                opacity: 1;
            }
        }

        .slick-current .product-item {
            opacity: 1;
        }
    }
}
