.auto-height {
    min-height: calc(100vh - #{$header__height--tablet});

    .page-header.fixed ~ * & {
        min-height: calc(100vh - #{$header__height-fixed--tablet});
    }

    @include max-screen($screen__xs-2) {
        min-height: calc(100vh - #{$header__height});

        .page-header.fixed ~ * & {
            min-height: calc(100vh - #{$header__height-fixed});
        }
    }

    @include min-screen($screen__m) {
        min-height: calc(100vh - #{$header__height--desktop});

        .page-header.fixed ~ * & {
            min-height: calc(100vh - #{$header__height-fixed--desktop});
        }
    }
}
