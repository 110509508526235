.cta-double-item {
    @include lib-cta-banner($block-class: 'cta-double-item');
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    color: $color-white;

    > * {
        flex-basis: auto;
    }

    .cta-double-item__title {
        @include lib-font-size($font-size__xxl);
        text-align: center;

        img {
            display: inline-block;
            max-height: 4rem;
            width: auto;
        }
    }

    .cta-double-item__body {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 1;
        @include lib-layout-indent-main();
        @include lib-css(padding-top, 10%);
        @include lib-css(padding-bottom, 10%);
        @include lib-font-size($font-size__s);
        text-align: center;

        svg {
            display: block;
            height: 5.6rem;
            width: auto;
            max-width: 100%;
            margin: 6% auto auto;
        }
        &-content {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

            > .action.go {
                margin-top: auto;
            }
        }
    }

    @include max-screen($screen__l) {
        .cta-double-item__figure {
            min-height: $cta-banner-height__s;

            img {
                width: 100%;
                height: 100%;
                display: block;
                @include object-fit(cover);
            }
        }
    }

    @include min-screen($screen__l) {
        .cta-double-item__figure {
            flex-basis: auto;
        }
    }

    @include min-screen($screen__xl) {
        .cta-double-item__title {
            @include lib-font-size(60px);

            img {
                max-height: 6.4rem;
            }
        }
        .cta-double-item__body {
            @include lib-font-size($font-size__base);

            svg {
                height: 7.2rem;
                margin: $indent__l auto;
            }
        }
    }

}

