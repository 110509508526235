$timeline-entry__separator__height      :  60px;
$timeline-entry__img__width__base       :  100px;
$timeline-entry__img__width__tablet     :  150px;

.timeline {
    display: flex;
    flex-direction: column;
    margin: 0;
    @include lib-css(margin-right, -$indent__s);
    @include lib-css(margin-left, -$indent__s);
    padding: 0;
    list-style: none;

    p:last-child {
        margin-bottom: 0;
    }

    &-entry {
        margin: 0;
        padding: 0;

        > * {
            @include lib-css(margin-right, $indent__s);
            @include lib-css(margin-left, $indent__s);
        }

        &__separator {
            height: $timeline-entry__separator__height;
            @include lib-css(color, $color-sna-primario4);
        }

        &__year {
            @include lib-font-size(3.8em);
            @include lib-css(color, $color-sna-primario1);
            @include lib-css(font-weight, $font-weight__bold);
            line-height: 1;
        }
    
        &__content {
            .img-logo-award {
                display: inline-block;
                margin: 0 1em;
                vertical-align: middle;
            }
        }
    }

    @include max-screen($screen__m) {
        &-entry {
            display: grid;
            grid-template-areas: 
                "separator  separator"
                "img        year"
                "img        content";
            grid-template-columns: $timeline-entry__img__width__base auto;
            grid-template-rows: $timeline-entry__separator__height auto auto;
            justify-content: start;
            align-items: flex-start;
            column-gap: $indent__s;
            row-gap: $indent__base;
            margin-bottom: $indent__base;

            &__separator { grid-area: separator; width: $timeline-entry__img__width__base; }
            &__year      { grid-area: year; margin-bottom: -$indent__s; }
            &__img       { grid-area: img; }
            &__content   { grid-area: content; }
        
        }
    }

    @include min-screen($screen__m) {
        &-entry {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
    
            &__separator {
                flex-basis: 100%;
                @include lib-css(margin-bottom, $indent__l);
                @include lib-css(margin-top, $indent__l);
            }
    
            &__year {
                flex: 1 1 30%;
                text-align: right;
            }
    
            &__img {
                flex: 0 0 auto;
                width: $timeline-entry__img__width__tablet;
                height: $timeline-entry__img__width__tablet;
                @include lib-css(background-color, $color-sna-primario4);
            }
    
            &__content {
                flex: 1 1 30%;
            }
        }

        &-entry:nth-child(odd) {
            flex-direction: row-reverse;

            .timeline-entry__year {
                text-align: left;
            }

            .timeline-entry__content {
                text-align: right;
            }
        }
    }
}