.cms-page-section.has-bg-img {
    background-repeat: no-repeat;
    background-position: top center;
    background-size: contain;
    @include lib-layout-indent-main-inverse();
    
    > * {
        @include lib-layout-indent-main();
    }

    &--cover {
        background-size: cover;
    }
}

/**
 * Made for "SNA Europe" and "Who is Bahco". Use with care or don't do it.
 * ------------------------------------------------------------------------- */
.cms-alt-who-is-bahco {
    .cms-page-section.has-bg-img {
        position: relative;
        overflow: hidden;

        > * {
            position: relative;
            z-index: 2;
        }
    
        @include max-screen($screen__l) {
            padding-bottom: 80vw;
        }
    
        .cms-page-section__bg {
            position: absolute;
            padding: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin-bottom: $landing-section-padding-bottom;
            margin-left: auto;
            z-index: 1;
    
            @include min-screen($screen__l) {
                left: 50%;
                width: 50%;
                margin-left: 0;
            }
        }
    }
}