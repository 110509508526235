.cms-alt-after-sales-services {
    
    .mosaic {
        @extend .abs-reset-list;
        
        li:not(:last-child) {
            @include lib-css(margin-bottom, $indent__base);
        }

        @include min-screen($screen__m) {
            column-count: 2;
            @include lib-css(column-gap, $indent__m);
            
            li:not(:last-child) {
                @include lib-css(margin-bottom, $indent__m);
            }
        }
        @include min-screen($screen__xl) {
            max-width: 75%;
            margin: 0 auto;
            column-count: 2;
            @include lib-css(column-gap, $indent__xxl);
            
            li:not(:last-child) {
                @include lib-css(margin-bottom, $indent__xxl);
            }
        }
    }

    .technical-doc {
        @include lib-layout-indent-main-inverse();
        @include lib-layout-indent-main();
        @include lib-css(background-color, $color-sna-primario2);
        @include lib-css(color, $color-white);
        text-align: center;
        @include lib-landing-section--one-col("cms-page-section");

        ul {
            @extend .abs-reset-list;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            @include lib-css(margin-top, $indent__xxl);

            > * {
                flex-basis: 50%;
            }

            > :nth-child(0),
            > :nth-child(1) {
                margin-bottom: 1.6em; 
            }
        }
            
        @include min-screen($screen__m) {
            ul {
                flex-wrap: nowrap;

                > * {
                    margin-bottom: 0;
                }
            }
        }

        @include min-screen($screen__xl) {
            > * {
                @include lib-css(margin-left, $indent__xxl);
                @include lib-css(margin-right, $indent__xxl);
            }

            ul {
                @include lib-css(margin-top, $indent__xxxxl);
            }
        }

        svg {
            display: block;
            height: 4em;
            width: 4em;
            margin: 0 auto 1.6em;
        }
    }
}