.column-slider {
    .slick-list,
    .slick-slide {
        padding: 0;
    }

    &.slick-initialized.slick-slider {
        display: flex;
        align-items: center;
    }

    .slick-prev,
    .slick-next {
        position: initial;
        padding: $indent__s;
        transform: none;
        box-sizing: content-box;
    }
}
