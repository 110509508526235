.header.content {
    a.btn-special {
        //It might be displayed again on phase 2
        display: none;
    }
}

@include min-screen($screen__m) {
    .header.content {
        .block-search {
            margin-right: 0; /* Primera fase: quitar link a Mi Cuenta */
        }
    }
}
