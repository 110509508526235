.landing-picheader {
    @include lib-cta-banner(
        $block-class: "landing-picheader",
        $row-breakpoint: $screen__m,
        $body-width: 39%,
        $figure-width: 61%
    );
    @include lib-cta-banner-reverse(
        $block-class: "landing-picheader",
        $row-breakpoint: $screen__m
    );
    @include lib-css(background-color, $color-sna-primario2);
    @include lib-css(color, $color-white);

    &__title {
        text-transform: none;
    }

    &__icon {
        height: 24px;
        width: 24px;
        @include lib-css (margin-bottom, $indent__s);
    }

    .landing-picheader__subtitle {
        order: inherit;
        text-transform: none;
    }

    &__figure {
        height: auto;

        img {
            max-width: 100%;
            height: auto;
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 288px;
    }

    &__body-content + .action {
        @include lib-css(margin-top, $indent__l);
    }

    @include min-screen($screen__l) {
        height: auto;

        &__icon {
            height: 40px;
            width: 40px;
        }
    }
}
