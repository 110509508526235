//  Messages
$message-global-note__background: $color__warning;
$message-global-note__border-color: none;
$message-global-caution__background: $color__error;

//  Footer
$footer__background-color: $color__neutral-2;

.footer {
    .contact {
        &__text {
            > :last-child {
                text-align: center;
            }
        }
    }
}

.page-footer {
    @include lib-css(background-color, $footer__background-color);
}

.footer-two-blocks-row {
    @include lib-css(background-color, $color__white);
}

@include min-screen($screen__s) {
    .footer {
        .contact {
            &__text {
                > :last-child {
                    text-align: left;
                }
            }
        }
    }
}
