//
//  Contained row appearance styles
//  _____________________________________________

[data-content-type='row'][data-appearance='contained'] {
    @include lib-css(max-width, $layout__max-width);

    box-sizing: border-box;
    margin-left: auto !important; // sass-lint:disable-line no-important
    margin-right: auto !important; // sass-lint:disable-line no-important

    [data-element='inner'] {
        box-sizing: border-box;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    [data-content-type='row'][data-appearance='contained'] {
        [data-element='inner'] {
            background-attachment: scroll !important; // sass-lint:disable-line no-important

            .page-main-full-width & {
                padding-left: $layout-indent-main__s;
                padding-right: $layout-indent-main__s;
            }
        }
    }
}

//
//  > Tablet
//  _____________________________________________

@include min-screen($screen__m) {
    [data-content-type='row'][data-appearance='contained'] {
        [data-element='inner'] {
            .page-main-full-width & {
                padding-left: $layout-indent-main__m;
                padding-right: $layout-indent-main__m;
            }
        }
    }
}

@include min-screen($screen__xl) {
    [data-content-type='row'][data-appearance='contained'] {
        [data-element='inner'] {
            .page-main-full-width & {
                padding-left: $layout-indent-main__xl;
                padding-right: $layout-indent-main__xl;
            }
        }
    }
}
