.where-to-buy-search-form {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    @include lib-layout-indent-main-inverse();
    @include lib-layout-indent-main();
    background-size: cover;
    background-position: center center;

    height: 320px;
    @include min-screen(915px) {
        height: auto;

        &::before {
            content: '';
            display: inline-block;
            width: 1px;
            padding-bottom: 35%;
        }
    }

    label {
        @extend .abs-visually-hidden;
    }

    [type="search"] {
        width: 100%;
    }

    @include min-screen(50rem) {
        flex-wrap: nowrap;

        [type="search"] {
            max-width: 50rem;
            @include lib-css(margin-right, $indent__base);
        }
    }
}

.where-to-buy-results {
    padding-top: 8.33%;
    padding-bottom: 10%;
    
    ul {
        @extend .abs-reset-list;
        
        li {
            @include lib-css(padding, $indent__m);
            @include lib-css(margin-bottom, $indent__s);
            @include lib-css(background-color, $color-sna-primario4);
            @include lib-font-size($font-size__s);
            
            strong {
                font-size: 1.23em;
            }

            address {
                margin: .5em 0; 
            }

            svg {
                height: 1.23em;
                width: 1.23em;
                @include lib-css(margin-right, $indent__s);
            }
        }

        @include min-screen($screen__xxl) {
            max-width: 75%;
            margin: 0 auto;
        }
    }    
}

#where-to-buy-no-results {
    border: 1px solid $color-sna-primario1;
    text-align: center;
    @include lib-css(padding, 5%); 
    
    @include min-screen($screen__xxl) {
        max-width: 75%;
        margin: 0 auto;
        @include lib-css(padding, $indent__xxxl); 
    }
}
