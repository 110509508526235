.cta-double,
.cta-banner-container {
    @include lib-layout-indent-main-inverse();
    
    .cta-double-item,
    .cta-banner {
        @include lib-layout-indent-main-inverse-reset();
        
        &__body {
            @include lib-layout-indent-main();
        }
    }

    @include min-screen($screen__m){
        display: flex;
    }
}