$on4menu-desktop-media-query: $screen__m;
$on4menu-desktop-normal-media-query: $screen__m-2;
$on4menu-desktop-big-media-query: $screen__l;
$on4menu-desktop-extra-big-media-query: $screen__l-3;

$on4menu-icon-width: 1.2rem;

$on4menu-font-size--base: $font-size__s;
$on4menu-font-size--small: $font-size__base;

$on4menu-color-grey: $color-sna-primario4;

$on4menu-border-color: $on4menu-color-grey;

$on4menu-shadow-bottom: $box-shadow-sna1;

$on4menu-column-gutter: $indent__l;