.mn-category-list--inline {
  display: flex;
}
.mn-category-list {
    &__list-title {
        @include lib-title(small);
        font-weight: $font-weight__hairline;
    }
    &__list-content &__item a {
        @include lib-link-secondary(
            $_link-font-weight             : $font-weight__heavier,
            $_link-color                   : $color-sna-black
        );
        display: inline-block;
        padding: $indent__s 0;
    }

    @include on4menu-mobile {
        &__list-content {
            margin-top: $indent__base;
        }
        &__list-title {
            margin-bottom: $indent__s;
        }
        &__list-content &__item {
            margin-bottom: $indent__base;
        }
    }
    @include on4menu-desktop {
        &__list-content {
            border-left: 1px solid $color-sna-primario4;
            padding: 0 0 0 $indent__m;
        }
        &__list-title {
            margin-bottom: $indent__base;
        }
    }
}
