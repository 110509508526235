@mixin lib-landing-header() {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    @include lib-layout-indent-main-inverse();
    padding-bottom: 2.4rem;
    @include lib-css(color, $color-sna-white);
    @include lib-css(background-color, $color-sna-secundario2);

    > :empty {
        display: none;
    }

    &__title {
        @include lib-heading(h2);
        flex-grow: 1;
        margin-bottom: 0;
        @include lib-font-size($font-size__base);
        text-transform: uppercase;
    }

    &__subtitle {
        @include lib-css(color, $color-sna-white);
        margin-bottom: 0 !important;
        @include lib-font-size($font-size__s);
    }

    &__title,
    &__subtitle {
        flex-grow: 0;
        @include lib-layout-indent-main();
        margin-top: 2.4rem;
    }

    &__pic,
    &__video {
        order: -2;
        flex-basis: 100%;
        width: 100%;
        @include lib-cms-page-picture-fixed-height();
        @include lib-video-container(2048/717);
    }

    &__pic {
        img {
            display: block;
        }
    }

    &__actions {
        position: absolute;
        flex-basis: 100%;
        top: $landing-section-fixed-height__s;
        left: 3%;
        right: 3%;
        margin-top: -3%;
        order: -1;
        transform: translateY(-100%);
        text-align: center;

        @include min-screen($screen__m) {
            text-align: right;
        }

        @include min-screen($landing-section-bg-img-fixed-height__mediaquery) {
            top: 0;
            margin-top: 32%;
        }

        .action {
            @include lib-button-primary-over-dark-bg();
        }
    }

    @include min-screen($screen__xl) {
        &__title {
            @include lib-font-size($font-size__xl);
        }
        &__subtitle {
            @include lib-font-size($font-size__base);
        }
    }
}

@mixin lib-landing-header-nav() {
    @include lib-css(color, $color-sna-white);
    @include lib-css(font-weight, $font-weight__bold);
    margin-bottom: 0;

    button {
        color: currentColor;
        text-transform: none;
        @include lib-css(padding, $indent__s 0);
    }

    ul {
        @extend .abs-reset-list;

        li {
            display: block;

            a {
                display: inline-block;
                @include lib-link-secondary(
                    $_link-font-weight             : $font-weight__bold,
                    $_link-color                   : $color__primary--light,
                    $_link-color-visited           : $color__primary--light,
                    $_link-color-hover             : $color-sna-white,
                    $_link-text-decoration-hover   : none,
                    $_link-color-active            : $color__primary--light
                );
                @include lib-css(padding-top, $indent__base);
                @include lib-css(padding-bottom, $indent__base);
                border-bottom: 3px solid transparent;

                &:hover {
                    @include lib-css(border-bottom-color, $color__primary--light);
                }
            }
        }
    }

    @include max-screen($screen__m) {
        button, a {
            width: 100%;
            text-align: right;
        }

        a {
            padding-right: 2.2rem;
        }
    }

    @include min-screen($screen__m) {
        ul {
            display: flex;
            justify-content: space-between;
            align-items: center;

            li {
                & + li {
                    @include lib-css(margin-left, $indent__xxl);
                }

                a {
                    @include lib-css(padding-top, $indent__s);
                    @include lib-css(padding-bottom, $indent__s);
                }
            }
        }
    }
}
@mixin lib-landing-header--has-nav($blockName: "cms-page-header") {
    .#{$blockName}--has-nav {
        position: relative;
        padding-bottom: 0;
        overflow: hidden;

        .#{$blockName}__title {
            margin-bottom: 2.4rem;
        }

        .#{$blockName}__subtitle {
            position: absolute;
            top: 0;
            left: 50%;
            @include lib-layout-indent-main();
            width: 100%;
            margin-top: 17.5%; /* Half desktop image ratio 1:0.35 = 0.175 */
            transform: translate(-50%, -50%);
            @include lib-css(font-weight, $font-weight__hairline);
            @include lib-font-size($font-size__xxl);
            text-align: center;
            z-index: 2;

            @include max-screen($landing-section-bg-img-fixed-height__mediaquery) {
                margin-top: 160px;
            }

            @include min-screen($screen__m) {
                width: 60%;
            }

            @include min-screen($screen__xl) {
                @include lib-font-size(48px);
            }

            small {
                display: inline-block;
                @include lib-typography__main__text();
            }
        }

        .#{$blockName}__nav {
            flex-grow: 0;
            @include lib-layout-indent-main();
            margin-top: 0;
            @include lib-font-size($font-size__s);
            @include lib-landing-header-nav();

            @include max-screen($screen__m) {
                flex-basis: 100%;
            }

            @include min-screen($screen__m) {
                flex-grow: 1;
                display: flex;
                justify-content: flex-end;
            }

            @include min-screen($screen__xl) {
                @include lib-font-size($font-size__base);
            }
        }
    }
    .#{$blockName}:not(.#{$blockName}--has-nav) {
        .#{$blockName}__nav {
            @extend .abs-visually-hidden;
        }
    }
}

.cms-page-header,
.landing-header {
    @include lib-landing-header();
}

@include lib-landing-header--has-nav("landing-header");
@include lib-landing-header--has-nav("cms-page-header");
