.mn-submenu--category-groups {
    .mn-submenu {
        &__items {
            display: block;
            cursor: auto;

            @include on4menu-desktop {
                margin: 0 (-$indent__m);
                padding: 0 $indent__s;
                column-count: 2;
                flex-basis: 100%;

                > * {
                    margin: 0 $indent__m;
                    width: 100%;
                }
            }

            @include on4menu-desktop-big {
                margin: 0 (-$indent__s);
            }

            @include on4menu-desktop-extra-big {
                display: flex;
                column-count: initial;
            }

            @include min-screen($screen__xl) {
                margin: 0;
            }
        }
    }
    .mn-children {
        display: flex;
        flex-direction: column;
        flex: 1 0 15%;
        break-inside: avoid;
        color: $color-sna-black;
        @include lib-font-size($font-size__s);

        &__item-list {
            padding: 0;
            margin: 0 0 .5em;
        }

        &__heading {
            display: flex;
            align-items: center;
            padding: 1em 0;
            @include lib-css(font-weight, $font-weight__bold);

            .arrow {
                margin-left: $indent__xs;
            }
        }

        @include on4menu-mobile {
            &__content {
                display: flex;
                flex-direction: column;
                flex: 1 0 15%;
                break-inside: avoid;
            }
            &__item-list {
                order: 2;
                margin-top: $indent__s;
                padding-left: $indent__base;
                padding-top: $indent__s;
                border-top: 1px solid $color-sna-primario4;
            }
            &__item {
                @include lib-font-size($font-size__base);
                display: flex;
            }
            .mn-category-button--back {
                flex-grow: 0;
                padding: $indent__s 0;
                margin-bottom: $indent__s;
            }
            &__view-all {
                @include lib-link-as-button();
                @include lib-button-primary($_button-min-width: 0);
            }

            &__content.is-expanded {
                .mn-children__heading {
                    margin-left: -$indent__base;
                    margin-bottom: .5em;
                    color: $color-sna-primario1;
                }
                svg.arrow-right {
                    display: none;
                }
            }

            &__content:not(.is-expanded) .mn-children {
                &__item-list,
                &__view-all,
                &__heading svg.arrow-left {
                    display: none;
                }
                &__heading {
                    padding-left: $indent__base;
                }
            }
        }

        @include on4menu-desktop {
            padding-bottom: 1.5em;
            @include lib-font-size($font-size__base);
            letter-spacing: normal;

            &__view-all {
                height: 48px;
                @include lib-button-go();
            }

            &__heading {
                svg.arrow-left,
                svg.arrow-right {
                    display: none;
                }
            }
        }
    }
}