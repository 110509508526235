.block.block-content {

    #search_result {
        max-width: $layout__max-width;
        background-color: $color-white;

        .result {
            padding: 15px 0 $indent__xs;
            @include lib-font-size($font-size__s);

            .text,
            .num_results {
                color: $color-sna-black;
                @include lib-css(font-weight, $font-weight__heavier);
            }

            .close-popup {
                cursor: pointer;
            }

            .view-all {
                margin-left: $indent__xs;
                display: inline-block;
                cursor: pointer;
                @include lib-link-secondary(
                    $_link-font-weight             : $font-weight__heavier,
                    $_link-text-transform          : lowercase,
                    $_link-color                   : $color-sna-primario1,
                    $_link-color-visited           : $color-sna-primario1,
                    $_link-color-hover             : $color-sna-secundario1,
                    $_link-text-decoration-hover   : underline,
                    $_link-color-active            : $color-sna-primario1
                );
            }
        }

        .filters {
            padding-top: $indent__xs;

            .filter {
                background-color: $color-sna-primario4;
                @include lib-font-size($font-size__s);
                color: $color-sna-black;
                margin: 0 0 $indent__s;
            }

            .filter-title {
                padding: $indent__s $indent__xl $indent__s $indent__s;
                @include lib-css(font-weight, $font-weight__heavier);

                .filter-name {
                    display: inline-block;
                }

                .arrow {
                    right: $indent__base;
                }

                .arrow-up {
                    display: none;
                }

                .arrow-down {
                    display: inline-block;
                }

                &.active {
                    .arrow-up {
                        display: inline-block;
                    }
                    .arrow-down {
                        display: none;
                    }
                }
            }

            .options {
                padding: $indent__s $indent__base $indent__m;

                .value::before {
                    content: '';
                    width: 16px;
                    height: 16px;
                    margin-right: $indent__xs;
                    display: inline-block;
                    background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 16 16' style='enable-background:new 0 0 16 16;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:none;stroke:%23000000;%7D%0A%3C/style%3E%3Cg id='Group-2' transform='translate(0.000000, 1.990149)'%3E%3Crect id='Rectangle-127' x='0.5' y='0.5' class='st0' width='13' height='13'/%3E%3C/g%3E%3C/svg%3E");
                    background-repeat: no-repeat;
                    background-position: center;
                    vertical-align: top;
                }
                div.content {
                    cursor:pointer;
                    margin-top: 0;

                    &.selected {
                        .value::before {
                            background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 16 16' style='enable-background:new 0 0 16 16;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:none;stroke:%23000000;%7D .st1%7Bfill-rule:evenodd;clip-rule:evenodd;%7D%0A%3C/style%3E%3Cg id='Group-2' transform='translate(0.000000, 0.990149)'%3E%3Crect id='Rectangle-127' x='0.5' y='1.5' class='st0' width='13' height='13'/%3E%3Cpath id='Combined-Shape' class='st1' d='M6.67,8.51l7.3-8.29c0.3-0.3,0.58-0.27,0.78-0.07s0.23,0.47-0.07,0.78l-8,9L3.22,6.57 c-0.3-0.3-0.27-0.58-0.07-0.78c0.2-0.19,0.48-0.23,0.78,0.07L6.67,8.51z'/%3E%3C/g%3E%3C/svg%3E");
                        }
                    }

                    & + div.content {
                        margin-top: $indent__s;
                    }
                }
            }
        }
        .selected-filters {
            padding-bottom: $indent__s;
            text-align: left;

            .selected-item {
                color: $color-sna-primario2;
                background-color: $color-sna-primario4;
                margin-right: $indent__s;
                padding: $indent__s;

                @include lib-link-secondary(
                    $_link-font-weight             : $font-weight__heavier,
                    $_link-color                   : $color-sna-primario2,
                    $_link-color-visited           : $color-sna-primario2,
                    $_link-color-hover             : $color-sna-primario2,
                    $_link-color-active            : $color-sna-primario2
                );
                @include lib-font-size($font-size__xs);

                &:hover {
                    -webkit-box-shadow: $box-shadow-sna1;
                    -moz-box-shadow: $box-shadow-sna1;
                    box-shadow: $box-shadow-sna1;
                }

                svg {
                    margin-left: $indent__s;
                    width: 1.6rem;
                    height: 1.6rem;
                }
            }
        }
        .products {

            .product-info {
                @include lib-product-list__item();
                flex-wrap: wrap;
                flex-direction: row;

                .special-product-label {
                    position: absolute;
                    top: 0;
                    right: 0;
                    z-index: 1;
                }

                .special-product-label.new {
                    @include lib-link-secondary(
                        $_link-font-weight             : $font-weight__heavier,
                        $_link-text-transform          : uppercase,
                        $_link-color                   : $color-sna-primario2,
                        $_link-color-visited           : $color-sna-primario2,
                        $_link-color-hover             : $color-sna-primario2,
                        $_link-color-active            : $color-sna-primario2
                    );
                    font-weight: $font-weight__heavier;
                    @include lib-font-size($font-size__s);
                    border: 1px solid $color-sna-primario2;
                    padding: $indent__xs $indent__s;
                    background-color: $color-sna-white;
                }

                .product-ergo {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    max-width: 72px;

                    > img {
                        display: block;
                        max-width: 100%;
                    }
                }

                .product-name {
                    @include lib-link-secondary(
                        $_link-font-weight             : $font-weight__bold,
                        $_link-color                   : $color-sna-primario2,
                        $_link-color-visited           : $color-sna-primario2,
                        $_link-color-hover             : $color-sna-primario2,
                        $_link-color-active            : $color-sna-primario2
                    );
                    @include lib-font-size($font-size__base);
                    word-break: break-word;
                    @include lib-css(font-weight, $font-weight__bold);
                    @include lib-css(margin-bottom, $indent__s);
                    display: flex;
                    flex-grow: 1;
                }

                .product-item-photo {
                    position: relative;
                    margin: 0 $indent__base auto;
                    @include lib-css(padding, 0);
                    flex: 0 1 100%;
                }

                .product-sku {
                    display: block;
                    @include lib-css(color, $color-sna-primario1);
                    @include lib-css(font-weight, $font-weight__bold);
                    @include lib-font-size($font-size__base);
                    word-break: break-word;
                }
                .action.primary {
                    display: none;
                    width: 100%;
                    min-width: unset;
                    margin-top: $indent__base;
                }

                @include min-screen($screen__m) {
                    &:hover {
                        @include lib-css(box-shadow, $box-shadow-sna1);

                        .action.primary {
                            display: block;
                        }
                    }
                }

                .box-price {
                    .final-price {
                        display: inline-block;
                        white-space: nowrap;
                        color: $color-black;
                    }
                    .old-price {
                        display: inline-block;
                        text-decoration-color: $color-gray-darken2;
                        color: $color-gray-darken2;
                    }
                }

                .stock {
                    margin: $indent__s 0 $indent__s;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                }
                .stock.unavailable {
                    color: $color-black;
                }
                .stock.available {
                    color: $color-dark-green1;
                }
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .page-header:not(.fixed) {
        .block-search {

            .control {
                position: relative;
                .actions {
                    position: absolute;
                    right: 16px;
                    top: 50%;
                    transform: translateY(-50%);

                    .input-btn {
                        display: inline-block;
                        @include lib-button-reset();
                        padding: $indent__xs 0;

                        z-index: 1;
                        min-width: unset;

                        span {
                            @include lib-visually-hidden();
                        }

                        svg {
                            margin: 0;
                        }

                        &:focus {
                            &:before {
                                @include lib-css(color, $color-gray20);
                            }
                        }
                    }
                }
            }

        }
    }

    .page-header.fixed {
        .block-search {
            .label {
                display: inline-block;
                cursor: pointer;

                span {
                    font-size: 0;
                    width: 0;
                    height: 0;
                }

                &.active {
                    + .control {
                        padding: $indent__s $layout__width-xs-indent;
                        max-height: 64px;
                        transition: max-height 0.5s, overflow 0.5s 0.5s;
                        overflow: hidden;

                    }
                    .search {
                        display: none;
                    }
                    .close {
                        display: inline-block;
                    }
                }
            }
            .actions-container {
                position: relative;
            }
            .actions {
                position: absolute;
                right: 0;
                top: 0;
                z-index: 15;
                max-height: none;
                transition: none;

                .input-btn {
                    transform: none;
                    @include lib-custom-button-reset();
                    padding: $indent__s $layout__width-xs-indent;
                    position: relative;
                    height: $form-element-input__height;

                    > span {
                        @include lib-visually-hidden();
                    }

                    svg {
                        margin: 0;
                    }
                }
            }
        }
    }

    .block.block-content {
        #search_result > div {
            padding-left: $layout__width-xs-indent;
            padding-right: $layout__width-xs-indent;

            .products {
                .product-info {
                    .product-name {
                        @include lib-font-size(13);

                    }
                    .img-container {
                        margin: 0 15px;
                    }
                }
            }

            .result {
                .filter-options-text {
                    padding: $indent__base;
                    display: block;
                    border: 1px solid $color-sna-black;
                    text-align: center;
                    cursor: pointer;
                    @include lib-title(small);
                    margin: $indent__base 0 0;
                    word-break: break-all;
                    letter-spacing: 0.8px;
                    @include lib-font-size($font-size__base);
                }
            }
        }

        .input-btn::before {
            @include lib-css(font-size, 19px);
        }
    }
}

@include max-screen($screen__xs-2) {
    .page-header:not(.fixed) {
        .block-search {
            display: block;
            position: initial;
            .label {
                display: inline-block;
                cursor: pointer;

                span {
                    @include lib-visually-hidden();
                }

                &.active {
                    + .control {
                        padding: $indent__s $layout__width-xs-indent;
                        max-height: 64px;
                        transition: max-height 0.5s, overflow 0.5s 0.5s;
                        overflow: hidden;

                    }
                    .search {
                        display: none;
                    }
                    .close {
                        display: inline-block;
                    }
                }
            }
            .control {
                position: absolute;

                .actions-container {
                    position: relative;
                }
                .actions {
                    z-index: 15;
                    max-height: none;
                    transition: none;
                    right: 0;
                    top: 0;
                    transform: none;

                    .input-btn {
                        transform: none;
                        @include lib-custom-button-reset();
                        padding: $indent__s $layout__width-xs-indent;
                        position: relative;
                        height: $form-element-input__height;

                        > span {
                            @include lib-visually-hidden();
                        }

                        svg {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }

    .block.block-content {
        #search_result > div .result {

            .filter-icon {
                font-size: 0;

                &::after {
                    @include lib-css(font-size, 14px);
                    @include lib-css(font-weight, normal);
                    margin-left: 0;
                }
            }

            .view-all {
                margin-left: 3px;
            }

        }
    }
}


//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .block.block-content {
        #search_result {
            border: 1px solid $color-black;
            & > div {
                padding-left: $layout-indent__width;
                padding-right: $layout-indent__width;

                .result .filter-options-text {
                    display: none;
                }
            }
        }

        .input-btn {
            display: inline-block;
            @include lib-button-reset();
            padding: $indent__xs 0;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
            min-width: unset;

            span {
                @include lib-visually-hidden();
            }

            &:focus {
                &:before {
                    @include lib-css(color, $color-gray20);
                }
            }
            &:hover {
                color: $color-sna-primario1;
            }
        }
        .products {

            .product-info:hover {
                .product-image-wrapper {
                    padding-bottom: calc(110% - 66px);
                }
            }
        }
    }
}



