[data-content-type='banner'].banner-content-align-top {
    .pagebuilder-overlay.pagebuilder-poster-overlay {
        align-items: flex-start;
    }
}

[data-content-type='banner'].banner-content-align-bottom {
    .pagebuilder-overlay.pagebuilder-poster-overlay {
        align-items: flex-end;
    }
}

.jarallax {
    z-index: -1;
}
