//
//  Home
//  _____________________________________________

.cms-home .page-title-wrapper {
    display: none;
}

@include min-screen($screen__m) {
    .cta-banner-container.two-cols {
        display: flex;
    }
}

.cta-banner.featured-product {
    @include lib-cta-banner-product();
    @include lib-css(background-color, $color-sna-primario2);
    @include lib-css(color, $color-white);

    .cta-banner__figure {
        height: $cta-banner-height__s;
        @include lib-css(padding-top, $layout-indent-main__s);
        padding-bottom: 0;

        img {
            width: 100%;
            height: 100%;
            @include object-fit(contain);
        }
    }

    @include min-screen($screen__m) {
        height: $cta-banner-height__m;
        .cta-banner__figure {
            height: auto;
            @include lib-css(padding-top, $layout-indent-main__m);
        }
    }

    @include min-screen($screen__xl) {
        height: $cta-banner-height__l;
        .cta-banner__figure {
            @include lib-css(padding-top, $layout-indent-main__xl);
        }
    }
} 


.cta-banner.new-arrival {
    @include lib-cta-banner-row-breakpoint(
        $row-breakpoint: $screen__m,
        $body-width: 39%,
        $figure-width: 61%
    );

    @include min-screen($screen__m) {
        height: $cta-banner-height__m;
        .cta-banner__figure {
            height: auto;
        }
    }

    @include max-screen($screen__l) {
        .cta-banner__figure {
            height: $cta-banner-height__m;
            img {
                max-width: none;
                height: 100%;
            }
        }
    }
    
    @include min-screen($screen__l) {
        height: auto;
    }
}

.cta-banner.sponsorship {
    @include lib-cta-banner-row-breakpoint(
        $row-breakpoint: $screen__m,
        $body-width: 33%,
        $figure-width: 67%
    );
    @include lib-css(background-color, $color-sna-primario4);

    @include min-screen($screen__m) {
        height: $cta-banner-height__s;
        .cta-banner__figure {
            height: auto;
        }
    }

    @include max-screen($screen__l) {
        .cta-banner__figure {
            height: $cta-banner-height__s;
            img {
                max-width: none;
                height: 100%;
            }
        }
    }
    
    @include min-screen($screen__l) {
        height: auto;
    }
}

.cta-banner.only-text {
    display: block !important;
    @include lib-css(background-color, $color-sna-primario4);

    .cta-banner__title {
        text-align: center;
    }

    .cta-banner__body {
        @include lib-css(padding-top, 64px);
        @include lib-css(padding-bottom, 64px);
        text-align: center;
    }

    @include min-screen($screen__l) {
        .cta-banner__body {
            @include lib-css(padding-top, 141px);
            @include lib-css(padding-bottom, 141px);
            max-width: 75%;
            margin: 0 auto;
        }
    }

    &.is-dark {
        @include lib-css(color, $color-white);
        @include lib-css(background-color, $color-black);

        .action {
            @include lib-button-primary-over-dark-bg();
        }
    }
}

/* .cta-banner.campaign {
    @include min-screen($screen__m) {
        flex-direction: column;
        > * {
            flex-basis: auto;
        }
        .cta-banner__body {
            @include lib-css(padding-top, $indent__xxl);
            @include lib-css(padding-bottom, $indent__xxl);
        }
    }

    @include min-screen($screen__xl) {
        flex-direction: row;
        > * {
            flex-basis: 50%;
        }
        .cta-banner__body {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
} */
