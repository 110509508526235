.mn-submenu--products-menu {
    @include on4menu-mobile {
        .menu-banner {
            display: none;
        }
    }
    @include on4menu-desktop {
        flex-wrap: nowrap;

        .mn-submenu__header.with-banner {
            flex: 0 1 20%;
            max-width: 256px;
            min-width: 200px;
        }

        .menu-banner {
            display: flex;
            flex-direction: column;
            margin-left: 0;
            margin-right: 0;
            box-sizing: border-box;
            overflow: hidden;

            .title {
                @include lib-font-size($font-size__l);
                @include lib-css(font-weight, $font-weight__hairline);
                text-transform: uppercase;
                line-height: normal;
                margin-bottom: $indent__l;
                color: $color-sna-black;
                cursor: default;
            }

            a {
                text-align: center;
                font-size: $font-size__base;
                @include lib-link-secondary(
                        $_link-font-weight             : $font-weight__bold,
                        $_link-color                   : $color-sna-black
                );

                .img-container {
                    position: relative;
                    padding-bottom: 56.25%;

                    img {
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        margin: auto;
                        max-width: 100%;
                        max-height: 100%;
                    }
                }

                p {
                    margin: $indent__s 0 $indent__m;
                }

                &:hover {
                    .img-container:after {
                        background: rgba(0, 0, 0, $overlay__opacity);
                        content: '';
                        display: block;
                        position: absolute;
                        right: 0;
                        left: 0;
                        top: 0;
                        bottom: 0;
                    }
                }
            }
            .banner:last-child p {
                margin: $indent__s 0 0;
            }
        }

        .with-banner  + .mn-submenu__content{
            border-left: 1px solid $color-sna-primario4;
            margin: 0 0 0 $indent__m;
            padding-left: $indent__l;
        }
        .mn-submenu__content {
            display: flex;
        }
    }
    @include on4menu-desktop-big {
        .menu-banner {
            margin-left: 12px;
        }
    }
    @include min-screen($screen__xl) {
        .menu-banner {
            margin-left: 20px;
        }
    }

    .mn-submenu {
        @include on4menu-desktop {
            &.mn-submenu--level--2 {
                flex-wrap: nowrap;
                position: absolute;
                .mn-category-button--back {
                    @include lib-link(
                        $_link-text-decoration-hover   : none,
                        $_link-text-decoration-active  : none
                    );
                    display: block;
                    margin-bottom: $indent__m;
                }
                .mn-category-button__arrow--flechaizda{
                    display: inline-block;
                }
                .mn-submenu__header {
                    flex-basis: 200px;
                    flex-shrink: 0;
                }
                .mn-submenu__content {
                    border-left: 1px solid $color-sna-primario4;
                    margin: 0 0 0 $indent__m;
                    padding-left: $indent__l;
                }
            }
            &__header {
                align-self: flex-start;
            }
            &__items {
                flex-wrap: wrap;
                align-self: flex-start;
                width: 100%;
            }
            &__item,
            .banner-link {
                height: 91px;
            }
            &__item {
                margin: 0 0 $indent__base $indent__base;
                flex: 0 1 200px;
            }
        }
    }
}
