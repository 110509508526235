//
//  CMS PAGE: Awards
//  _____________________________________________

.cms-alt-awards .cta-banner.product {
    @include lib-layout-indent-main-inverse-reset();
    border-top: 1px solid $color-sna-primario4;
    background-color: white;

    .cta-banner {
        &__figure {
            padding-top: $indent__base;
        }
        
        &__body-header {
            margin-bottom: 0;
            
            :last-child {
                margin-bottom: 1em;
            }
        }
    }
    
    .awards {
        @extend .abs-reset-list;
        @include lib-font-size($font-size__s);
        
        li {
            display: grid;
            grid-template-areas: 
            "logo award"
            "logo year";
            grid-template-columns: auto 1fr;
            grid-template-rows: 1fr 1fr;
            @include lib-css(grid-column-gap, $indent__s);
    
            @include lib-css(margin-bottom, $indent__base);
            &:last-child {
                margin-bottom: 0;
            }
            
            img {
                grid-area: logo;
                height: 52px;
            }
            
            strong {
                display: block;
                grid-area: award;
                align-self: end;
            }
            
            small {
                display: block;
                grid-area: year;
                @include lib-css(color, $color-sna-primario3);
            }
        }
    }
}